import React from 'react';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';
import Button from '@/components/ui/Button/Button.tsx';
import InfoIcon from '@/assets/Icons/Info-Line.svg?react';
import styles from './UnsavedChangesToast.module.scss';
import breakpoints from '@/constants/breakpoints.ts';

interface UnsavedChangesToastProps {
	onCancel: () => void;
	onSubmit: () => void;
}

const UnsavedChangesToast: React.FC<UnsavedChangesToastProps> = ({ onCancel, onSubmit }) => {
	return (
		<FlexBlock
			flexDirection={{
				[breakpoints.zero]: 'column',
				[breakpoints.tabletSmall]: 'row',
			}}
			justifyContent="space-between"
			alignItems="center"
			rowGap="18px"
			className={styles.unsavedChangesToastWrapper}
		>
			<FlexBlock alignItems="center" columnGap="12px">
				<InfoIcon />
				There are unsaved changes!
			</FlexBlock>
			<FlexBlock columnGap="20px">
				<Button
					color="default"
					size="medium"
					type="button"
					hover={false}
					className={styles.leftButton}
					onClick={onCancel}
				>
					Cancel
				</Button>
				<Button color="darkGreen" size="medium" type="submit" onClick={onSubmit}>
					Save
				</Button>
			</FlexBlock>
		</FlexBlock>
	);
};

export default UnsavedChangesToast;
