import { Checkbox as CheckboxAria, CheckboxProps } from 'react-aria-components';
import DoneCheckmark from '@/assets/Icons/Done-Checkmark.svg?react';
import styles from './Checkbox.module.scss';
import classNames from 'classnames';

const Checkbox = ({ children, className, ...props }: CheckboxProps) => {
	return (
		<CheckboxAria {...props} className={classNames(styles.ariaCheckbox, className)}>
			<>
				<div className={styles.checkbox}>{props.isSelected && <DoneCheckmark />}</div>
				{children}
			</>
		</CheckboxAria>
	);
};

export default Checkbox;
