import React, { useEffect, useRef, useState } from 'react';
import styles from './TripEmissionLog.module.scss';
import Container from '@/components/ui/Container/Container.tsx';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';
import MainLayout from '@/layouts/MainLayout/MainLayout.tsx';
import { useNavigate } from 'react-router';
import routes from '@/constants/routes.ts';
import { ContentWrapper } from '@/pages/ClimateProDashboard/ClimateProDashboard.tsx';
import ArrowRightIcon from '@/assets/Icons/Arrow-Right.svg?react';
import Card from '@/components/ui/Card/Card.tsx';
import LuggageIcon from '@/assets/Icons/Luggage-2.svg?react';
import AirplaneImage from '@/assets/Images/Airplane.png';
import PillowImage from '@/assets/Images/Pillow.png';
import FuelImage from '@/assets/Images/Fuel.png';
import Image from '@/components/ui/Image/Image.tsx';
import classNames from 'classnames';
import LogForm from '@/pages/MyClimate/TripEmissionLog/LogForm.tsx';
import TripEmissionLogFlyout from '@/pages/MyClimate/TripEmissionLog/TripEmissionLogFlyout.tsx';
import TravelLogType = App.Enums.TravelLogType;

const TripEmissionLog: React.FC = () => {
	const navigate = useNavigate();
	const [selectedTripMode, setSelectedTripMode] = useState<TravelLogType | undefined>(undefined);
	const logFormRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (selectedTripMode && logFormRef.current) {
			logFormRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	}, [selectedTripMode]);

	return (
		<MainLayout>
			<Container>
				<FlexBlock flexDirection="column" rowGap="32px" className={styles.tripEmissionLogWrapper}>
					<FlexBlock columnGap="6px" alignItems="center" className={styles.breadcrumb}>
						<button onClick={() => navigate(routes.myClimate)}>Dashboard</button>
						<span>
							<ArrowRightIcon />
						</span>
						<button>Trip Emission Log</button>
					</FlexBlock>

					<ContentWrapper>
						<div className={styles.tripEmissionLogMenuWrapper}>
							<FlexBlock justifyContent="space-between">
								<h1>Trip emission log</h1>
								<TripEmissionLogFlyout />
							</FlexBlock>
							<Card>
								<FlexBlock alignItems="center" columnGap="16px" className={styles.cardHeader}>
									<FlexBlock
										justifyContent="center"
										alignItems="center"
										className={styles.iconWrapper}
									>
										<LuggageIcon />
									</FlexBlock>
									<p>Transport type</p>
								</FlexBlock>
								<div className={styles.subheader}>
									Log emissions manually for travel booked outside of Goodwings.
								</div>
								<div className={styles.buttonsWrapper}>
									<p className={styles.selectTitle}>Select the type of transport used</p>
									<FlexBlock columnGap="48px">
										<button
											className={classNames(styles.tripModeBttn, {
												[styles.selected]: selectedTripMode === 'flight',
											})}
											onClick={() => setSelectedTripMode('flight')}
										>
											<p>Air</p> <Image src={AirplaneImage} fallbackSrc={''} />
										</button>
										<button
											className={classNames(styles.tripModeBttn, {
												[styles.selected]: selectedTripMode === 'accommodation',
											})}
											onClick={() => setSelectedTripMode('accommodation')}
										>
											<p>Accommodation</p> <Image src={PillowImage} fallbackSrc={''} />
										</button>
										<button
											className={classNames(styles.tripModeBttn, {
												[styles.selected]: selectedTripMode === 'mileage',
											})}
											onClick={() => setSelectedTripMode('mileage')}
										>
											<p>Mileage</p> <Image src={FuelImage} fallbackSrc={''} />
										</button>
									</FlexBlock>
								</div>
							</Card>
						</div>

						{selectedTripMode && (
							<div ref={logFormRef}>
								<LogForm
									tripMode={selectedTripMode}
									setSelectedTripMode={setSelectedTripMode}
									editingMode={false}
									viewMode={false}
								/>
							</div>
						)}
					</ContentWrapper>
				</FlexBlock>
			</Container>
		</MainLayout>
	);
};

export default TripEmissionLog;
