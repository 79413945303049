import {
	Button,
	Calendar,
	CalendarCell,
	CalendarGrid,
	DateInput,
	DatePicker as ReactDatePicker,
	DateSegment,
	Dialog,
	Group,
	Heading,
	Label,
	Popover,
} from 'react-aria-components';
import styles from './DatePicker.module.scss';
import CalendarIcon from '@/assets/Icons/Calendar-Schedule-1.svg?react';
import ArrowRight from '@/assets/Icons/Arrow-Right.svg?react';
import ArrowLeft from '@/assets/Icons/Arrow-Left.svg?react';
import classNames from 'classnames';
import { CalendarDate, getLocalTimeZone, isSameDay, parseDate, today } from '@internationalized/date';
import CloseIcon from '@/assets/Icons/Close.svg?react';
import React, { FocusEventHandler, useCallback } from 'react';
import moment, { Moment } from 'moment';

export interface DatePickerProps {
	label?: string;
	disabled?: boolean;
	onBlur?: FocusEventHandler | undefined;
	onChange: (newValue?: Moment) => void;
	className?: string;
	value?: Moment;
	name?: string;
	errorMessage?: string;
	touched?: boolean;
	required?: boolean;
	wildcardVisible?: boolean;
}

const DatePicker: React.FC<DatePickerProps> = ({
	label,
	onChange,
	disabled,
	value,
	name,
	onBlur,
	errorMessage,
	touched,
	className,
	required = false,
	wildcardVisible = false,
}) => {
	const handleClearFieldValue = useCallback(
		(e: React.MouseEvent) => {
			e.stopPropagation();
			onChange(undefined);
		},
		[onChange],
	);

	const handleChange = useCallback(
		(date: CalendarDate) => {
			if (date) {
				onChange(
					moment({
						year: date?.year || undefined,
						month: date?.month ? date.month - 1 : undefined,
						day: date?.month || undefined,
					}),
				);
			} else {
				onChange(undefined);
			}
		},
		[onChange],
	);

	return (
		<ReactDatePicker
			className={classNames(styles.datePickerWrapper, className)}
			onBlur={onBlur}
			isDisabled={disabled}
			onChange={handleChange}
			name={name}
			value={value ? parseDate(value.format('YYYY-MM-DD')) : null}
		>
			<Label className={styles.label}>
				{label}
				{wildcardVisible && '*'}
			</Label>
			<Group className={styles.group}>
				<DateInput className={classNames(styles.dateInput, disabled && styles.disabled)}>
					{segment => <DateSegment segment={segment} />}
				</DateInput>
				{!required && !!value && !disabled && (
					<button className={styles.clearIcon} onClick={handleClearFieldValue}>
						<CloseIcon />
					</button>
				)}
				<Button className={classNames(styles.calendarButton, disabled && styles.disabled)}>
					<CalendarIcon />
				</Button>
			</Group>
			{touched && errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
			<Popover className={styles.popover}>
				<Dialog className={styles.dialog}>
					<Calendar className={styles.calendar}>
						<header>
							<Heading />
							<div className={styles.buttons}>
								<Button slot="previous">
									<ArrowLeft />
								</Button>
								<Button slot="next">
									<ArrowRight />
								</Button>
							</div>
						</header>
						<CalendarGrid className={styles.calendarGrid}>
							{date => (
								<CalendarCell
									date={date}
									className={({ isOutsideMonth }) =>
										classNames(styles.calendarCell, {
											[styles.fade]: isOutsideMonth,
											[styles.currentDate]: isSameDay(date, today(getLocalTimeZone())),
										})
									}
								/>
							)}
						</CalendarGrid>
					</Calendar>
				</Dialog>
			</Popover>
		</ReactDatePicker>
	);
};

export default DatePicker;
