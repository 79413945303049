import { useEffect } from 'react';
import { useBlocker } from 'react-router-dom';
import { useModalStore } from '@/store/useModalStore.ts';

export const useRouteBlocker = (shouldBlock: boolean) => {
	const blocker = useBlocker(true);
	const { openModal, closeModal, activeModal } = useModalStore(state => state);

	useEffect(() => {
		const shouldProceed = !shouldBlock || blocker.state !== 'blocked';
		if (shouldProceed) {
			blocker.proceed?.();
			return;
		}

		openModal('unsavedChanges', {
			onConfirm: () => {
				closeModal();
				blocker.proceed?.();
			},
		});
	}, [blocker, shouldBlock]);

	// Reset blocker from blocked to unblocked if the unsaved changes modal is closed with return option
	useEffect(() => {
		activeModal === null && blocker.reset?.();
	}, [activeModal]);

	return blocker;
};
