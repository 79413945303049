import React from 'react';
import { useField } from 'formik';
import AutocompletePlaceInput, {
	AutocompletePlaceInputProps,
} from '@/components/ui/AutocompletePlaceInput/AutocompletePlaceInput.tsx';
import { get } from 'lodash';

interface FormikAutocompletePlaceInputProps
	extends Omit<AutocompletePlaceInputProps, 'value' | 'onChange' | 'errorMessage' | 'onTouched'> {
	name: string;
}

const FormikAutocompletePlaceInput: React.FC<FormikAutocompletePlaceInputProps> = ({ name, ...props }) => {
	const [{ value }, { error, touched }, { setValue, setTouched }] = useField(name);

	return (
		<AutocompletePlaceInput
			{...props}
			name={name}
			value={value}
			onTouched={() => setTouched(true)}
			onChange={setValue}
			errorMessage={get(error, 'googlePlaceId', error)}
			touched={touched}
		/>
	);
};

export default FormikAutocompletePlaceInput;
