import React, { useEffect, useMemo } from 'react';
import { FormikHelpers, FormikProvider, useFormik } from 'formik';
import styles from './FactorFramework.module.scss';
import Card from '@/components/ui/Card/Card.tsx';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';
import FactorFrameworksExample from '@/assets/Illustrations/Factor-Frameworks-Example.svg?react';
import OpenInNewTabIcon from '@/assets/Icons/Open-In-New-Tab.svg?react';
import { Link } from 'react-router-dom';
import Flyout, { FlyoutCloseButton } from '@/components/ui/Flyout/Flyout.tsx';
import HistoryIcon from '@/assets/Icons/History.svg?react';
import DoneCheckmarkIcon from '@/assets/Icons/Done-Checkmark.svg?react';
import moment from 'moment';
import { useQuery } from '@tanstack/react-query';
import queryKeys from '@/constants/queryKeys.ts';
import { getFactorFrameworkOptions, getFactorFrameworks, updateFactorFrameworks } from '@/api/climatePro.ts';
import FormikSelect from '@/components/Formik/FormikSelect.tsx';
import Spinner from '@/components/ui/Spinner/Spinner.tsx';
import UnsavedChangesToast from '@/pages/ClimateProDashboard/UnsavedChangesToast/UnsavedChangesToast.tsx';
import handleResponseErrors from '@/utils/handleResponseErrors.ts';
import FormikCheckboxToggle from '@/components/Formik/FormikCheckboxToggle.tsx';
import { toast } from 'react-toastify';
import { useModalStore } from '@/store/useModalStore.ts';
import WarningModal from '@/pages/ClimateProDashboard/FactorFramework/WarningModal.tsx';
import { externalLinks } from '@/constants/externalLinks.ts';
import Tooltip from '@/components/ui/Tooltip/Tooltip.tsx';
import InfoIcon from '@/assets/Icons/Info-Line.svg?react';
import { isEqual } from 'lodash';
import breakpoints from '@/constants/breakpoints.ts';
import ZoomIcon from '@/assets/Icons/Zoom.svg?react';
import Modal from '@/components/ui/Modal/Modal.tsx';
import BookIcon from '@/assets/Icons/Book.svg?react';
import MessageIcon from '@/assets/Icons/Sms.svg?react';
import OrganizationCo2FactorFrameworkData = App.Data.OrganizationCo2FactorFrameworkData;
import UpdateOrganizationCo2FactorFrameworksRequest = App.Data.Request.UpdateOrganizationCo2FactorFrameworksRequest;
import SquakeFlightFramework = App.Enums.SquakeFlightFramework;
import SquakeHotelFramework = App.Enums.SquakeHotelFramework;
import SquakeRailFramework = App.Enums.SquakeRailFramework;
import SquakeCarFramework = App.Enums.SquakeCarFramework;

interface HistoryFlyoutProps {
	name: string;
	historyData: OrganizationCo2FactorFrameworkData[];
}

const HistoryFlyout: React.FC<HistoryFlyoutProps> = ({ name, historyData }) => (
	<div>
		<Flyout
			id={name}
			button={
				<button className={styles.historyFlyoutIconWrapper}>
					<HistoryIcon />
				</button>
			}
			className={styles.flyout}
		>
			<FlexBlock justifyContent="space-between" alignItems="center" className={styles.flyoutHeader}>
				<h3>History of changes for the {name} framework</h3>
				<FlyoutCloseButton />
			</FlexBlock>
			{historyData.map((data, index) => (
				<div key={index} className={styles.co2HistoryBox}>
					<FlexBlock justifyContent="space-between" alignItems="center">
						<p className={styles.time}>{moment(data.createdAt).format('MMM D, YYYY, h:mm:ss A [GMT]Z')}</p>
						{data.current && (
							<FlexBlock
								justifyContent="space-between"
								alignItems="center"
								columnGap="4px"
								className={styles.successMarker}
							>
								<DoneCheckmarkIcon />
								Current
							</FlexBlock>
						)}
					</FlexBlock>
					<table>
						<thead>
							<tr>
								<th style={{ width: '55%' }}>Framework</th>
								<th>Changed By</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>{data.framework}</td>
								<td>{data.createdBy || '-'}</td>
							</tr>
						</tbody>
					</table>
				</div>
			))}
		</Flyout>
	</div>
);

const ZoomIllustrationModal: React.FC = () => {
	return (
		<Modal name="zoomIllustration" className={styles.zoomIllustrationModal}>
			<FactorFrameworksExample />
		</Modal>
	);
};

const AskASpecialistModal: React.FC = () => {
	return (
		<Modal name="askASpecialist" className={styles.askASpecialistModal}>
			<h3>How would you like to contact the specialist?</h3>
			<FlexBlock justifyContent="space-between" alignItems="center" columnGap="12px">
				<a href="mailto:anj@goodwings.com">Send email</a>
				<Link to={externalLinks.bookClimateMeeting} target="_blank">
					Book a meeting
				</Link>
			</FlexBlock>
		</Modal>
	);
};

const FactorFramework: React.FC = () => {
	const { openModal, activeModal } = useModalStore(state => state);

	const {
		data: frameworkOptionsData,
		isLoading: isFrameworkOptionsDataLoading,
		isSuccess: frameworkOptionsIsSuccess,
		refetch: refetchFrameworkOptions,
	} = useQuery({
		queryKey: [queryKeys.factorFrameworkOptions],
		queryFn: getFactorFrameworkOptions,
	});

	const { data, isSuccess, isLoading, refetch, isError } = useQuery({
		queryKey: [queryKeys.factorFramework],
		queryFn: getFactorFrameworks,
	});

	const handleSubmit = async (
		values: UpdateOrganizationCo2FactorFrameworksRequest,
		formikHelpers: FormikHelpers<UpdateOrganizationCo2FactorFrameworksRequest>,
	) => {
		try {
			const selectedFlightFramework = frameworkOptionsData?.flight.find(item => item.value === values.flight);

			const newValues = {
				...values,
				flightRfi: selectedFlightFramework?.rfiRequired ? values.flightRfi : null,
				flightWtt: selectedFlightFramework?.wttRequired ? values.flightWtt : null,
			};

			await updateFactorFrameworks(newValues);
			await Promise.all([refetch(), refetchFrameworkOptions()]);
			toast.success('Successfully saved changes.');
		} catch (error) {
			handleResponseErrors(error, formikHelpers);
		}
	};

	// Formik
	const currentFlight = data?.flight?.find(flight => flight.current);
	const currentHotel = data?.hotel?.find(hotel => hotel.current);
	const currentRail = data?.rail?.find(rail => rail.current);
	const currentCar = data?.car?.find(car => car.current);

	const initialValues = {
		flight: currentFlight?.framework as SquakeFlightFramework,
		hotel: currentHotel?.framework as SquakeHotelFramework,
		rail: currentRail?.framework as SquakeRailFramework,
		car: currentCar?.framework as SquakeCarFramework,
		flightRfi: currentFlight?.flightRfi ?? null,
		flightWtt: currentFlight?.flightWtt ?? null,
	};

	const formik = useFormik({
		initialValues,
		enableReinitialize: true,
		onSubmit: handleSubmit,
	});

	const { flightRfiRequired, flightWttRequired } = useMemo(() => {
		const flightFramework = frameworkOptionsData?.flight?.find(f => f.value === formik.values.flight);

		return { flightRfiRequired: flightFramework?.rfiRequired, flightWttRequired: flightFramework?.wttRequired };
	}, [formik.values.flight, frameworkOptionsData?.flight]);

	useEffect(() => {
		isError && toast.error('Something went wrong. Please try again later.');
	}, [isError]);

	useEffect(() => {
		// Set the factors to a boolean if required. Otherwise, set it to null
		if (!isEqual(initialValues, formik.values)) {
			formik.setFieldValue('flightRfi', flightRfiRequired ? !!formik.values.flightRfi : null);
			formik.setFieldValue('flightWtt', flightWttRequired ? !!formik.values.flightWtt : null);
		}
	}, [formik.values.flight]);

	return (
		<FlexBlock flexDirection="column" rowGap="40px" className={styles.factorFrameworkWrapper}>
			<h1>CO2 factor framework</h1>

			<Card className={styles.card}>
				<h3 className={styles.cardTitle}>Choosing the right methodology for your company</h3>
				<FlexBlock
					flexDirection={{
						[breakpoints.zero]: 'column',
						[breakpoints.tabletWide]: 'row',
					}}
					rowGap="30px"
					columnGap="52px"
				>
					<div>
						<p className={styles.cardText}>
							There are many acknowledged methodologies for calculating emissions. They are all accurate,
							but differ a lot since they take various factors into account differently. Example, some
							include Radiative Forcing Index when calculating emissions from air travel, while other do
							not.
						</p>
						<p className={styles.cardText}>
							Your choice of emissions factor source can have a big impact on your calculated travel
							emissions as shown on this graph.
						</p>
						<Link to={externalLinks.emissionsFrameworks} target="_blank" className={styles.readMore}>
							Read more about the methodologies and our recommendations for your company
							<OpenInNewTabIcon />
						</Link>
					</div>
					<div className={styles.svgWrapper}>
						<FactorFrameworksExample />
						<button onClick={() => openModal('zoomIllustration')} className={styles.zoomIconWrapper}>
							<ZoomIcon />
						</button>
					</div>
				</FlexBlock>
			</Card>

			{isLoading && isFrameworkOptionsDataLoading && (
				<div className={styles.spinnerWrapper}>
					<Spinner absolute />
				</div>
			)}

			{isSuccess && frameworkOptionsIsSuccess && data && frameworkOptionsData && (
				<FormikProvider value={formik}>
					<FlexBlock flexDirection="column" rowGap="40px">
						<h2>Flights</h2>
						<Card className={styles.card}>
							<FlexBlock
								flexDirection={{
									[breakpoints.zero]: 'column',
									[breakpoints.tablet]: 'row',
								}}
								justifyContent="space-between"
								rowGap="27px"
								columnGap="14px"
							>
								<FlexBlock flexDirection="column" rowGap="27px" className={styles.flights}>
									<p>Changes are allowed only once per year</p>
									<FlexBlock columnGap="20px">
										<FormikSelect
											required
											name="flight"
											options={frameworkOptionsData.flight.map(({ label, value }) => ({
												label,
												value,
											}))}
											className={styles.select}
										/>
										{data && <HistoryFlyout name="flight" historyData={data.flight} />}
									</FlexBlock>
									{data.flight.length === 1 && currentFlight && (
										<p
											className={styles.infoText}
										>{`For your convenience, we've preselected ${currentFlight.framework} as the default flight framework. Feel free to choose the framework that best fits your company.`}</p>
									)}
									<FlexBlock columnGap="40px" className={styles.checkboxes}>
										{flightRfiRequired && (
											<FlexBlock columnGap="5px" alignItems="center">
												<Tooltip
													icon={
														<div className={styles.tooltipWrapper}>
															<InfoIcon />
														</div>
													}
													text="Radiative Forcing Index (RFI) accounts for the additional climate impact of aviation beyond CO₂ emissions, including contrails and atmospheric effects."
												/>
												<p>RFI</p>
												<FormikCheckboxToggle name="flightRfi" />
											</FlexBlock>
										)}
										{flightWttRequired && (
											<FlexBlock columnGap="5px" alignItems="center">
												<Tooltip
													icon={
														<div className={styles.tooltipWrapper}>
															<InfoIcon />
														</div>
													}
													text="Well-to-Tank (WTT) includes emissions from fuel extraction, production, and transportation before it is burned in the aircraft."
												/>
												<p>WTT</p>
												<FormikCheckboxToggle name="flightWtt" />
											</FlexBlock>
										)}
									</FlexBlock>
								</FlexBlock>
								<FlexBlock flexDirection="column" rowGap="22px" className={styles.learnMore}>
									<p>Not sure which framework to use?</p>
									<FlexBlock columnGap="12px" alignItems="center">
										<Link to={externalLinks.learnMoreAboutFactorFramework} target="_blank">
											<FlexBlock alignItems="center" columnGap="5px">
												<BookIcon /> <p>Learn more</p>
											</FlexBlock>
										</Link>
										<button onClick={() => openModal('askASpecialist')}>
											<FlexBlock alignItems="center" columnGap="5px">
												<MessageIcon /> <p>Ask a specialist</p>
											</FlexBlock>
										</button>
									</FlexBlock>
								</FlexBlock>
							</FlexBlock>
						</Card>

						<h2>Accommodation</h2>
						<Card className={styles.card}>
							<FlexBlock
								flexDirection={{
									[breakpoints.zero]: 'column',
									[breakpoints.tablet]: 'row',
								}}
								justifyContent="space-between"
								rowGap="27px"
								columnGap="14px"
							>
								<FlexBlock flexDirection="column" rowGap="27px" className={styles.flights}>
									<p>Changes are allowed only once per year</p>
									<FlexBlock columnGap="20px">
										<FormikSelect
											required
											name="hotel"
											options={frameworkOptionsData.hotel.map(({ label, value }) => ({
												label,
												value,
											}))}
											className={styles.select}
										/>
										{data && <HistoryFlyout name="hotel" historyData={data?.hotel} />}
									</FlexBlock>
									{data.hotel.length === 1 && currentHotel && (
										<p
											className={styles.infoText}
										>{`For your convenience, we've preselected ${currentHotel.framework} as the default flight framework. Feel free to choose the framework that best fits your company.`}</p>
									)}
								</FlexBlock>
								<FlexBlock flexDirection="column" rowGap="22px" className={styles.learnMore}>
									<p>Not sure which framework to use?</p>
									<FlexBlock columnGap="12px" alignItems="center">
										<Link to={externalLinks.learnMoreAboutFactorFramework} target="_blank">
											<FlexBlock alignItems="center" columnGap="5px">
												<BookIcon /> <p>Learn more</p>
											</FlexBlock>
										</Link>
										<button onClick={() => openModal('askASpecialist')}>
											<FlexBlock alignItems="center" columnGap="5px">
												<MessageIcon /> <p>Ask a specialist</p>
											</FlexBlock>
										</button>
									</FlexBlock>
								</FlexBlock>
							</FlexBlock>
						</Card>

						<h2>Rail</h2>
						<Card className={styles.card}>
							<FlexBlock
								flexDirection={{
									[breakpoints.zero]: 'column',
									[breakpoints.tablet]: 'row',
								}}
								justifyContent="space-between"
								rowGap="27px"
								columnGap="14px"
							>
								<FlexBlock flexDirection="column" rowGap="27px" className={styles.flights}>
									<p>Changes are allowed only once per year</p>
									<FlexBlock columnGap="20px">
										<FormikSelect
											required
											name="rail"
											options={frameworkOptionsData.rail.map(({ label, value }) => ({
												label,
												value,
											}))}
											className={styles.select}
										/>
										{data && <HistoryFlyout name="rail" historyData={data?.rail} />}
									</FlexBlock>
									{data.rail.length === 1 && currentRail && (
										<p
											className={styles.infoText}
										>{`For your convenience, we've preselected ${currentRail.framework} as the default flight framework. Feel free to choose the framework that best fits your company.`}</p>
									)}
								</FlexBlock>
								<FlexBlock flexDirection="column" rowGap="22px" className={styles.learnMore}>
									<p>Not sure which framework to use?</p>
									<FlexBlock columnGap="12px" alignItems="center">
										<Link to={externalLinks.learnMoreAboutFactorFramework} target="_blank">
											<FlexBlock alignItems="center" columnGap="5px">
												<BookIcon /> <p>Learn more</p>
											</FlexBlock>
										</Link>
										<button onClick={() => openModal('askASpecialist')}>
											<FlexBlock alignItems="center" columnGap="5px">
												<MessageIcon /> <p>Ask a specialist</p>
											</FlexBlock>
										</button>
									</FlexBlock>
								</FlexBlock>
							</FlexBlock>
						</Card>

						<h2>Rental cars</h2>
						<Card className={styles.card}>
							<FlexBlock
								flexDirection={{
									[breakpoints.zero]: 'column',
									[breakpoints.tablet]: 'row',
								}}
								justifyContent="space-between"
								rowGap="27px"
								columnGap="14px"
							>
								<FlexBlock flexDirection="column" rowGap="27px" className={styles.flights}>
									<p>Changes are allowed only once per year</p>
									<FlexBlock columnGap="20px">
										<FormikSelect
											required
											name="car"
											options={frameworkOptionsData.car.map(({ label, value }) => ({
												label,
												value,
											}))}
											position="top"
											className={styles.select}
										/>
										{data && <HistoryFlyout name="car" historyData={data?.car} />}
									</FlexBlock>
									{data.car.length === 1 && currentCar && (
										<p
											className={styles.infoText}
										>{`For your convenience, we've preselected ${currentCar.framework} as the default flight framework. Feel free to choose the framework that best fits your company.`}</p>
									)}
								</FlexBlock>
								<FlexBlock flexDirection="column" rowGap="22px" className={styles.learnMore}>
									<p>Not sure which framework to use?</p>
									<FlexBlock columnGap="12px" alignItems="center">
										<Link to={externalLinks.learnMoreAboutFactorFramework} target="_blank">
											<FlexBlock alignItems="center" columnGap="5px">
												<BookIcon /> <p>Learn more</p>
											</FlexBlock>
										</Link>
										<button onClick={() => openModal('askASpecialist')}>
											<FlexBlock alignItems="center" columnGap="5px">
												<MessageIcon /> <p>Ask a specialist</p>
											</FlexBlock>
										</button>
									</FlexBlock>
								</FlexBlock>
							</FlexBlock>
						</Card>
					</FlexBlock>
					{formik.dirty && (
						<UnsavedChangesToast
							onCancel={() => formik.resetForm()}
							onSubmit={() => openModal('warningFactorFrameworkModal', formik.submitForm)}
						/>
					)}
				</FormikProvider>
			)}
			{activeModal === 'warningFactorFrameworkModal' && <WarningModal />}
			{activeModal === 'zoomIllustration' && <ZoomIllustrationModal />}
			{activeModal === 'askASpecialist' && <AskASpecialistModal />}
		</FlexBlock>
	);
};

export default FactorFramework;
