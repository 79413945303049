import { Option, Value } from '@/components/ui/Select/Select.tsx';

export const toSelectOptions = <T extends Record<string, any>>(
	data: T[] | undefined,
	labelColumn: keyof T,
	valueColumn: keyof T = 'id' as keyof T,
): Option[] => {
	return (
		data?.map(item => ({
			value: item[valueColumn] as Value, // Ensure value is string | number | undefined
			label: String(item[labelColumn]), // Ensure label is always a string
		})) || []
	);
};
