import * as Yup from 'yup';
import { isEmpty } from 'lodash';

export const airValidationSchema = Yup.object({
	flightLegs: Yup.array()
		.of(
			Yup.object({
				departureAirport: Yup.object()
					.required('This field is required')
					.test('airport-valid', 'This field is required', value => !isEmpty(value)),
				arrivalAirport: Yup.object()
					.required('This field is required')
					.test('airport-valid', 'This field is required', value => !isEmpty(value)),
				flightCabinClass: Yup.string().required('This field is required'),
			}),
		)
		.min(1, 'At least one flight leg is required')
		.required('This field is required'),
	departureDate: Yup.date()
		.required('This field is required')
		.test('is-valid-year', 'Invalid date', value => {
			if (!value) return true;

			const year = value.getFullYear();
			return year.toString().length === 4;
		}),
	arrivalDate: Yup.date()
		.required('This field is required')
		.test('is-after-departure', 'Arrival date must be the same or after the departure date', function (value) {
			const { departureDate } = this.parent;
			return departureDate && value ? value >= departureDate : true;
		})
		.test('is-valid-year', 'Invalid date', value => {
			if (!value) return true;

			const year = value.getFullYear();
			return year.toString().length === 4;
		}),
	itineraryId: Yup.number().nullable(),
});

export const accommodationValidationSchema = Yup.object({
	accommodationPlace: Yup.object().required('This field is required'),
	departureDate: Yup.date()
		.required('This field is required')
		.test('is-valid-year', 'Invalid date', value => {
			if (!value) return true;

			const year = value.getFullYear();
			return year.toString().length === 4;
		}),
	arrivalDate: Yup.date()
		.required('This field is required')
		.test('is-after-departure', 'Arrival date must be the same or after the departure date', function (value) {
			const { departureDate } = this.parent;
			return departureDate && value ? value >= departureDate : true;
		})
		.test('is-valid-year', 'Invalid date', value => {
			if (!value) return true;

			const year = value.getFullYear();
			return year.toString().length === 4;
		}),
	itineraryId: Yup.number().nullable(),
	accommodationType: Yup.string().required('This field is required'),
});

export const mileageValidationSchema = Yup.object({
	departurePlace: Yup.object().required('This field is required'),
	arrivalPlace: Yup.object().required('This field is required'),
	distance: Yup.number().positive('Distance must be a valid number in kilometers'),
	departureDate: Yup.date()
		.required('This field is required')
		.test('is-valid-year', 'Invalid date', value => {
			if (!value) return true;

			const year = value.getFullYear();
			return year.toString().length === 4;
		}),
	arrivalDate: Yup.date()
		.required('This field is required')
		.test('is-after-departure', 'Arrival date must be the same or after the departure date', function (value) {
			const { departureDate } = this.parent;
			return departureDate && value ? value >= departureDate : true;
		})
		.test('is-valid-year', 'Invalid date', value => {
			if (!value) return true;

			const year = value.getFullYear();
			return year.toString().length === 4;
		}),
	itineraryId: Yup.number().nullable(),
	groundTransportationType: Yup.string().required('This field is required'),
}).test(
	'either-mileage-or-places',
	'Either mileage or both departure and arrival places are required',
	function (value) {
		const { distance, departurePlace, arrivalPlace } = value;
		if (distance || (departurePlace && arrivalPlace)) {
			return true;
		}
		return false;
	},
);
