import axios from 'axios';
import queryString from 'query-string';
import ListMyClimateTravelLogResponse = App.Data.Response.ListMyClimateTravelLogResponse;
import ListMyClimateTravelLogRequest = App.Data.Request.ListMyClimateTravelLogRequest;
import CreateTravelLogRequest = App.Data.Request.CreateTravelLogRequest;
import ListItinerariesResponse = App.Data.Response.ListItinerariesResponse;
import UpdateTravelLogRequest = App.Data.Request.UpdateTravelLogRequest;
import MyClimateCo2MetricsRequest = App.Data.Request.MyClimateCo2MetricsRequest;
import ListMyClimateCo2MetricsResponse = App.Data.Response.ListMyClimateCo2MetricsResponse;
import MyClimateCarbonFootprintRequest = App.Data.Request.MyClimateCarbonFootprintRequest;
import GetMyClimateCarbonFootprintResponse = App.Data.Response.GetMyClimateCarbonFootprintResponse;
import GetOrganizationCarbonRewardsResponse = App.Data.Response.GetOrganizationCarbonRewardsResponse;

export const getAllItineraries = async (): Promise<ListItinerariesResponse> => {
	const response = await axios.get('/my-climate/itineraries');
	return response.data;
};

export const getTravelLogHistory = async (
	data: ListMyClimateTravelLogRequest,
): Promise<ListMyClimateTravelLogResponse> => {
	const response = await axios.get(`/my-climate/travel-logs?${queryString.stringify(data)}`);
	return response.data;
};

export const createTravelLog = async (data: CreateTravelLogRequest): Promise<ListMyClimateTravelLogResponse> => {
	const response = await axios.post('/my-climate/travel-logs', data);
	return response.data;
};

export const updateTravelLog = async (data: UpdateTravelLogRequest): Promise<ListMyClimateTravelLogResponse> => {
	const response = await axios.put(`/my-climate/travel-logs/${data.id}`, data);
	return response.data;
};

export const deleteTravelLog = async (travelLogId: number): Promise<ListMyClimateTravelLogResponse> => {
	const response = await axios.delete(`/my-climate/travel-logs/${travelLogId}`);
	return response.data;
};

export const getMyClimateMetrics = async (
	data: MyClimateCo2MetricsRequest,
): Promise<ListMyClimateCo2MetricsResponse> => {
	const response = await axios.get(`/my-climate/metrics?${queryString.stringify(data)}`);
	return response.data;
};

export const getMyClimateCarbonFootprint = async (
	data: MyClimateCarbonFootprintRequest,
): Promise<GetMyClimateCarbonFootprintResponse> => {
	const response = await axios.get(`/my-climate/carbon-footprint?${queryString.stringify(data)}`);
	return response.data;
};

export const getOrganizationCarbonRewards = async (): Promise<GetOrganizationCarbonRewardsResponse> => {
	const response = await axios.get('/my-climate/organization-carbon-rewards');
	return response.data;
};
