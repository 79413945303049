import * as Yup from 'yup';
import OrganizationEntityData = App.Data.OrganizationEntityData;

export const loginSchema = Yup.object().shape({
	email: Yup.string().email('The email must be a valid email address').required('Email is required'),
	password: Yup.string().required('Password is required'),
	twoFaCode: Yup.string()
		.nullable()
		.matches(/^\d{6}$/, 'The code must be exactly 6 digits'),
});

export const onboardEmployeeSchema = (legalEntities: OrganizationEntityData[]) =>
	Yup.object().shape({
		firstName: Yup.string()
			.max(25, 'The first name may not be greater than 25 characters')
			.required('First name is required'),
		middleName: Yup.string().max(40, 'The first name may not be greater than 40 characters'),
		lastName: Yup.string()
			.max(40, 'The first name may not be greater than 40 characters')
			.required('Last name is required'),
		email: Yup.string().email('Invalid email format').required('Email is required'),
		...(legalEntities.length > 1
			? {
					legalEntityId: Yup.number().required('Legal entity is required'),
				}
			: {}),
	});

export const addToCalendarSchema = Yup.object().shape({
	date: Yup.date().required('Date is required'),
	time: Yup.string().required('Time is required'),
});
