import styles from './Input.module.scss';
import React, { FocusEventHandler, useCallback } from 'react';
import classNames from 'classnames';

export type InputValue = string | number | undefined;

export interface InputProps {
	className?: string;
	label?: string;
	placeholder?: string;
	value: string;
	onChange: (newValue: InputValue) => void;
	type?: 'text' | 'number' | 'password' | 'email' | 'tel';
	maxLength?: number;
	errorMessage?: string;
	touched?: boolean;
	min?: number;
	disabled?: boolean;
	onBlur?: FocusEventHandler | undefined;
	name?: string;
	ref?: React.Ref<HTMLInputElement>;
	tableInput?: boolean;
	color?: 'white' | 'gray';
	helperText?: string;
}

const Input: React.FC<InputProps> = ({
	className,
	label,
	placeholder,
	value,
	onChange,
	type = 'text',
	maxLength,
	errorMessage,
	touched,
	min,
	disabled = false,
	onBlur,
	name,
	ref,
	tableInput,
	color = 'white',
	helperText,
}) => {
	const handleChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			let newValue: InputValue = e.target.value;

			if (type === 'number') {
				newValue = parseFloat(newValue);

				if (Number.isNaN(newValue)) {
					newValue = undefined;
				}
			}

			onChange(newValue);
		},
		[onChange, type],
	);

	return (
		<div className={classNames(styles.wrapper, className, { [styles.tableInput]: tableInput })}>
			{!!label && <span className={styles.label}>{label}</span>}
			<div className={styles.inputWrapper}>
				<div className={styles.inputFieldWrapper}>
					<input
						ref={ref}
						name={name}
						type={type}
						className={classNames(styles.input, {
							[styles.error]: !!errorMessage,
							[styles.white]: color === 'white',
							[styles.gray]: color !== 'white',
						})}
						placeholder={placeholder}
						value={value}
						onChange={handleChange}
						maxLength={maxLength}
						min={min}
						disabled={disabled}
						onBlur={onBlur}
					/>
					{helperText && <div className={styles.helperText}>{helperText}</div>}
				</div>
				{!!errorMessage && touched && <span>{errorMessage}</span>}
			</div>
		</div>
	);
};

export default Input;
