import React, { useEffect, useMemo } from 'react';
import styles from './SelectTarget.module.scss';
import { useFormikContext } from 'formik';
import FormikSelect from '@/components/Formik/FormikSelect.tsx';
import FormikInput from '@/components/Formik/FormikInput.tsx';
import GlobalError from '@/components/FormikErrors/GlobalError.tsx';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';
import Tooltip from '@/components/ui/Tooltip/Tooltip.tsx';
import InfoStatus from '@/components/ui/InfoStatus/InfoStatus.tsx';
import TravelerIllustration from '@/assets/Illustrations/Traveler-Airplane.svg?react';
import classNames from 'classnames';
import { round } from 'lodash';
import { Option } from '@/components/ui/Select/Select.tsx';
import { getPeriodDates } from '@/constants/emissionTarget.ts';
import OrganizationEmissionTargetData = App.Data.OrganizationEmissionTargetData;
import breakpoints from '@/constants/breakpoints.ts';

const SelectTarget: React.FC = () => {
	const { values, setFieldValue } = useFormikContext<OrganizationEmissionTargetData>();

	const carbonEmitCo2 = useMemo(() => {
		if (values.type === 'absolute') {
			if (values.lastPeriodTravelSpend) {
				return round(((values.lastPeriodTravelSpend * 1000000) / 600) * 0.8, 2);
			} else {
				return values.lastPeriodCo2;
			}
		} else if (values.type === 'intensity') {
			if (values.lastPeriodRevenue && values.expectedRevenue && values.lastPeriodCo2) {
				return round((values.lastPeriodCo2 / values.lastPeriodRevenue) * values.expectedRevenue, 2);
			} else if (values.lastPeriodRevenue && values.expectedRevenue && values.lastPeriodTravelSpend) {
				return round(
					(((values.lastPeriodTravelSpend / 600) * 0.8) / values.lastPeriodRevenue) *
						values.expectedRevenue *
						1000000,
					2,
				);
			}
		}

		return 0;
	}, [
		values.type,
		values.lastPeriodCo2,
		values.lastPeriodRevenue,
		values.expectedRevenue,
		values.lastPeriodTravelSpend,
	]);

	const reductionOptions: Option[] = useMemo(() => {
		return Array.from({ length: 20 }, (_, index) => {
			const value = index * 5;

			const co2Reduction = carbonEmitCo2 ? round((carbonEmitCo2 * value) / 100, 3) : 0; // Calculate CO2 reduction dynamically
			let label = `${value}% reduction`;

			if (co2Reduction) {
				label += ` (-${co2Reduction} tons of CO2)`;
			}

			return { label, value };
		});
	}, [carbonEmitCo2]);

	useEffect(() => {
		let targetCo2 = 0;

		if (carbonEmitCo2 && values.reductionPercentage !== null) {
			// Calculate the reduced CO2 emissions based on the reduction percentage
			targetCo2 = round(carbonEmitCo2 * (1 - values.reductionPercentage / 100), 2);
		}

		setFieldValue('targetCo2', targetCo2);
	}, [carbonEmitCo2, values.reductionPercentage, setFieldValue]);

	return (
		<div className={styles.selectTargetWrapper}>
			<p className={styles.introText}>
				Defining your reduction target is a decisive action toward minimizing your environmental footprint. Your
				target should be ambitious yet achievable, reflecting your commitment to sustainability.
				<GlobalError />
			</p>

			{values.type === 'intensity' && (
				<FlexBlock
					flexDirection="column"
					rowGap="72px"
					className={classNames(styles.targetWrapper, styles.expectedRevenueWrapper)}
				>
					<FlexBlock
						flexDirection={{
							[breakpoints.zero]: 'column',
							[breakpoints.tablet]: 'row',
						}}
						rowGap="40px"
						justifyContent="space-between"
						alignItems="start"
						className={styles.expectedRevenue}
					>
						<FlexBlock columnGap="10px" alignItems="center">
							<h2>Enter your expected revenue for the current period</h2>
							<Tooltip
								placement="right"
								text="Estimate and enter your expected revenue for this full year in millions of dollars. This projection is a necessary step in calculating emissions based on intensity."
							/>
						</FlexBlock>
						<FormikInput
							type="number"
							name="expectedRevenue"
							min={0.0001}
							helperText="million EUR"
							className={styles.inputField}
						/>
					</FlexBlock>
					<InfoStatus
						status="warning"
						text={`With no action leading to carbon reductions, your company will emit 
							 ${carbonEmitCo2} tons of CO₂ in the current period.`}
					/>
				</FlexBlock>
			)}
			<FlexBlock flexDirection="column" rowGap="72px" className={styles.targetWrapper}>
				<FlexBlock
					flexDirection={{
						[breakpoints.zero]: 'column',
						[breakpoints.tablet]: 'row',
					}}
					rowGap="40px"
					justifyContent="space-between"
					alignItems="start"
				>
					<FlexBlock columnGap="10px" alignItems="center">
						<h2>Select reduction percentage</h2>
						<Tooltip
							placement="right"
							text="Provide your company's revenue from the last full year in millions of dollars. This will allow us to establish the ratio of emissions to economic output."
						/>
					</FlexBlock>
					<FormikSelect
						required
						name="reductionPercentage"
						options={reductionOptions}
						className={styles.selectField}
					/>
				</FlexBlock>
				{values.type === 'intensity' && (
					<InfoStatus
						status="success"
						text={`Your current business travel carbon intensity is ${carbonEmitCo2} tons of CO₂ per million dollars in revenue. Your selection will set a benchmark for future efforts and progress tracking. Aim for a target that aligns with industry standards and your company’s sustainability vision: ${values.targetCo2} tons of CO₂ per million dollars in revenue.`}
					/>
				)}
			</FlexBlock>

			<FlexBlock
				flexDirection={{
					[breakpoints.zero]: 'column-reverse',
					[breakpoints.tablet]: 'row',
				}}
				justifyContent="space-between"
				columnGap="20px"
				rowGap="20px"
				className={styles.mainCalculationWrapper}
			>
				<div className={styles.co2Target}>
					<FlexBlock
						flexDirection={{
							[breakpoints.zero]: 'column-reverse',
							[breakpoints.tablet]: 'row',
						}}
						rowGap="8px"
						justifyContent="space-between"
						alignItems="start"
					>
						<div className={styles.header}>
							<p>Company target</p>
							<p>Tons of CO₂</p>
						</div>
						<span className={styles.year}>
							{getPeriodDates(values.periodStartDay, values.periodStartMonth)}
						</span>
					</FlexBlock>
					<p className={styles.calculation}>{values.targetCo2}</p>
				</div>
				<div className={styles.imageWrapper}>
					<TravelerIllustration />
				</div>
			</FlexBlock>
		</div>
	);
};

export default SelectTarget;
