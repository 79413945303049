import axios from 'axios';
import queryString from 'query-string';
import GoogleAutocompleteRequest = App.Data.Request.GoogleAutocompleteRequest;
import GoogleAutocompleteResponse = App.Data.Response.GoogleAutocompleteResponse;
import SearchAirportsRequest = App.Data.Request.SearchAirportsRequest;
import SearchAirportsResponse = App.Data.Response.SearchAirportsResponse;

export const searchAutocomplete = async (data: GoogleAutocompleteRequest): Promise<GoogleAutocompleteResponse> => {
	const response = await axios.get(
		`/google-places/autocomplete?${queryString.stringify(data, { arrayFormat: 'bracket' })}`,
	);

	return response.data;
};

export const listAirports = async (data: SearchAirportsRequest): Promise<SearchAirportsResponse> => {
	const response = await axios.get(`/airports?${queryString.stringify(data, { arrayFormat: 'bracket' })}`);
	return response.data;
};
