import React, { useEffect } from 'react';
import Spinner from '@/components/ui/Spinner/Spinner.tsx';
import { logout } from '@/api/auth.ts';
import routes from '@/constants/routes.ts';
import { useWhoamiStore } from '@/store/useWhoamiStore.ts';
import { useNavigate } from 'react-router';

const Logout: React.FC = () => {
	const { loggedIn, organizationLoginPageSlug } = useWhoamiStore(state => state);
	const navigate = useNavigate();

	const processLogout = async () => {
		try {
			await logout();

			if (organizationLoginPageSlug) {
				window.location.href = routes.login + '/' + organizationLoginPageSlug;
			} else {
				window.location.href = routes.login;
			}
		} catch (error) {
			navigate(routes.myClimate);
		}
	};

	useEffect(() => {
		if (loggedIn) {
			processLogout();
			return;
		}

		navigate(routes.login);
	}, []);

	return <Spinner />;
};

export default Logout;
