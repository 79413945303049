import React from 'react';
import styles from './SelectType.module.scss';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';
import FormikSwitchSelect from '@/components/Formik/FormikSwitchSelect.tsx';
import { targetTypeOptions } from '@/constants/emissionTarget.ts';
import breakpoints from '@/constants/breakpoints.ts';

const SelectType: React.FC = () => {
	return (
		<div className={styles.selectTypeContent}>
			<p className={styles.introText}>There’s two paths you can take when setting an emission target</p>
			<div className={styles.typeWrapper}>
				<div>
					<h2>Absolute</h2>
					<p>
						An absolute emissions reduction target focuses on reducing the total quantity of greenhouse gas
						emissions (GHG) emitted by a company or entity. As a simple example, if our company emits 1,000
						metric tons (MT) of CO2-e in 2021 and our goal is to reduce absolute emissions by 10% in 2022,
						we need to reduce our annual emissions by 100 MT year-over-year from 1,000 to 900 MT of CO2-e.
					</p>
				</div>
			</div>
			<div className={styles.typeWrapper}>
				<div>
					<FlexBlock justifyContent="space-between" alignItems="center">
						<h2>Intensity</h2>
						<p className={styles.additionalInfo}>Recommended</p>
					</FlexBlock>
					<p>
						An intensity-based emissions target compares your organizations emissions to some unit of
						economic output. Examples could be revenue-based (i.e., MT of CO₂ per million dollars in sales),
						per capita or per employee, per building or facility, per unit or finished product, or another
						material operational indicator.
					</p>
				</div>
			</div>
			<FlexBlock
				flexDirection={{
					[breakpoints.zero]: 'column',
					[breakpoints.tablet]: 'row',
				}}
				justifyContent="space-between"
				alignItems="center"
				rowGap="28px"
				className={styles.typeWrapper}
			>
				<h2>Choose preferable type of reduction target</h2>
				<FormikSwitchSelect name="type" options={targetTypeOptions} />
			</FlexBlock>
		</div>
	);
};

export default SelectType;
