import React, { useEffect, useState } from 'react';
import { APIProvider, Map, Marker, useMap } from '@vis.gl/react-google-maps';
import Modal from '@/components/ui/Modal/Modal.tsx';
import { useModalStore } from '@/store/useModalStore.ts';
import polyline from '@mapbox/polyline';
import { modeColors } from '@/components/MapPreview/MapPreview.tsx';
import GoogleDirectionsRouteData = App.Data.GoogleDirectionsRouteData;

interface MapModalProps {
	mode?: App.Enums.TransportationMode;
	route?: GoogleDirectionsRouteData;
	markers?: { lat: number; lng: number }[];
}

const MapContent: React.FC<MapModalProps> = ({ route, markers, mode }) => {
	const map = useMap();
	const [path, setPath] = useState<any[]>([]);

	useEffect(() => {
		if (route && map) {
			const bounds = new window.google.maps.LatLngBounds();
			const fullPath: any[] = [];

			const decodedPolyline = polyline.decode(route.polyline).map(([lat, lng]) => ({
				lat,
				lng,
			}));
			decodedPolyline.forEach((point: any) => bounds.extend(point));
			fullPath.push(...decodedPolyline);

			setPath(fullPath);
			map.fitBounds(bounds);
		}
	}, [route, map]);

	useEffect(() => {
		if (route && path.length > 0 && map) {
			const polylinePath = new window.google.maps.Polyline({
				path: path,
				geodesic: true,
				strokeColor: modeColors[mode as keyof typeof modeColors] || '#4285F4',
				strokeOpacity: 0.8,
				strokeWeight: 5,
			});

			polylinePath.setMap(map);

			return () => polylinePath.setMap(null);
		}
	}, [path, route, map]);

	useEffect(() => {
		if (!route && markers && markers.length > 0 && map) {
			const bounds = new window.google.maps.LatLngBounds();
			markers.forEach(marker => bounds.extend(new google.maps.LatLng(marker.lat, marker.lng)));
			map.fitBounds(bounds);

			// Force a specific zoom level after fitBounds
			const listener = map.addListener('idle', () => {
				if (markers.length === 1) {
					map.setZoom(15);
				} else {
					const calculatedZoom = Math.max(8, Math.min(12, map.getZoom() || 10)); // Dynamic zoom
					map.setZoom(calculatedZoom);
				}
				window.google.maps.event.removeListener(listener); // Clean up listener
			});
		}
	}, [route, markers, map]);

	return (
		<>
			{!route &&
				markers &&
				markers.map((marker, index) => <Marker key={index} position={{ lat: marker.lat, lng: marker.lng }} />)}
		</>
	);
};

const MapModal: React.FC<MapModalProps> = ({ mode, route, markers }) => {
	const { activeModal } = useModalStore();

	if (activeModal !== 'mapModal') return null;

	const apiKey = import.meta.env.VITE_GOOGLE_CLOUD_API_KEY;

	const defaultCenter = (route?.centerCoordinates && {
		lat: route.centerCoordinates.latitude,
		lng: route.centerCoordinates.longitude,
	}) || { lat: 0, lng: 0 };
	const center = markers?.length === 1 ? markers[0] : defaultCenter;

	const mapOptions: google.maps.MapOptions = {
		zoomControl: true,
		scrollwheel: true,
		disableDoubleClickZoom: false,
		gestureHandling: 'greedy',
	};

	// @ts-ignore
	return (
		<Modal name="mapModal">
			<APIProvider apiKey={apiKey}>
				<div style={{ height: '90vh', width: '90vw' }}>
					<Map {...(mapOptions as any)} defaultZoom={14} defaultCenter={center}>
						<MapContent mode={mode} route={route} markers={markers} />
					</Map>
				</div>
			</APIProvider>
		</Modal>
	);
};

export default MapModal;
