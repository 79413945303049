import axios from 'axios';
import { User } from '@/api/user.ts';
import LoginRequest = App.Data.Request.LoginRequest;
import EnableUserTwoFaRequest = App.Data.Request.EnableUserTwoFaRequest;
import OnboardNewUserRequest = App.Data.Request.OnboardNewUserRequest;
import GetOrganizationPageResponse = App.Data.Response.GetOrganizationPageResponse;

interface LogoutResponse {
	message: string;
}

export interface WhoamiResponse {
	accessCountry?: string;
	bookingEngine?: string;
	currency?: string;
	locale?: string;
	spotnanaSsoUrl?: string;
	weGlotLanguageCode?: string;
	user?: User;
	roles?: string[];
	ssoUrls?: {
		google: string;
		microsoft: string;
	};
	redirectUrl?: string;
	twoFaEnabled?: boolean;
	twoFaUrl?: string;
	twoFaSecret?: string;
	organizationEmissionTarget?: number | null;
	organizationLoginPageSlug?: string | null;
}

export interface SsoUserRegisterRequest {
	organizationId: number | undefined;
	token: string;
	provider: string;
	legalEntityId: number | undefined;
}

export interface SsoGetUsersOrganizationRequest {
	provider: string;
	token: string;
}

export interface LegalEntity {
	id: number;
	name: string;
	currency: string;
	countryCode: string;
	city: string;
}

export interface SsoGetUsersOrganizationResponse {
	organizations: {
		countryCode: string;
		id: number;
		name: string;
		legalEntities: LegalEntity[];
	}[];
}

export interface SpotananaWhoamiResponse {
	loggedIn: boolean;
	userUuid?: string;
	ssoUrl?: string;
}

export interface RegisterUserRequest {
	firstName: string;
	middleName?: string;
	lastName: string;
	email: string;
	jobTitle?: string;
	employeeNumber?: string;
	phone?: string;
	legalEntityId: number | undefined;
}

export interface OnboardNewUserRequestExtended extends OnboardNewUserRequest {
	onboardToken: string;
}

export const enableTwoFactor = async (data: EnableUserTwoFaRequest): Promise<void> => {
	const response = await axios.patch('/users/2fa/enable', data);
	return response.data;
};

export const disableTwoFactor = async (): Promise<void> => {
	const response = await axios.patch('/users/2fa/disable');
	return response.data;
};

export const login = async (requestData: LoginRequest): Promise<void> => {
	const response = await axios.post('/users/login', requestData);
	return response.data;
};

export const logout = async (): Promise<LogoutResponse> => {
	const response = await axios.post('/users/logout');
	return response.data;
};

export const whoami = async (): Promise<WhoamiResponse> => {
	const response = await axios.get<WhoamiResponse>('/users/whoami');
	return response.data;
};

export const spotnanaWhoami = async (targetPath: string | null): Promise<SpotananaWhoamiResponse> => {
	const response = await axios.get<SpotananaWhoamiResponse>('/spotnana/whoami?targetPath=' + targetPath);
	return response.data;
};

export const forgotPassword = async (email: string): Promise<void> => {
	const response = await axios.post('/users/password/forgot', {
		email,
	});
	return response.data;
};

export const resetPassword = async (email: string, password: string, token: string): Promise<void> => {
	const response = await axios.post('/users/password/reset', {
		email,
		password,
		token,
	});
	return response.data;
};

export const ssoUserRegister = async (data: SsoUserRegisterRequest): Promise<void> => {
	const response = await axios.post(`/auth/${data.provider}/users`, data);
	return response.data;
};

export const ssoGetUsersOrganization = async (
	data: SsoGetUsersOrganizationRequest,
): Promise<SsoGetUsersOrganizationResponse> => {
	const response = await axios.get(`/auth/${data.provider}/organizations`, {
		params: data,
	});
	return response.data;
};

//  Onboarding employee
export const registerUser = async (data: OnboardNewUserRequestExtended): Promise<OnboardNewUserRequest> => {
	const response = await axios.post(`/organizations/${data.onboardToken}/onboard-user`, data);
	return response.data;
};

export const getOrganizationLogin = async (organizationSlug: string): Promise<GetOrganizationPageResponse> => {
	const response = await axios.get(`/organization-pages/${organizationSlug}/login`);
	return response.data;
};
