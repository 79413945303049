import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import queryKeys from '@/constants/queryKeys';
import { getMyClimateCarbonFootprint } from '@/api/myClimate';
import styles from './Source.module.scss';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock';
import classNames from 'classnames';
import Skeleton from 'react-loading-skeleton';
import { upperFirst } from 'lodash';
import { formatWeight } from '@/utils/stringUtils.ts';
import noDataIcon from '@/assets/Icons/No-Data.svg';
import Co2Period = App.Enums.Co2Period;

const COLORS_BOOKING_TYPES = ['#D1EC50', '#5683D2', '#AABCA3', '#E067C9', '#677591'];
const COLORS_BOOKING_TYPES_HOVER = ['#D1EC50', '#5683D2', '#AABCA3', '#E067C9', '#677591'];

interface SourceProps {
	period: Co2Period;
}

const Source: React.FC<SourceProps> = ({ period }) => {
	const [activeIndex, setActiveIndex] = useState<number | null>(null);

	const { data, isLoading, isError } = useQuery({
		queryKey: [queryKeys.getMyClimateCarbonFootprint, period],
		queryFn: () => getMyClimateCarbonFootprint({ period }),
	});

	const isNoEmission = data?.totalEmission === 0;

	const mockData = {
		totalEmission: 0,
		bookingTypes: {
			flights: { emission: 0, emissionPercentage: 50 },
			trains: { emission: 0, emissionPercentage: 12 },
			car: { emission: 0, emissionPercentage: 15 },
			bus: { emission: 0, emissionPercentage: 12 },
			hotel: { emission: 0, emissionPercentage: 11 },
		},
	};

	const displayData = isNoEmission ? mockData : data;

	const bookingPieData = React.useMemo(() => {
		if (!displayData) return [];

		return Object.entries(displayData.bookingTypes).map(([type, { emissionPercentage }]) => ({
			name: type,
			value: emissionPercentage,
		}));
	}, [displayData]);

	const CustomTooltip = ({ active, payload }: { active?: boolean; payload?: Array<any> }) => {
		if (active && payload && payload.length) {
			const colorIndex = bookingPieData.findIndex(item => item.name === payload[0].name);
			return (
				<div className={styles.customTooltip}>
					<h1>CO2 emission</h1>
					<div className={styles.tooltipContent}>
						<span className={styles.dot} style={{ backgroundColor: COLORS_BOOKING_TYPES[colorIndex] }} />
						<span>{upperFirst(payload[0].name)}</span>
						<span>{`${payload[0].value.toFixed(1)}%`}</span>
					</div>
				</div>
			);
		}
		return null;
	};
	if (isLoading) {
		return (
			<div className={styles.sourceWrapper}>
				<FlexBlock className={styles.source}>
					<div className={styles.pieChartContainer}>
						<div style={{ margin: '10px' }}>
							<Skeleton circle height={205} width={205} />
						</div>
					</div>
					<div className={styles.infoContainer} style={{ paddingLeft: '32px' }}>
						<Skeleton width={70} height={26} style={{ marginBottom: '12px' }} />
						<Skeleton width={170} height={35} />
						<div className={styles.legendContainer}>
							{Array.from({ length: 5 }).map((_, index) => (
								<FlexBlock
									key={index}
									flexDirection="column"
									alignItems="center"
									className={styles.legendItem}
								>
									<div className={styles.topRow} style={{ paddingLeft: '5px' }}>
										<Skeleton circle width={24} height={24} />
										<Skeleton width={70} />
									</div>
									<Skeleton width={94} style={{ marginBottom: '44.2px', marginLeft: '5px' }} />
								</FlexBlock>
							))}
						</div>
					</div>
				</FlexBlock>
			</div>
		);
	}

	if (isError) {
		return <div className={styles.failedRequestMessage}>Failed to load the data. Please try again later.</div>;
	}

	return (
		<div className={styles.sourceWrapper}>
			<FlexBlock className={styles.source}>
				<div className={styles.pieChartContainer}>
					<ResponsiveContainer width="100%" height="100%">
						<PieChart>
							<Pie
								data={bookingPieData}
								dataKey="value"
								nameKey="name"
								cx="50%"
								cy="50%"
								outerRadius={100}
								onMouseEnter={(_, index) => setActiveIndex(index)}
								onMouseLeave={() => setActiveIndex(null)}
								stroke="none"
							>
								{bookingPieData.map((_, index) => (
									<Cell
										key={`cell-booking-${index}`}
										fill={
											activeIndex === index
												? COLORS_BOOKING_TYPES_HOVER[index]
												: COLORS_BOOKING_TYPES[index % COLORS_BOOKING_TYPES.length]
										}
										opacity={activeIndex === null || activeIndex === index ? 1 : 0.3}
									/>
								))}
							</Pie>
							<Tooltip content={<CustomTooltip />} />
						</PieChart>
					</ResponsiveContainer>
				</div>
				<div className={styles.infoContainer}>
					<h2>Total</h2>
					<h1>{displayData?.totalEmission && formatWeight(+displayData.totalEmission.toFixed(2))}CO₂</h1>
					<div className={styles.legendContainer}>
						{bookingPieData.map((item, index) => (
							<FlexBlock
								flexDirection="column"
								alignItems="center"
								key={item.name}
								className={classNames(styles.legendItem, {
									[styles.active]: activeIndex === index,
									[styles.inactive]: activeIndex !== null && activeIndex !== index,
								})}
								style={{
									color: activeIndex === index ? COLORS_BOOKING_TYPES_HOVER[index] : 'inherit',
								}}
							>
								<div className={styles.topRow}>
									<span
										className={styles.dot}
										style={{
											backgroundColor:
												activeIndex === index
													? COLORS_BOOKING_TYPES_HOVER[index]
													: COLORS_BOOKING_TYPES[index % COLORS_BOOKING_TYPES.length],
										}}
									/>
									<span className={styles.label}>{upperFirst(item.name)}</span>
								</div>
								<span className={styles.percentage}>{item.value.toFixed(1)}%</span>
							</FlexBlock>
						))}
					</div>
				</div>
			</FlexBlock>
			{isNoEmission && (
				<div className={styles.overlay}>
					<div className={styles.overlayContent}>
						<img src={noDataIcon} alt="No data" className={styles.noDataImage} />
						<h3 className={styles.overlayTitle}>No data available</h3>
						<p className={styles.overlaySubtitle}>
							Try selecting another date range, or there isn’t enough information to generate insights.
						</p>
					</div>
				</div>
			)}
		</div>
	);
};

export default Source;
