import React, { FocusEventHandler } from 'react';
import styles from './TextArea.module.scss';
import classNames from 'classnames';

export interface TextAreaProps {
	disabled?: boolean;
	placeholder?: string;
	resize?: boolean;
	className?: string;
	onChange?: (e: any) => void;
	onBlur?: FocusEventHandler | undefined;
	value: string;
	name?: string;
	errorMessage?: string;
	touched?: boolean;
	label?: string;
}

const TextArea: React.FC<TextAreaProps> = ({
	disabled,
	placeholder,
	resize = true,
	onChange,
	value,
	name,
	label,
	onBlur,
	errorMessage,
	touched,
	className,
}) => {
	return (
		<div className={classNames(styles.textAreaWrapper, className)}>
			{!!label && <p className={styles.label}>{label}</p>}
			<textarea
				name={name}
				onBlur={onBlur}
				value={value || ''}
				onChange={onChange}
				disabled={disabled}
				placeholder={placeholder}
				className={classNames(styles.textarea, resize && styles.resize, disabled && styles.disabled)}
			></textarea>
			{touched && errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
		</div>
	);
};

export default TextArea;
