import React from 'react';
import { pagix } from 'pagix';
import classNames from 'classnames';
import styles from './Pagination.module.scss';
import ArrowRight from '@/assets/Icons/Arrow-Right.svg?react';
import DoubleArrowRight from '@/assets/Icons/Double-Arrow-Right.svg?react';
import ArrowLeft from '@/assets/Icons/Arrow-Left.svg?react';
import DoubleArrowLeft from '@/assets/Icons/Double-Arrow-Left.svg?react';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';

interface PaginationProps {
	records: number;
	limit?: number;
	current: number;
	fixed: number;
	delta: number;
	onChange: (num: number) => void;
}

interface PageProps {
	idx: number;
	active: boolean;
	onChange: (num: number) => void;
}

const Page = ({ idx, active, onChange }: PageProps) => {
	const handleClick = () => onChange(idx);
	return (
		<button onClick={handleClick} className={classNames(active && styles.active)}>
			{idx}
		</button>
	);
};

const Pagination: React.FC<PaginationProps> = ({ records, limit, current, delta, fixed, onChange }) => {
	const { start, end, middle, prev, next, from, to } = pagix({
		records,
		limit,
		current,
		delta,
		fixed,
	});

	const goToPrev = () => prev && onChange(prev);
	const goToNext = () => next && onChange(next);
	const goToFirst = () => onChange(start[0]);
	const goToLast = () => onChange(end[0]);

	return (
		<FlexBlock justifyContent="space-between" alignItems="center" className={styles.pagination}>
			<p className={styles.rangeDisplay}>
				<span>{from}</span> - <span>{to}</span> of <span>{records}</span> displayed
			</p>
			<div className={styles.paginationWrapper}>
				<button onClick={goToFirst} disabled={current === 1}>
					<DoubleArrowLeft />
				</button>
				<button onClick={() => onChange(current - 1)} disabled={current === 1}>
					<ArrowLeft />
				</button>
				{start.map(idx => (
					<Page key={idx} idx={idx} active={current === idx} onChange={onChange} />
				))}
				{prev && <button onClick={goToPrev}>...</button>}
				{middle.map((idx: number) => (
					<Page key={idx} idx={idx} active={current === idx} onChange={onChange} />
				))}
				{next && <button onClick={goToNext}>...</button>}
				{end.map((idx: number) => (
					<Page key={idx} idx={idx} active={current === idx} onChange={onChange} />
				))}
				<button onClick={() => onChange(current + 1)} disabled={current === end[0]}>
					<ArrowRight />
				</button>
				<button onClick={goToLast} disabled={current === end[0]}>
					<DoubleArrowRight />
				</button>
			</div>
		</FlexBlock>
	);
};

export default Pagination;
