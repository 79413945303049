import moment from 'moment/moment';

export const formatDistance = (distanceMeters: number) => {
	if (distanceMeters >= 1000) {
		const km = distanceMeters / 1000;
		return (km % 1 === 0 ? km.toFixed(0) : km.toFixed(1)) + ' km';
	}
	return Math.floor(distanceMeters) + ' m';
};

export const formatDuration = (seconds: number) => {
	const duration = moment.duration(seconds, 'seconds');
	const hours = duration.hours();
	const minutes = duration.minutes();

	if (hours > 0) {
		return `${hours}h ${minutes}min`;
	} else if (minutes > 0) {
		return `${minutes}min`;
	} else {
		return '<1min';
	}
};

export function formatWeight(kilos: number): string {
	if (kilos < 1000) {
		return `${Math.floor(kilos)} kg`;
	} else {
		const tons = (kilos / 1000).toFixed(2);
		return `${tons} t`;
	}
}
