import React, { useEffect, useMemo, useState } from 'react';
import styles from './AIPredictiveAnalytics.module.scss';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';
import { useQuery } from '@tanstack/react-query';
import queryKeys from '@/constants/queryKeys.ts';
import Select from '@/components/ui/Select/Select.tsx';
import { getCo2Analytics, getEntitiesFilters } from '@/api/climatePro.ts';
import { transformDataToGroupedOptionsArray } from '@/pages/ClimateProDashboard/Metrics/Metrics.tsx';
import { periodOptions } from '@/constants/climatePro.ts';
import { useWhoamiStore } from '@/store/useWhoamiStore.ts';
import useDashboardAnalytics from '@/store/useDashboardAnalytics.ts';
import ForecastChart from '@/pages/ClimateProDashboard/AIPredictiveAnalytics/ForecastChart/ForecastChart.tsx';
import Co2SourceModelClass = App.Enums.Co2SourceModelClass;
import Co2Period = App.Enums.Co2Period;
import breakpoints from '@/constants/breakpoints.ts';

const AiPredictiveAnalytics: React.FC = () => {
	const { organizationEmissionTarget } = useWhoamiStore();

	const [entity, setEntity] = useState<string | undefined>(undefined);
	const { setContribution, setTimePeriod, timePeriod } = useDashboardAnalytics();

	const { data: entitiesFilter, isSuccess } = useQuery({
		queryKey: [queryKeys.entitiesFilter],
		queryFn: getEntitiesFilters,
	});

	const query = useQuery({
		queryKey: [queryKeys.co2Analytics, timePeriod],
		queryFn: async () => {
			const [sourceModelClass, sourceModelId] = entity!.split('.');
			const data = {
				period: timePeriod,
				sourceModelClass: sourceModelClass as Co2SourceModelClass,
				sourceModelId: +sourceModelId,
			};
			setContribution(sourceModelClass as Co2SourceModelClass);
			return await getCo2Analytics(data);
		},
		enabled: false,
	});

	useEffect(() => {
		setEntity(`${entitiesFilter?.entities[0].value}.${entitiesFilter?.entities[0].items[0].value}`);
	}, [isSuccess]);

	useEffect(() => {
		entity && query.refetch();
	}, [entity]);

	useEffect(() => {
		timePeriod && query.refetch();
	}, [timePeriod]);

	const availablePeriodOptions = useMemo(() => {
		return periodOptions.filter(
			option => !(option.value === 'current-period' && organizationEmissionTarget === null),
		);
	}, [organizationEmissionTarget]);

	return (
		<FlexBlock flexDirection="column" className={styles.aiPredictiveWrapper}>
			<FlexBlock
				flexDirection={{
					[breakpoints.zero]: 'column',
					[breakpoints.tablet]: 'row',
				}}
				rowGap="16px"
				justifyContent="space-between"
				className={styles.header}
			>
				<h1>AI predictive CO2 analytics</h1>
				<FlexBlock
					flexDirection={{
						[breakpoints.zero]: 'column',
						[breakpoints.tablet]: 'row',
					}}
					justifyContent="space-between"
					columnGap="32px"
					rowGap="12px"
				>
					<Select
						required
						searchable
						value={entity}
						onChange={value => setEntity(value as string)}
						name="sourceModelId"
						options={transformDataToGroupedOptionsArray(entitiesFilter?.entities || [])}
						className={styles.selectEntityDropdown}
					/>
					<Select
						required
						value={timePeriod}
						onChange={value => value && setTimePeriod(value as Co2Period)}
						placeholder="Choose period"
						options={availablePeriodOptions}
						className={styles.selectDropdown}
					/>
				</FlexBlock>
			</FlexBlock>
			<p className={styles.additionalInfo}>The following suggestions would help limit your emissions</p>
			<ForecastChart analyticsQuery={query} />
		</FlexBlock>
	);
};

export default AiPredictiveAnalytics;
