import {
	Area,
	AreaChart,
	Label,
	Legend,
	LegendProps,
	ReferenceLine,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	XAxisProps,
	YAxis,
	YAxisProps,
} from 'recharts';
import styles from './BudgetEmissionChart.module.scss';
import React, { FC } from 'react';
import { UseQueryResult } from '@tanstack/react-query';
import useDashboardAnalytics from '@/store/useDashboardAnalytics.ts';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import Overlay from '@/components/ui/Overlay/Overlay.tsx';
import ChartTooltip from '@/pages/ClimateProDashboard/ChartTooltip/ChartTooltip.tsx';
import ArrowDown from '@/assets/Icons/Arrow-Down.svg?react';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';
import ListClimateProCo2SegmentsResponse = App.Data.Response.ListClimateProCo2SegmentsResponse;
import Co2SegmentData = App.Data.Co2SegmentData;

interface BudgetEmissionChartProps {
	queryAnalytics: UseQueryResult<ListClimateProCo2SegmentsResponse | null, unknown>;
}

type ChartKey = 'flight' | 'car' | 'hotel' | 'rail';

const chartDetail: Record<ChartKey, { fill: string; stroke: string; dataKey: string; label: string }> = {
	flight: {
		fill: '#FAD9C5',
		stroke: '#E96516',
		dataKey: 'flight',
		label: 'Flight',
	},
	car: {
		fill: '#DFF6ED',
		stroke: '#2FAA7B',
		dataKey: 'car',
		label: 'Car',
	},
	hotel: {
		fill: '#E9DDFD',
		stroke: '#9861F6',
		dataKey: 'hotel',
		label: 'Hotel',
	},
	rail: {
		fill: '#D8E8FF',
		stroke: '#4894FF',
		dataKey: 'rail',
		label: 'Rail',
	},
};

interface CustomTickProps extends XAxisProps {
	x: number;
	y: number;
	payload: {
		value: string;
	};
}

const CustomTick: React.FC<CustomTickProps> = ({ x, y, payload }) => (
	<g transform={`translate(${x},${y})`}>
		<text x={0} y={0} dy={-3} fontSize={8} textAnchor="middle" fill="#003C37">
			{moment(payload.value, 'YYYY-MM').format('MMM')}
		</text>
	</g>
);

interface CustomTickYProps extends YAxisProps {
	x: number;
	y: number;
	payload: {
		value: number;
	};
	total: number;
}

const CustomTickY: React.FC<CustomTickYProps> = ({ x, y, payload }) => {
	const value = payload?.value;
	// const isOutRange = value >= total;
	return (
		<g transform={`translate(${x},${y})`}>
			<text x={0} y={0} dx={-10} fontSize={9} textAnchor="middle" fill="#003C37" fillRule="evenodd">
				{value && +value.toFixed(2)}
			</text>
		</g>
	);
};

const CustomLegend: React.FC<LegendProps> = ({ payload }) => (
	<ul className={styles.customLegendWrapper}>
		{payload?.map((entry, index) => (
			<li key={`item-${index}`}>
				<svg width="18" height="18" viewBox="0 0 20 20">
					<circle cx="10" cy="10" r="7" style={{ fill: entry.color }} />
				</svg>
				<span className={styles.label}>
					{/* @ts-ignore */}
					{entry?.payload?.label || 'value'}
				</span>
			</li>
		))}
	</ul>
);

const findMaxValue = (segments: Co2SegmentData[]) => {
	if (!segments || segments.length === 0) return 0;

	const values = segments.flatMap(segment => [segment.car, segment.flight, segment.hotel, segment.rail]);

	const maxValue = Math.max(...values.filter(value => value !== undefined)) || 0;
	return maxValue;
};

const BudgetEmissionChart: FC<BudgetEmissionChartProps> = ({ queryAnalytics }) => {
	const { timePeriod, contribution } = useDashboardAnalytics();
	const budget = queryAnalytics?.data?.emissionTarget;

	const chartData = queryAnalytics.data?.segments;

	if (chartData?.length === 1) {
		chartData.push({
			date: moment(Object.keys(chartData), 'YYYY-MM').endOf('year').format('YYYY-MM'),
			car: 0,
			rail: 0,
			flight: 0,
			hotel: 0,
		});
	}

	const max = Math.max(findMaxValue(chartData!) || 0, budget || 0);

	const loading = queryAnalytics.isLoading || queryAnalytics.isRefetching;
	const isError = queryAnalytics.isError;

	if (loading) {
		return (
			<div className={styles.skeletonWrapper}>
				<Skeleton
					width="30%"
					height="26px"
					style={{ margin: '18px 0' }}
					containerClassName={styles.skeletonLegend}
				/>
				<Skeleton width="100%" height="345px" />
			</div>
		);
	}

	return (
		<div className={styles.chartSectionWrapper}>
			<div className={styles.chartContainer}>
				<ResponsiveContainer width="100%" height="100%" minHeight="150px">
					{/* @ts-ignore */}
					<AreaChart data={chartData} margin={{ top: 30, right: 0, left: 0, bottom: 0 }}>
						<Tooltip
							cursor={false}
							content={<ChartTooltip labelMap={chartDetail} extralabel="Tons of CO2" />}
						/>
						<Legend
							wrapperStyle={{ marginTop: '-80px' }}
							content={<CustomLegend />}
							verticalAlign="top"
							align="right"
						/>

						{(['car', 'flight', 'hotel', 'rail'] as ChartKey[]).map(chartKey => (
							<Area
								className={`gw-chart-${chartKey}`}
								key={chartKey}
								type="monotone"
								dataKey={chartKey}
								label={chartDetail[chartKey].label}
								fill={chartDetail[chartKey].fill}
								stroke={chartDetail[chartKey].stroke}
								strokeWidth={2}
								stackId={10}
								strokeOpacity={1}
								fillOpacity={1}
							/>
						))}
						{/* @ts-ignore */}
						{budget && contribution == 'company' && timePeriod === 'current-period' && (
							<ReferenceLine
								y={budget}
								stroke="#668A87"
								fill="#668A87"
								strokeDasharray="16 16"
								strokeWidth={0.8}
							>
								<Label
									value="CO2 Budget target"
									position="insideBottomLeft"
									dx={10}
									dy={-3}
									fontSize={10}
									className={styles.referenceLabel}
								/>
							</ReferenceLine>
						)}
						<XAxis
							mirror
							dataKey="date"
							tickLine={false}
							axisLine={false}
							fontSize={8}
							interval="preserveStartEnd"
							color="#003C37"
							opacity={1}
							// @ts-ignore
							tick={<CustomTick />}
						/>
						<YAxis
							mirror
							type="number"
							color="#003C37"
							fontSize={12}
							tickLine={false}
							orientation="right"
							axisLine={false}
							domain={[0, max]}
							// @ts-ignore
							tick={<CustomTickY total={4000} />}
						/>
					</AreaChart>
				</ResponsiveContainer>
				{isError && <Overlay text="No Data Available" subtext="Error Occurred. Please try again later" />}
				<FlexBlock alignItems="center" columnGap="6px" className={styles.sideInfo}>
					Tons of CO2
					<ArrowDown />
				</FlexBlock>
			</div>
		</div>
	);
};

export default BudgetEmissionChart;
