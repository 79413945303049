import React, { JSX, useEffect, useMemo, useRef, useState } from 'react';
import styles from './EmissionTarget.module.scss';
import { useEmissionTargetStore } from '@/store/useEmissionTargetStore.ts';
import { FormikHelpers, FormikProvider, FormikTouched, useFormik } from 'formik';
import {
	defaultInitialValues,
	getPeriodDates,
	ShownHeaderSteps,
	STEPS,
	submenuLinks,
} from '@/constants/emissionTarget.ts';
import validationSchemaEmissionTarget from '@/validations/emissionTarget.ts';
import handleResponseErrors from '@/utils/handleResponseErrors.ts';
import Spinner from '@/components/ui/Spinner/Spinner.tsx';
import { isEmpty, upperFirst } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import queryKeys from '@/constants/queryKeys.ts';
import Card from '@/components/ui/Card/Card.tsx';
import TargetIcon from '@/assets/Icons/Target.svg?react';
import CalendarIcon from '@/assets/Icons/Calendar-Schedule-2.svg?react';
import BaselineIcon from '@/assets/Icons/Baseline.svg?react';
import EarthIcon from '@/assets/Icons/Earth-Home-World-2.svg?react';
import SelectType from '@/pages/ClimateProDashboard/EmissionTarget/SelectType.tsx';
import SelectPeriod from '@/pages/ClimateProDashboard/EmissionTarget/SelectPeriod.tsx';
import SelectBaseline from '@/pages/ClimateProDashboard/EmissionTarget/SelectBaseline.tsx';
import SelectTarget from '@/pages/ClimateProDashboard/EmissionTarget/SelectTarget.tsx';
import Button from '@/components/ui/Button/Button.tsx';
import ArrowLeft from '@/assets/Icons/Arrow-Left.svg?react';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';
import InfoIcon from '@/assets/Icons/Info-Line.svg?react';
import ArrowDecrease from '@/assets/Icons/Arrow-Decrease.svg?react';
import ArrowIncrease from '@/assets/Icons/Arrow-Increase.svg?react';
import HistoryIcon from '@/assets/Icons/History.svg?react';
import { AxiosError } from 'axios';
import { Area, AreaChart, ResponsiveContainer, Tooltip as RechartsTooltip, XAxis, YAxis } from 'recharts';
import Flyout, { FlyoutCloseButton } from '@/components/ui/Flyout/Flyout.tsx';
import DoneCheckmarkIcon from '@/assets/Icons/Done-Checkmark.svg?react';
import { useRouteBlocker } from '@/hooks/useRouteBlocker.ts';
import { getEmissionTargets, updateEmissionTargets } from '@/api/climatePro.ts';
import { whoami } from '@/api/auth.ts';
import { useWhoamiStore } from '@/store/useWhoamiStore.ts';
import SubmenuItems from '@/components/SubmenuItems/SubmenuItems.tsx';
import OrganizationEmissionTargetData = App.Data.OrganizationEmissionTargetData;
import breakpoints from '@/constants/breakpoints.ts';
import { useWindowSize } from '@react-hook/window-size';
import PenIcon from '@/assets/Icons/Pen.svg?react';
import classNames from 'classnames';

const showIcon = (activeStep: string) => {
	const icons: { [key: string]: JSX.Element } = {
		type: <TargetIcon />,
		period: <CalendarIcon />,
		baseline: <BaselineIcon />,
		target: <EarthIcon />,
	};
	return icons[activeStep] || null;
};

const CustomChartTooltip: React.FC<{
	active?: boolean;
	payload?: Array<{ value: number; payload: { percentage: number } }>;
}> = ({ active, payload }) => {
	if (active && payload && payload.length) {
		return (
			<div className={styles.customChartTooltip}>
				<p className={styles.label}>{`${payload[0].value} tons`} </p>
				<p className={styles.percentage}>
					{payload[0]?.payload?.percentage < 0 ? <ArrowDecrease /> : <ArrowIncrease />}{' '}
					{`${payload[0]?.payload?.percentage}%`}
				</p>
			</div>
		);
	}
	return null;
};

const EmissionTarget: React.FC = () => {
	const [width] = useWindowSize();
	const setWhoami = useWhoamiStore(state => state.setWhoami);
	const containerRef = useRef<HTMLDivElement>(null);

	const [isEditing, setIsEditing] = useState(false);
	const [currentEmissionTargetData, setCurrentEmissionTargetData] = useState<OrganizationEmissionTargetData | null>(
		null,
	);

	// Show unsaved changes modal when there is a new form values and user wants to navigate to another page
	const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
	useRouteBlocker(hasUnsavedChanges);

	const {
		data: emissionTargetData,
		refetch: refetchEmissionTarget,
		isLoading,
		isError,
		error,
	} = useQuery({
		queryKey: [queryKeys.getEmissionTarget],
		queryFn: async () => {
			return await getEmissionTargets();
		},
	});

	const getChartData = () => {
		const chartData: { name: string; tons: number; percentage: string | number }[] = [];
		emissionTargetData
			?.sort((a, b) => moment(a.createdAt).diff(moment(b.createdAt)))
			.forEach((item, index) => {
				const previousTons = chartData[index - 1]?.tons || null;
				const currentTons = item.targetCo2;
				const percentage =
					previousTons !== null ? (((currentTons - previousTons) / previousTons) * 100).toFixed(2) : 0;

				chartData.push({
					name: moment(item.createdAt).format('DD.MM.YYYY'),
					tons: currentTons,
					percentage: percentage,
				});
			});
		return chartData;
	};

	useEffect(() => {
		emissionTargetData &&
			emissionTargetData.length > 0 &&
			setCurrentEmissionTargetData(emissionTargetData.find(item => item.current) || null);
	}, [emissionTargetData]);

	const { activeStep, changeStep, clickNext, clickBack } = useEmissionTargetStore(state => state);

	const isFirstStep = STEPS.indexOf(activeStep) === 0;

	const initialValues = useMemo(() => {
		if (isError || !emissionTargetData || !currentEmissionTargetData) {
			return defaultInitialValues;
		}
		return {
			...defaultInitialValues,
			...currentEmissionTargetData,
		};
	}, [emissionTargetData, isError, currentEmissionTargetData]);

	const emissionTargetProvider = useFormik<OrganizationEmissionTargetData>({
		// @ts-ignore
		initialValues,
		enableReinitialize: true,
		validationSchema: validationSchemaEmissionTarget[activeStep],
		onSubmit: async (
			values: OrganizationEmissionTargetData,
			formikHelpers: FormikHelpers<OrganizationEmissionTargetData>,
		) => {
			setHasUnsavedChanges(false);
			const transformedValues = {
				...values,
				lastPeriodCo2: values.lastPeriodTravelSpend?.toString().length ? undefined : values.lastPeriodCo2,
				lastPeriodTravelSpend: values.lastPeriodCo2?.toString().length
					? undefined
					: values.lastPeriodTravelSpend,
				lastPeriodRevenue: values.type === 'intensity' ? values.lastPeriodRevenue : undefined,
				expectedRevenue: values.type === 'intensity' ? values.expectedRevenue : undefined,
			};

			try {
				// @ts-ignore
				await updateEmissionTargets(transformedValues);
				await refetchEmissionTarget();
				setWhoami(await whoami());
				setIsEditing(false);
				setHasUnsavedChanges(false);
			} catch (error) {
				handleResponseErrors(error, formikHelpers);
			}
		},
	});
	const { isSubmitting, validateForm, setTouched, handleSubmit } = emissionTargetProvider;

	const handleBack = () => {
		clickBack();
		if (width < breakpoints.tabletWide && containerRef.current) {
			containerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	};

	const handleNext = async () => {
		const errors = await validateForm();

		if (isEmpty(errors)) {
			if (activeStep === 'target') {
				handleSubmit();
				changeStep('type');
			} else {
				clickNext();
			}

			if (width < breakpoints.tabletWide && containerRef.current) {
				containerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
			}
		} else {
			const touched: FormikTouched<OrganizationEmissionTargetData> = {};
			Object.keys(errors).forEach(key => {
				touched[key as keyof FormikTouched<OrganizationEmissionTargetData>] = true;
			});
			await setTouched(touched);
		}
	};

	useEffect(() => {
		setHasUnsavedChanges(emissionTargetProvider.dirty);
	}, [emissionTargetProvider.dirty]);

	useEffect(() => {
		changeStep('type');
	}, []);

	const axiosError = error as AxiosError;
	const is404Error = axiosError?.response?.status === 404;

	if (isLoading) {
		return (
			<Card className={styles.spinnerWrapper}>
				<Spinner absolute />
			</Card>
		);
	}

	// VIEW MODE (No editing)
	if (emissionTargetData && currentEmissionTargetData && !isEditing && !is404Error) {
		return (
			<>
				{width < breakpoints.tabletWide && <SubmenuItems links={submenuLinks} />}
				<FlexBlock justifyContent="space-between" alignItems="center" className={styles.headerWrapper}>
					<h1>CO2 emission target</h1>
					<FlexBlock justifyContent="space-between" alignItems="center" columnGap="16px">
						<Flyout
							id="emissionTargetHistoryFlyout"
							button={
								<button className={styles.iconWrapper}>
									<HistoryIcon />
								</button>
							}
							className={styles.flyoutWrapper}
						>
							<FlexBlock
								justifyContent="space-between"
								alignItems="center"
								className={styles.flyoutHeader}
							>
								<h3>History of emission target changes</h3>
								<FlyoutCloseButton />
							</FlexBlock>
							{emissionTargetData
								.sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)))
								.map((data, index) => (
									<div key={index} className={styles.co2HistoryBox}>
										<FlexBlock justifyContent="space-between" alignItems="center">
											<p className={styles.time}>
												{moment(data.createdAt).format('MMM D, YYYY, h:mm:ss A [GMT]Z')}
											</p>
											{data.current && (
												<FlexBlock
													justifyContent="space-between"
													alignItems="center"
													columnGap="4px"
													className={styles.successMarker}
												>
													<DoneCheckmarkIcon />
													Current
												</FlexBlock>
											)}
										</FlexBlock>
										<table>
											<thead>
												<tr>
													<th>Company target</th>
													<th>Type</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>{data.targetCo2} tons of CO2</td>
													<td>{upperFirst(data.type)}</td>
												</tr>
											</tbody>
										</table>
										<table>
											<thead>
												<tr>
													<th>Period ({upperFirst(data.yearType)})</th>
													<th>Changed By</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>
														{getPeriodDates(data.periodStartDay, data.periodStartMonth)}
													</td>
													<td>{data.createdBy || '-'}</td>
												</tr>
											</tbody>
										</table>
									</div>
								))}
						</Flyout>
						{width > breakpoints.tabletWide ? (
							<Button color="darkGreen" size="medium" onClick={() => setIsEditing(true)}>
								Edit
							</Button>
						) : (
							<button
								className={classNames(styles.iconWrapper, styles.editBttn)}
								onClick={() => setIsEditing(true)}
							>
								<PenIcon />
							</button>
						)}
					</FlexBlock>
				</FlexBlock>
				<Card className={styles.emissionTargetDataExist}>
					<FlexBlock
						flexDirection={{
							[breakpoints.zero]: 'column',
							[breakpoints.tablet]: 'row',
						}}
						flex={1}
						alignItems="stretch"
						columnGap="30px"
						rowGap="16px"
					>
						<FlexBlock flexDirection="column" className={styles.co2Target}>
							<FlexBlock
								flexDirection={{
									[breakpoints.zero]: 'column-reverse',
									[breakpoints.phoneWide]: 'row',
								}}
								justifyContent="space-between"
								alignItems="start"
								rowGap="11px"
							>
								<div className={styles.header}>
									<p>Company target</p>
									<p>Tons of CO₂</p>
								</div>
								<span className={styles.year}>
									{getPeriodDates(
										currentEmissionTargetData.periodStartDay,
										currentEmissionTargetData.periodStartMonth,
									)}
								</span>
							</FlexBlock>
							<p className={styles.calculation}>
								{emissionTargetData && currentEmissionTargetData.targetCo2}
							</p>
							<FlexBlock alignItems="center" columnGap="6px" className={styles.previousEmission}>
								<InfoIcon />
								<p>
									Using an {currentEmissionTargetData.type} type with the reduction of{' '}
									{currentEmissionTargetData.reductionPercentage}%.
								</p>
							</FlexBlock>
						</FlexBlock>
						<div className={styles.chartWrapper}>
							<h2>Historical Changes</h2>
							<ResponsiveContainer
								width="100%"
								height="100%"
								minHeight="150px"
								style={{ marginLeft: -10 }}
							>
								{/* @ts-ignore */}
								<AreaChart
									width={500}
									height={200}
									data={getChartData()}
									syncId="anyId"
									margin={{
										top: 10,
										right: 30,
										left: 10,
										bottom: 10,
									}}
								>
									<defs>
										<linearGradient id="colorTons" x1="0" y1="0" x2="0" y2="1">
											<stop offset="0%" stopColor="#6BD6AD" stopOpacity={1} />
											<stop offset="100%" stopColor="#FFFFFF" stopOpacity={1} />
										</linearGradient>
									</defs>
									<XAxis dataKey="name" axisLine={false} tickLine={false} tick={{ fontSize: 9.5 }} />
									<YAxis axisLine={false} tickLine={false} tick={{ fontSize: 9.5 }} />
									<RechartsTooltip content={<CustomChartTooltip />} />
									<Area
										type="monotone"
										dataKey="tons"
										stroke="#2EA97C"
										strokeWidth={2}
										fill="url(#colorTons)"
										dot={{ stroke: '#2EA97C', fill: '#2EA97C', strokeWidth: 1.5, r: 3 }}
									/>
								</AreaChart>
							</ResponsiveContainer>
						</div>
					</FlexBlock>
				</Card>
			</>
		);
	}

	// EDIT MODE
	return (
		<div className={styles.editModeWrapper} ref={containerRef}>
			<h1>Setting CO2 budget</h1>
			<Card className={styles.setCo2Target}>
				{isSubmitting && <Spinner overlay absolute />}
				<FlexBlock alignItems="center" className={styles.header}>
					{ShownHeaderSteps.find(({ steps }) => steps.includes(activeStep))?.text && (
						<div className={styles.step}>
							<span className={styles.iconWrapper}>{showIcon(activeStep)}</span>
							{ShownHeaderSteps.find(({ steps }) => steps.includes(activeStep))!.text}
						</div>
					)}
				</FlexBlock>
				<FormikProvider value={emissionTargetProvider}>
					<div className={styles.contentContainer}>
						{activeStep === 'type' && <SelectType />}
						{activeStep === 'period' && <SelectPeriod />}
						{activeStep === 'baseline' && <SelectBaseline />}
						{activeStep === 'target' && <SelectTarget />}
					</div>
				</FormikProvider>
				<FlexBlock justifyContent="space-between" alignItems="center">
					{!isFirstStep && (
						<div>
							<Button
								leftIcon={<ArrowLeft />}
								size="medium"
								hover={false}
								type="button"
								onClick={handleBack}
								className={styles.leftButton}
							>
								Back
							</Button>
						</div>
					)}
					<Button
						color="darkGreen"
						size="medium"
						type={activeStep === 'target' ? 'submit' : 'button'}
						onClick={handleNext}
						className={styles.rightButton}
					>
						{activeStep === 'target' ? 'Save' : 'Continue'}
					</Button>
				</FlexBlock>
			</Card>
		</div>
	);
};

export default EmissionTarget;
