import FlexBlock from '@/components/ui/FlexBlock/FlexBlock';
import styles from './LearnMore.module.scss';
import React from 'react';
import Image from '@/components/ui/Image/Image';
import { externalLinks } from '@/constants/externalLinks';
import ButtonLink from '@/components/ui/ButtonLink/ButtonLink.tsx';
import manAtAirportSrc from '@/assets/Illustrations/Man-At-Airport.svg';

const LearnMore: React.FC = () => {
	return (
		<FlexBlock className={styles.learnMoreCard} flexDirection="column">
			<div className={styles.imageWrapper}>
				<Image
					src={manAtAirportSrc}
					fallbackSrc={manAtAirportSrc}
					alt="Booking rewards illustration"
					className={styles.illustration}
				/>
			</div>
			<div>
				<h1>Travel with a lighter footprint</h1>
				<ButtonLink
					href={externalLinks.learnMoreAboutSustainableTravel}
					className={styles.learnMoreBtn}
					target="_blank"
					color="grey"
				>
					Learn more
				</ButtonLink>
			</div>
		</FlexBlock>
	);
};

export default LearnMore;
