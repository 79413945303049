import React from 'react';
import MainLayout from '@/layouts/MainLayout/MainLayout.tsx';
import Container from '@/components/ui/Container/Container.tsx';
import Welcome from '@/pages/MyClimate/Welcome.tsx';
import UpcomingTrips from '@/pages/MyClimate/UpcomingTrips/UpcomingTrips.tsx';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';
import CarbonFootprint from './CarbonFootprint/CarbonFootprint';
import LearnMore from './LearnMore/LearnMore';
import Metrics from './Metrics/Metrics';
import LogTrip from './LogTrip/LogTrip';
import BookingRewards from './BookingRewards/BookingRewards';

const MyClimate: React.FC = () => {
	return (
		<MainLayout>
			<title>Goodwings - Compromise free travel</title>
			<Container>
				<Welcome />
				<FlexBlock flexDirection="column" rowGap="32px">
					<FlexBlock flexDirection="row" columnGap="32px">
						<FlexBlock flexDirection="column" rowGap="32px" flex={1}>
							<UpcomingTrips />
							<CarbonFootprint />
						</FlexBlock>

						<FlexBlock flexDirection="column" rowGap="32px">
							<BookingRewards />
							<LearnMore />
						</FlexBlock>
					</FlexBlock>
					<Metrics />
					<LogTrip />
				</FlexBlock>
			</Container>
		</MainLayout>
	);
};

export default MyClimate;
