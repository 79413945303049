import React, { useEffect, useState } from 'react';
import styles from './Header.module.scss';
import routes from '@/constants/routes.ts';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { Logo } from '@/components/Logo/Logo.tsx';
import { useWhoamiStore } from '@/store/useWhoamiStore.ts';
import { externalLinks } from '@/constants/externalLinks.ts';
import classNames from 'classnames';
import LanguageMenu from '@/components/LanguageMenu/LanguageMenu.tsx';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';
import ProfileMenu from '@/components/ProfileMenu/ProfileMenu.tsx';
import ExploreGoodwingsMenu from '@/components/ExploreGoodwingsMenu/ExploreGoodwingsMenu.tsx';
import ButtonLink from '@/components/ui/ButtonLink/ButtonLink.tsx';
import CloseIcon from '@/assets/Icons/Close.svg?react';
import { useWindowSize } from '@react-hook/window-size';
import { Popover } from 'react-tiny-popover';
import ChevronDown from '@/assets/Icons/Chevron-Down.svg?react';
import breakpoints from '@/constants/breakpoints.ts';
import headerMenuPerRole, { HeaderMenu } from '@/constants/headerLinks.tsx';
import usePersistentPreferencesStore from '@/store/usePersistentPreferencesStore.ts';
import OutsideClickHandler from 'react-outside-click-handler';

const Header: React.FC = () => {
	const user = useWhoamiStore(state => state.user);
	const [width] = useWindowSize();
	const { isLanguageNotificationSeen, setIsLanguageNotificationSeen } = usePersistentPreferencesStore();
	const [activeDropdown, setActiveDropdown] = useState<string | null>(null);
	const [showExploreMenu, setShowExploreMenu] = useState(false);
	const [showLangDropdown, setShowLangDropdown] = useState(false);
	const location = useLocation();
	const isCorporateLogin = !!matchPath('/login/:organizationSlug', location.pathname);

	const handleChangeLanguageInfo = () => {
		setIsLanguageNotificationSeen(true);
		setShowLangDropdown(true);
	};

	const handlePopoverOpen = (popoverName: string) => {
		setActiveDropdown(popoverName);
	};

	const handlePopoverClose = () => {
		setActiveDropdown(null);
	};

	useEffect(() => {
		if (showExploreMenu) {
			document.body.style.overflowY = 'hidden';
		}

		return () => {
			document.body.style.overflowY = 'auto';
		};
	}, [showExploreMenu]);

	const menus: HeaderMenu[] =
		user && user.spotnanaRole && headerMenuPerRole[user.spotnanaRole]
			? headerMenuPerRole[user.spotnanaRole]
			: headerMenuPerRole.default;

	return (
		<div className={styles.headerWrapper}>
			<OutsideClickHandler onOutsideClick={() => setShowExploreMenu(false)} display="flex">
				<div className={styles.headerContent}>
					<FlexBlock alignItems="center">
						<Link to={routes.myClimate} className={styles.logoLink}>
							<Logo className={styles.logotype} />
							<Logo showOnlyLeaves className={styles.logo} />
						</Link>
						{!user && !isCorporateLogin && (
							<Link to={externalLinks.hubspot} className={styles.exploreLink}>
								Explore
							</Link>
						)}
					</FlexBlock>

					{user ? (
						<>
							<div className={styles.menuWrapper}>
								{width > breakpoints.desktop &&
									menus &&
									menus.map((menu, idx) =>
										menu.submenu ? (
											<Popover
												key={idx}
												isOpen={activeDropdown === menu.label.toLowerCase()}
												onClickOutside={handlePopoverClose}
												positions={['bottom', 'left']}
												align="start"
												padding={20}
												transform={{ top: -22 }}
												transformMode="relative"
												reposition={false}
												containerClassName={styles.popoverWrapper}
												content={
													<div
														className={styles.popover}
														onMouseEnter={() => handlePopoverOpen(menu.label.toLowerCase())}
														onMouseLeave={handlePopoverClose}
													>
														{menu.submenu.map((section, sectionIdx) => (
															<div key={sectionIdx} className={styles.linksSection}>
																<span className={styles.subheader}>
																	{section.header}
																</span>
																{section.links.map((link, linkIdx) => (
																	<Link
																		key={linkIdx}
																		className={styles.linkWrapper}
																		to={link.route}
																	>
																		<div className={styles.iconWrapper}>
																			{link.icon}
																		</div>
																		{link.label}
																	</Link>
																))}
															</div>
														))}
													</div>
												}
											>
												<span
													className={classNames(styles.menuLink, styles.menuLinkPopover)}
													onMouseEnter={() => handlePopoverOpen(menu.label.toLowerCase())}
													onMouseLeave={handlePopoverClose}
												>
													{menu.label}
													<span className={styles.icon}>
														<ChevronDown
															className={classNames(
																activeDropdown === menu.label.toLowerCase() &&
																	styles.rotate,
															)}
														/>
													</span>
												</span>
											</Popover>
										) : (
											<Link
												key={idx}
												to={menu.route || ''}
												className={classNames(styles.menuLink, {
													[styles.activeLink]: menu.match
														? location.pathname.includes(menu.match)
														: location.pathname === menu.route,
												})}
											>
												{menu.label}
											</Link>
										),
									)}
							</div>
							<FlexBlock flexDirection="row" alignItems="center">
								<LanguageMenu />
								<ProfileMenu />
							</FlexBlock>
						</>
					) : (
						<>
							<FlexBlock
								flexDirection="row"
								columnGap="15px"
								alignItems="center"
								className={styles.notLoggedInMenuWrapper}
							>
								<div className={styles.changeLanguageWrapper}>
									<LanguageMenu showDropdown={showLangDropdown} />
									{!isLanguageNotificationSeen && (
										<button onClick={handleChangeLanguageInfo} className={styles.info}>
											<p>Change Language?</p>
											<CloseIcon />
										</button>
									)}
								</div>
								{!isCorporateLogin && (
									<>
										<Link to={routes.login} className={styles.signInLink}>
											Sign in
										</Link>
										<ButtonLink
											href={externalLinks.bookADemo}
											color="green"
											className={styles.bookADemoLink}
										>
											Book a demo
										</ButtonLink>
									</>
								)}
							</FlexBlock>
							{showExploreMenu && <ExploreGoodwingsMenu />}
						</>
					)}
				</div>
			</OutsideClickHandler>
		</div>
	);
};

export default Header;
