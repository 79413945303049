const routes = {
	myClimate: '/my-climate',
	tripEmissionLog: '/my-climate/trip-emission-log',
	climatePro: '/climate-pro',
	climateProSetting: '/climate-pro/setting',
	climateProAllocation: '/climate-pro/allocation',
	climateProExcessAlerts: '/climate-pro/excess-alerts',
	login: '/login',
	organizationLogin: '/login/:organizationSlug',
	logout: '/user/logout',
	spotnanaRedirection: '/spotnana',
	forgotPassword: '/forgot-password',
	resetPassword: '/reset-password',
	employeeOnboard: '/onboard/employee/:token',
	ssoRegister: '/auth/organizations',
	noOrganization: '/auth/no-organization',
	authError: '/auth/error',
	activateAccount: '/user/activate/:token',
	thankYouForFeedback: '/thank-you-for-feedback',
	securitySettings: '/user/security',
};

export default routes;
