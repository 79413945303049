import * as Yup from 'yup';

export const legalEntitiesValidationSchema = Yup.object().shape({
	legalEntities: Yup.array().of(
		Yup.object().shape({
			modelId: Yup.number().required('Legal entity is required'),
			percentage: Yup.number()
				.typeError('Percentage must be a number')
				.required('Percentage is required')
				.min(0, 'Percentage cannot be negative')
				.max(100, 'Percentage cannot exceed 100'),
			responsibleUserId: Yup.number().required('Responsible person is required'),
		}),
	),
});

export const officesValidationSchema = Yup.object().shape({
	offices: Yup.array().of(
		Yup.object().shape({
			modelId: Yup.number().required('Office is required'),
			percentage: Yup.number()
				.typeError('Percentage must be a number')
				.required('Percentage is required')
				.min(0, 'Percentage cannot be negative')
				.max(100, 'Percentage cannot exceed 100'),
			responsibleUserId: Yup.number().required('Responsible person is required'),
		}),
	),
});

export const departmentsValidationSchema = Yup.object().shape({
	departments: Yup.array().of(
		Yup.object().shape({
			modelId: Yup.number().required('Department is required'),
			percentage: Yup.number()
				.typeError('Percentage must be a number')
				.required('Percentage is required')
				.min(0, 'Percentage cannot be negative')
				.max(100, 'Percentage cannot exceed 100'),
			responsibleUserId: Yup.number().required('Responsible person is required'),
		}),
	),
});
