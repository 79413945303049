import { isAxiosError } from 'axios';
import { each } from 'lodash';
import { FormikHelpers } from 'formik';
import { ErrorResponse } from '@/utils/axiosUtil.ts';

const handleResponseErrors = (error: unknown, form: FormikHelpers<any>): void => {
	// Check if the error is an AxiosError
	if (isAxiosError(error)) {
		const responseData = error.response?.data as ErrorResponse;

		// Check if the error is a validation exception with field errors
		if (responseData?.type === 'ValidationException' && responseData.data?.fields) {
			// Set all fields with errors as touched, and set their error message
			each(responseData.data.fields, (errorMessages, fieldName) => {
				form.setFieldTouched(fieldName, true, true).then(() => form.setFieldError(fieldName, errorMessages[0]));
			});
		} else {
			// Use the error message from the response, or a default message
			const errorMessage =
				responseData?.message ||
				'Something went wrong. Please try again or contact support if the issue persists.';

			// Set a generic error message on the form
			form.setErrors({
				_error: errorMessage,
			});
		}
	} else {
		// If the error is not an AxiosError, set a generic error message
		form.setErrors({
			_error: 'Something went wrong. Please try again or contact support if the issue persists.',
		});
	}
};

export default handleResponseErrors;
