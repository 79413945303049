import React, { useCallback, useEffect, useState } from 'react';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';
import Flyout, { FlyoutCloseButton } from '@/components/ui/Flyout/Flyout.tsx';
import { airTravelLogHistoryDemoColumns } from '@/constants/myClimate.ts';
import moment from 'moment/moment';
import Tooltip from '@/components/ui/Tooltip/Tooltip.tsx';
import LogForm from '@/pages/MyClimate/TripEmissionLog/LogForm.tsx';
import Spinner from '@/components/ui/Spinner/Spinner.tsx';
import { isEmpty, uniq, upperFirst } from 'lodash';
import Pagination from '@/components/ui/Pagination/Pagination.tsx';
import Button from '@/components/ui/Button/Button.tsx';
import HistoryIcon from '@/assets/Icons/History-White.svg?react';
import ArrowRightIcon from '@/assets/Icons/Arrow-Right.svg?react';
import EditIcon from '@/assets/Icons/Pen.svg?react';
import EyeIcon from '@/assets/Icons/Eye-Stroke.svg?react';
import { getTravelLogHistory } from '@/api/myClimate.ts';
import styles from './TripEmissionLogFlyout.module.scss';
import { useQuery } from '@tanstack/react-query';
import queryKeys from '@/constants/queryKeys.ts';
import { formatDistance, formatWeight } from '@/utils/stringUtils.ts';
import TravelLogType = App.Enums.TravelLogType;
import TravelLogData = App.Data.TravelLogData;

const TripEmissionLogFlyout: React.FC = () => {
	const [travelLogHistoryPagination, setTravelLogHistoryPagination] = useState({ page: 1, perPage: 8 });
	const [selectedRowData, setSelectedRowData] = useState<TravelLogData | undefined>(undefined);
	const [selectedTripMode, setSelectedTripMode] = useState<TravelLogType | undefined>(undefined);
	const [editingMode, setEditingMode] = useState(false);
	const [viewMode, setViewMode] = useState(false);

	const isSevenDaysAgo = useCallback((date: string) => {
		const givenTime = moment(date);
		const sevenDaysAgo = moment().subtract(7, 'days');
		return givenTime.isBefore(sevenDaysAgo);
	}, []);

	const handlePagination = (num: number) => {
		setTravelLogHistoryPagination({ page: num, perPage: 8 });
	};

	const handleActionClick = (action: 'edit' | 'view', rowId: number) => {
		const selectedRow = travelLogHistoryData?.data.find(item => item.id === rowId);
		setSelectedRowData(selectedRow);
		setSelectedTripMode(selectedRow?.type.toLowerCase() as TravelLogType);
		action === 'edit' ? setEditingMode(true) : setViewMode(true);
	};

	const {
		data: travelLogHistoryData,
		isLoading: isTravelLogHistoryDataLoading,
		refetch: refetchTravelLogHistoryData,
	} = useQuery({
		queryKey: [queryKeys.travelLogHistory],
		queryFn: () => getTravelLogHistory(travelLogHistoryPagination),
		enabled: false,
	});

	const exitEditingMode = () => {
		setEditingMode(false);
		setViewMode(false);
	};

	useEffect(() => {
		refetchTravelLogHistoryData();
	}, [travelLogHistoryPagination.page]);

	return (
		<Flyout
			id="emissionTargetHistoryFlyout"
			button={
				<span className={styles.tripLogHistoryBttn} onClick={() => refetchTravelLogHistoryData()}>
					<HistoryIcon /> Trip log history
				</span>
			}
			onClose={exitEditingMode}
			className={styles.tripEmissionLogFlyout}
		>
			<FlexBlock justifyContent="space-between" alignItems="center" className={styles.flyoutHeader}>
				{editingMode || viewMode ? (
					<FlexBlock alignItems="center" columnGap="6px" className={styles.breadcrumbHistory}>
						<p onClick={exitEditingMode}>History of trips logs</p>
						<ArrowRightIcon />
						<p>View trip details</p>
					</FlexBlock>
				) : (
					<p className={styles.flyoutTitle}>History of trips logs</p>
				)}
				<FlyoutCloseButton />
			</FlexBlock>
			{!editingMode && !viewMode ? (
				<>
					<div className={styles.tableWrapper}>
						<table>
							<thead>
								<tr>
									{airTravelLogHistoryDemoColumns.map((column, index) => (
										<th key={index}>{column.header}</th>
									))}
								</tr>
							</thead>
							<tbody>
								{travelLogHistoryData &&
									travelLogHistoryData.data &&
									travelLogHistoryData.data.length > 0 &&
									travelLogHistoryData?.data?.map((travelLog, index) => {
										const flightClasses = uniq(
											travelLog?.flightLegs?.map(flightLeg =>
												upperFirst(flightLeg.flightCabinClass),
											),
										);

										const typeClass = {
											flight: flightClasses.join(', '),
											accommodation: upperFirst(travelLog.accommodationType),
											mileage: upperFirst(travelLog.groundTransportationType),
										}[travelLog.type];

										const routeInfo = {
											flight: travelLog.flightLegs
												?.map(({ arrivalAirport, departureAirport }) =>
													[departureAirport.iataCode, arrivalAirport.iataCode].join('->'),
												)
												.join(', '),
											accommodation: travelLog.accommodationPlace?.name,
											mileage:
												travelLog.departurePlace && travelLog.arrivalPlace
													? `${travelLog.departurePlace?.addressLong} - ${travelLog.arrivalPlace?.addressLong}`
													: `${formatDistance(Number(travelLog.distance))}`,
										}[travelLog.type];

										return (
											<tr key={index}>
												<td>{upperFirst(travelLog.type)}</td>
												<td>{routeInfo}</td>
												<td
													style={{ textWrap: 'nowrap' }}
												>{`${moment(travelLog.departureDate).format('DD.MM.YY')} - ${moment(travelLog.arrivalDate).format('DD.MM.YY.')}`}</td>
												<td>{typeClass}</td>
												<td>
													{travelLog.type === 'accommodation'
														? '-'
														: travelLog.isRoundTrip
															? 'Yes'
															: 'No'}
												</td>
												<td style={{ textWrap: 'nowrap' }}>
													{travelLog.co2e ? formatWeight(travelLog.co2e) : '-'}
												</td>
												<td>{moment(travelLog.createdAt).format('DD.MM.YY.')}</td>
												<td>
													<FlexBlock alignItems="center" columnGap="10px">
														<Tooltip
															icon={
																<button
																	onClick={() =>
																		handleActionClick('edit', travelLog.id!)
																	}
																	disabled={isSevenDaysAgo(travelLog.createdAt!)}
																	className={styles.actionBttn}
																>
																	<EditIcon />
																</button>
															}
															text={
																!isSevenDaysAgo(travelLog.createdAt!)
																	? 'Edit this travel log'
																	: 'Emission log entries can be edited or deleted within a week of creation.'
															}
														/>
														<Tooltip
															icon={
																<button
																	onClick={() =>
																		handleActionClick('view', travelLog.id!)
																	}
																	className={styles.actionBttn}
																>
																	<EyeIcon />
																</button>
															}
															text="See full data"
														/>
													</FlexBlock>
												</td>
											</tr>
										);
									})}
							</tbody>
						</table>
						{isTravelLogHistoryDataLoading && (
							<div className={styles.spinnerWrapper}>
								<Spinner absolute />
							</div>
						)}
						{travelLogHistoryData && isEmpty(travelLogHistoryData.data) && (
							<p className={styles.emptyTable}>No data available</p>
						)}
					</div>
					{travelLogHistoryData && travelLogHistoryData?.pagination.totalPages > 1 && (
						<Pagination
							records={travelLogHistoryData.pagination.total}
							current={travelLogHistoryData.pagination.currentPage}
							onChange={handlePagination}
							delta={2}
							fixed={1}
							limit={8}
						/>
					)}
				</>
			) : (
				<>
					{selectedTripMode && (
						<LogForm
							tripMode={selectedTripMode}
							editingMode={editingMode}
							exitEditingMode={exitEditingMode}
							viewMode={viewMode}
							selectedRowData={selectedRowData}
							refetchTravelLogHistoryData={refetchTravelLogHistoryData}
						/>
					)}
					<Button align="right" hover={false} onClick={exitEditingMode} className={styles.backBttn}>
						Back
					</Button>
				</>
			)}
		</Flyout>
	);
};

export default TripEmissionLogFlyout;
