import React, { useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import FormikInput from '@/components/Formik/FormikInput.tsx';
import MainLayout from '@/layouts/MainLayout/MainLayout.tsx';
import { forgotPassword } from '@/api/auth.ts';
import styles from './ForgotPassword.module.scss';
import Button from '@/components/ui/Button/Button.tsx';
import routes from '@/constants/routes.ts';
import { useNavigate } from 'react-router';
import { forgotPasswordSchema } from '@/validations/user.ts';
import handleResponseErrors from '@/utils/handleResponseErrors.ts';
import Card from '@/components/ui/Card/Card.tsx';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';
import { Logo } from '@/components/Logo/Logo.tsx';
import InfoStatus from '@/components/ui/InfoStatus/InfoStatus.tsx';
import LetterSent from '@/assets/Illustrations/Letter-Sent.svg?react';

interface ForgotPasswordValues {
	email: string;
}

const ForgotPassword: React.FC = () => {
	const navigate = useNavigate();
	const [isLinkSent, setIsLinkSent] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>('');

	const handleSubmit = async (
		values: ForgotPasswordValues,
		formikHelpers: FormikHelpers<ForgotPasswordValues>,
	): Promise<void> => {
		try {
			await forgotPassword(values.email);
			setIsLinkSent(true);
		} catch (error) {
			//@ts-ignore
			setErrorMessage(error?.response?.data.message);
			handleResponseErrors(error, formikHelpers);
		}
	};

	return (
		<MainLayout>
			<FlexBlock
				flexDirection="column"
				justifyContent="center"
				alignItems="center"
				rowGap="32px"
				className={styles.forgotPasswordWrapper}
			>
				<div className={styles.forgotContainer}>
					<div className={styles.logoWrapper}>
						<Logo showOnlyLeaves />
					</div>
					{isLinkSent ? (
						<Card>
							<FlexBlock flexDirection="column" alignItems="center">
								<h1>The link is on the way</h1>
								<p>We’ve sent you an email — follow the link inside to reset your password.</p>
								<LetterSent />
							</FlexBlock>
						</Card>
					) : (
						<Card>
							<h1>Forgot your password?</h1>
							<Formik<ForgotPasswordValues>
								initialValues={{ email: '' }}
								validationSchema={forgotPasswordSchema}
								onSubmit={handleSubmit}
							>
								{({ isSubmitting }) => (
									<Form>
										<p>Enter your email to receive a password reset link.</p>
										<div>
											<FormikInput
												name="email"
												placeholder="Email*"
												className={styles.formikInput}
											/>
											{errorMessage && (
												<InfoStatus
													status="warning"
													text={errorMessage}
													className={styles.warning}
												/>
											)}
											<Button
												fullWidth
												type="submit"
												color="darkGreen"
												size="large"
												loading={isSubmitting}
												disabled={isSubmitting}
											>
												Send reset link
											</Button>
										</div>
									</Form>
								)}
							</Formik>
							<div className={styles.backButtonWrapper}>
								<button onClick={() => navigate(routes.login)} className={styles.backButton}>
									Back to sign in
								</button>
							</div>
						</Card>
					)}
				</div>
			</FlexBlock>
		</MainLayout>
	);
};

export default ForgotPassword;
