import * as yup from 'yup';
import EmissionTargetType = App.Enums.EmissionTargetType;

const requiredMessage = 'This field is required';
const minValueMessage = 'Value must be at least 0.0001';

const validationSchemaEmissionTarget = {
	type: yup.object().shape({
		type: yup.string().required(requiredMessage),
	}),
	period: yup.object().shape({
		yearType: yup.string().required(requiredMessage),
	}),
	baseline: yup.object().shape({
		lastPeriodCo2: yup.number().min(0.0001, minValueMessage),
		lastPeriodTravelSpend: yup.number().min(0.0001, minValueMessage),
		// Validation for lastPeriodRevenue field only if selected type is 'intensity', otherwise there is no field
		lastPeriodRevenue: yup.number().when('type', (type: EmissionTargetType[], schema: yup.NumberSchema) => {
			return type && type[0] === 'intensity'
				? schema.required(requiredMessage).min(0.0001, minValueMessage)
				: schema.nullable();
		}),
	}),
	target: yup.object().shape({
		expectedRevenue: yup.number().when('type', (type: EmissionTargetType[], schema) => {
			return type[0] === 'intensity'
				? schema.required(requiredMessage).min(0.0001, minValueMessage)
				: schema.nullable();
		}),
		reductionPercentage: yup.number().required(requiredMessage),
	}),
	finish: {},
};

export default validationSchemaEmissionTarget;
