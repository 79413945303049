import React, { useState } from 'react';
import styles from './ButtonDropdown.module.scss';
import ArrowDown from '@/assets/Icons/Arrow-Down.svg?react';
import classNames from 'classnames';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';

interface ButtonDropdownProps {
	buttonText: string;
	options: { value: string | number; label: string }[];
	className?: string;
	disabled?: boolean;
	onClick: (value: string | number) => void;
}

const ButtonDropdown: React.FC<ButtonDropdownProps> = ({
	buttonText,
	options,
	className,
	disabled = false,
	onClick,
}) => {
	const [isDropdownOpened, setIsDropdownOpened] = useState(false);
	return (
		<div
			className={classNames(
				styles.buttonDropdownWrapper,
				isDropdownOpened && styles.opened,
				disabled && styles.disabled,
				className,
			)}
		>
			<FlexBlock
				alignItems="center"
				columnGap="12px"
				onClick={() => !disabled && setIsDropdownOpened(!isDropdownOpened)}
				className={styles.bttn}
			>
				{buttonText} <ArrowDown />
			</FlexBlock>
			<div className={classNames(styles.dropdown, isDropdownOpened && styles.opened)}>
				{options.map((option, index) => (
					<div
						className={styles.option}
						key={index}
						onClick={() => {
							onClick(option.value);
							setIsDropdownOpened(!isDropdownOpened);
						}}
					>
						{option.label}
					</div>
				))}
			</div>
		</div>
	);
};

export default ButtonDropdown;
