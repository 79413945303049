import React, { useEffect, useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import FormikInput from '@/components/Formik/FormikInput.tsx';
import Button from '@/components/ui/Button/Button.tsx';
import MainLayout from '@/layouts/MainLayout/MainLayout.tsx';
import { resetPassword } from '@/api/auth.ts';
import styles from './ResetPassword.module.scss';
import routes from '@/constants/routes.ts';
import { useLocation, useNavigate } from 'react-router';
import { resetPasswordSchema } from '@/validations/user.ts';
import handleResponseErrors from '@/utils/handleResponseErrors.ts';
import GlobalError from '@/components/FormikErrors/GlobalError.tsx';
import FormikPasswordInput from '@/components/Formik/FormikPasswordInput.tsx';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';
import { Logo } from '@/components/Logo/Logo.tsx';
import Card from '@/components/ui/Card/Card.tsx';
import CheckCircle from '@/assets/Icons/Check-Circle.svg?react';

interface LoginFormValues {
	email: string;
	password: string;
	confirmPassword: string;
	token: string;
}

const useQuery = () => {
	return new URLSearchParams(useLocation().search);
};

const ResetPassword: React.FC = () => {
	const navigate = useNavigate();
	const query = useQuery();
	const token = query.get('token');
	const email = query.get('email');
	const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);

	useEffect(() => {
		if (!token) {
			navigate(routes.myClimate);
		}
	}, [token, email, navigate]);

	const handleSubmit = async (values: LoginFormValues, formikHelpers: FormikHelpers<LoginFormValues>) => {
		try {
			await resetPassword(values.email, values.password, values.token);
			setShowSuccessMessage(true);
			setTimeout(() => navigate(routes.login), 4000);
		} catch (error) {
			handleResponseErrors(error, formikHelpers);
		}
	};

	return (
		<MainLayout>
			<FlexBlock
				flexDirection="column"
				justifyContent="center"
				alignItems="center"
				rowGap="32px"
				className={styles.resetPasswordWrapper}
			>
				<div className={styles.resetContainer}>
					<div className={styles.logoWrapper}>
						<Logo showOnlyLeaves />
					</div>
					{showSuccessMessage ? (
						<Card>
							<FlexBlock flexDirection="column" alignItems="center" className={styles.successChanged}>
								<CheckCircle />
								<h1>Password reset success</h1>
								<p>You’ll be redirected shortly.</p>
								<Button
									fullWidth
									type="submit"
									color="darkGreen"
									size="large"
									onClick={() => navigate(routes.login)}
									className={styles.submitButton}
								>
									Continue
								</Button>
							</FlexBlock>
						</Card>
					) : (
						<Card>
							<h1>Reset your password</h1>
							<p>Make sure your new password is strong and memorable.</p>
							<Formik
								initialValues={{
									email: email || '',
									password: '',
									confirmPassword: '',
									token: token || '',
								}}
								validationSchema={resetPasswordSchema}
								onSubmit={handleSubmit}
							>
								{({ isSubmitting }) => (
									<Form>
										<GlobalError />
										<div>
											<FormikInput
												name="email"
												placeholder="Email*"
												type="email"
												className={styles.formikInput}
											/>
											<FormikPasswordInput
												name="password"
												placeholder="Password*"
												type="password"
												className={styles.formikInput}
											/>
											<FormikPasswordInput
												name="confirmPassword"
												placeholder="Confirm Password*"
												type="password"
												className={styles.formikInput}
											/>
											<Button
												fullWidth
												type="submit"
												color="darkGreen"
												size="large"
												loading={isSubmitting}
												disabled={isSubmitting}
												className={styles.submitButton}
											>
												Set new password
											</Button>
										</div>
									</Form>
								)}
							</Formik>
						</Card>
					)}
				</div>
			</FlexBlock>
		</MainLayout>
	);
};

export default ResetPassword;
