const queryKeys = {
	getEmissionTarget: 'getEmissionTarget',
	getEmployeeOnboardOrganizationsEntities: 'employeeOnboardOrganizationEntities',
	usersOrganizations: 'usersOrganizations',
	upcomingTrips: 'upcomingTrips',
	suggestedRestaurants: 'suggestedRestaurants',
	suggestedPlacesToRelax: 'suggestedPlacesToRelax',
	transportationToHotel: 'transportationToHotel',
	showRatingModal: 'showRatingModal',
	deleteEmissionTargetData: 'deleteEmissionTargetData',
	searchTerm: 'searchTerm',
	climateProNotificationSettings: 'climateProNotificationSettings',
	certificates: 'certificates',
	factorFramework: 'factorFramework',
	factorFrameworkOptions: 'factorFrameworkOptions',
	organizationEntities: 'organizationEntities',
	responsibleUsers: 'responsibleUsers',
	allocationTables: 'allocationTables',
	metrics: 'metrics',
	entitiesFilter: 'entitiesFilter',
	co2Segments: 'co2Segments',
	co2Analytics: 'co2Analytics',
	travelLogHistory: 'travelLogHistory',
	allItineraries: 'allItineraries',
	getMyClimateMetrics: 'getMyClimateMetrics',
	getMyClimateCarbonFootprint: 'getMyClimateCarbonFootprint',
	getOrganizationCarbonRewards: 'getOrganizationCarbonRewards',
	getOrganizationLogin: 'getOrganizationLogin',
};

export default queryKeys;
