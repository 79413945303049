import { create } from 'zustand';
import { STEPS, StepType } from '@/constants/emissionTarget.ts';

interface EmissionTargetTypes {
	activeStep: StepType;
	changeStep: (step: StepType) => void;
	clickNext: () => void;
	clickBack: () => void;
}

const initialState = {
	activeStep: STEPS[0],
};

export const useEmissionTargetStore = create<EmissionTargetTypes>(set => ({
	...initialState,
	changeStep: step =>
		set({
			activeStep: step,
		}),
	clickNext: () =>
		set(state => {
			if (state.activeStep !== 'target') {
				return { activeStep: STEPS[STEPS.indexOf(state.activeStep) + 1] };
			}
			return state;
		}),
	clickBack: () =>
		set(state => {
			const newIndex = STEPS.indexOf(state.activeStep) - 1;
			if (newIndex >= 0) {
				return { activeStep: STEPS[newIndex] };
			}
			return state;
		}),
}));
