import React from 'react';

interface LogoProps {
	className?: string;
	style?: React.CSSProperties | undefined;
	showOnlyLeaves?: boolean;
	showOnlyName?: boolean;
}

export const Logo: React.FC<LogoProps> = ({ className, style, showOnlyLeaves = false, showOnlyName = false }) => {
	return (
		<>
			<svg
				className={className}
				style={style}
				viewBox={`0 0 ${!showOnlyLeaves ? '525' : '74'} ${!showOnlyLeaves ? '91' : '72'}`}
			>
				<title>Goodwings Logo</title>
				<g id="gw-logo_svg" data-name="Goodwings Logo">
					<g id="Design">
						{!showOnlyLeaves && (
							<path d="M153.114 25.4031C149.837 21.3935 145.205 18.8304 139.928 18.8304C130.373 18.8304 123.008 26.9511 123.008 36.9877C123.008 47.0244 130.373 55.3227 139.928 55.3227C147.293 55.3227 152.937 52.7723 154.304 46.9229H136.739V34.7038H170.857C170.857 58.7867 157.936 69.1913 139.915 69.1913C121.894 69.1913 107.619 54.6883 107.619 36.9877C107.619 19.2872 121.818 4.69531 139.928 4.69531C150.115 4.69531 158.949 9.07287 164.403 15.8232L153.114 25.4031Z" />
						)}
						{!showOnlyLeaves && (
							<path d="M169.592 47.0253C169.592 33.5246 180.146 24.7695 192.067 24.7695C203.989 24.7695 214.809 33.5246 214.809 47.0253C214.809 60.5259 204.166 69.1922 192.067 69.1922C179.969 69.1922 169.592 60.4371 169.592 47.0253ZM192.067 55.8692C196.889 55.8692 200.888 51.8596 200.888 47.0253C200.888 42.1909 196.889 38.1813 192.067 38.1813C187.246 38.1813 183.247 42.1909 183.247 47.0253C183.247 51.8596 187.246 55.8692 192.067 55.8692Z" />
						)}
						{!showOnlyLeaves && (
							<path d="M214.453 47.0253C214.453 33.5246 225.008 24.7695 236.929 24.7695C248.85 24.7695 259.67 33.5246 259.67 47.0253C259.67 60.5259 249.027 69.1922 236.929 69.1922C224.83 69.1922 214.453 60.4371 214.453 47.0253ZM236.929 55.8692C241.75 55.8692 245.749 51.8596 245.749 47.0253C245.749 42.1909 241.75 38.1813 236.929 38.1813C232.107 38.1813 228.108 42.1909 228.108 47.0253C228.108 51.8596 232.107 55.8692 236.929 55.8692Z" />
						)}
						{!showOnlyLeaves && (
							<path d="M291.701 3.51562H306.166V68.3796H292.524V62.2637C290.068 67.2757 284.601 69.1916 279.97 69.1916C269.504 69.1916 259.316 60.7918 259.316 47.0247C259.316 33.2576 269.504 24.769 279.97 24.769C285.07 24.769 288.98 26.7738 291.714 29.7936V3.51562H291.701ZM282.88 55.9702C287.702 55.9702 291.701 51.9606 291.701 47.1262C291.701 42.2919 287.702 38.2823 282.88 38.2823C278.059 38.2823 274.06 42.2919 274.06 47.1262C274.06 51.9606 278.059 55.9702 282.88 55.9702Z" />
						)}
						{!showOnlyLeaves && (
							<path d="M373.682 25.8594L359.128 68.3661H346.84L339.741 42.9128L333.008 68.3661H320.72L305.711 25.8594H321.176L327.541 49.3078L333.995 25.8594H345.372L351.826 49.3078L358.192 25.8594H373.657H373.682Z" />
						)}
						{!showOnlyLeaves && (
							<path d="M380.591 3.51562C385.413 3.51562 389.235 7.06842 389.235 11.7251C389.235 16.3818 385.413 20.0235 380.591 20.0235C375.77 20.0235 371.859 16.2803 371.859 11.7251C371.859 7.16993 375.858 3.51562 380.591 3.51562ZM373.403 68.3796H387.868V25.8602H373.403V68.3796Z" />
						)}
						{!showOnlyLeaves && (
							<path d="M406.889 68.3801H392.424V25.8607H406.889V32.5222C408.344 27.1423 415.077 24.7695 418.81 24.7695C430.187 24.7695 435.73 32.5222 435.73 45.7564V68.3801H421.265V46.2132C421.265 40.5541 417.355 38.8285 414.355 38.8285C411.078 38.8285 406.889 40.5668 406.889 46.0356V68.3928V68.3801Z" />
						)}
						{!showOnlyLeaves && (
							<path d="M450.385 71.1082C451.752 74.3945 455.295 76.7673 459.763 76.7673C466.862 76.7673 470.95 72.1106 470.95 63.0763C468.95 66.6291 464.394 69.1922 457.94 69.1922C447.474 69.1922 437.287 60.7924 437.287 47.0253C437.287 33.2582 447.474 24.7695 457.94 24.7695C462.218 24.7695 467.039 26.6855 469.672 30.4286V25.8734H484.137V63.0002C484.137 80.7007 475.316 90.0014 459.75 90.0014C449.74 90.0014 441.463 84.1647 438.186 76.044L450.373 71.1209L450.385 71.1082ZM460.851 55.958C465.673 55.958 469.672 51.9484 469.672 47.1141C469.672 42.2798 465.673 38.2702 460.851 38.2702C456.029 38.2702 452.03 42.2798 452.03 47.1141C452.03 51.9484 456.029 55.958 460.851 55.958Z" />
						)}
						{!showOnlyLeaves && (
							<path d="M500.068 51.7708C486.78 48.1165 486.97 40.5541 486.97 37.4454C486.97 31.0631 494.791 24.7695 504.257 24.7695C513.179 24.7695 522.91 28.4238 522.822 39.4629H509.268C509.268 36.8237 507.724 35.6309 504.991 35.6309C503.168 35.6309 501.447 36.3669 501.447 38.0037C501.447 39.0949 501.991 40.0973 504.636 40.7444L512.191 42.838C523.113 45.7564 524.024 52.9635 524.024 55.7043C524.024 65.1065 513.837 69.2049 504.826 69.2049C495.816 69.2049 486.35 63.9138 486.35 54.1562H499.903C499.903 55.9834 501.27 58.5338 504.813 58.5338C507.18 58.5338 509.268 57.4426 509.268 55.7043C509.268 54.4227 508.534 54.0674 505.446 53.2427L500.08 51.7835L500.068 51.7708Z" />
						)}
						{!showOnlyName && (
							<>
								<path d="M4.5179 4.52982C-1.50597 10.5696 -1.50597 20.3651 4.5179 26.4049C13.9713 35.8833 35.4345 35.528 35.4345 35.528C35.4345 35.528 35.7888 13.9955 26.3354 4.52982C20.3115 -1.50994 10.5418 -1.50994 4.5179 4.52982Z" />
								<path d="M4.5179 65.6386C10.5418 71.6783 20.3115 71.6783 26.3354 65.6386C35.7888 56.1602 35.4345 34.6404 35.4345 34.6404C35.4345 34.6404 13.9587 34.2851 4.5179 43.7635C-1.50597 49.8032 -1.50597 59.5988 4.5179 65.6386Z" />
								<path d="M69.6917 65.6386C75.7156 59.5988 75.7156 49.8032 69.6917 43.7635C60.2383 34.2851 38.7752 34.6404 38.7752 34.6404C38.7752 34.6404 38.4208 56.1729 47.8742 65.6386C53.8981 71.6783 63.6679 71.6783 69.6917 65.6386Z" />
								<path d="M69.6917 4.52982C63.6679 -1.50994 53.8981 -1.50994 47.8742 4.52982C38.4208 14.0082 38.7752 35.528 38.7752 35.528C38.7752 35.528 60.251 35.8833 69.6917 26.4049C75.7156 20.3651 75.7156 10.5696 69.6917 4.52982Z" />
							</>
						)}
					</g>
				</g>
			</svg>
		</>
	);
};
