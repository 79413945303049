import React, { FC } from 'react';
import Header from '@/components/Header/Header.tsx';
import styles from './MainLayout.module.scss';

interface MainLayoutProps {
	children: React.ReactNode;
	ref?: React.Ref<HTMLDivElement>;
}

const MainLayout: FC<MainLayoutProps> = ({ children, ref }) => {
	return (
		<div className={styles.mainWrapper} ref={ref}>
			<Header />
			<div className={styles.layoutBackground}>{children}</div>
		</div>
	);
};

export default MainLayout;
