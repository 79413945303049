import React from 'react';
import styles from './CheckboxToggle.module.scss';
import classNames from 'classnames';

export interface CheckboxToggleProps
	extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
	id?: string;
	name?: string;
	label?: string;
	checked: boolean;
	uncheckedColor?: string;
	checkedColor?: string;
	disabled?: boolean;
	onChange: () => void;
	className?: string;
	style?: React.CSSProperties;
}

const CheckboxToggle: React.FC<CheckboxToggleProps> = ({
	name,
	label,
	checked,
	uncheckedColor = '#F0F0F0',
	checkedColor = '#07624A',
	disabled = false,
	onChange,
	className,
	style,
}) => {
	return (
		<>
			<div
				className={classNames(styles.checkboxToggleLabel, disabled && styles.disabled, className)}
				style={{ backgroundColor: checked ? checkedColor : uncheckedColor, ...style }}
				onClick={!disabled ? onChange : undefined}
				data-name={name}
			>
				{label}
				<div
					className={styles.checkboxToggleIndicator}
					style={{ left: checked ? 'calc(90%)' : '5%', transform: checked ? 'translateX(-90%)' : 'none' }}
				/>
			</div>
		</>
	);
};

export default CheckboxToggle;
