import React from 'react';
import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import queryKeys from '@/constants/queryKeys.ts';
import { showRatingModal } from '@/api/rating.ts';
import { useModalStore } from '@/store/useModalStore.ts';
import RatingModal from '@/components/RatingModal/RatingModal.tsx';
import { useWhoamiStore } from '@/store/useWhoamiStore.ts';

const RatingProvider: React.FC = () => {
	const openModal = useModalStore(state => state.openModal);
	const loggedIn = useWhoamiStore(state => state.loggedIn);

	const { data } = useQuery({
		queryKey: [queryKeys.showRatingModal],
		queryFn: () => showRatingModal(),
		enabled: !!loggedIn,
	});
	useEffect(() => {
		data?.showReviewPopup && setTimeout(() => openModal('ratingModal'), 3000);
	}, [data?.showReviewPopup]);

	return <RatingModal />;
};

export default RatingProvider;
