import EmissionTargetType = App.Enums.EmissionTargetType;
import EmissionTargetYearType = App.Enums.EmissionTargetYearType;
import moment from 'moment/moment';
import routes from '@/constants/routes.ts';

export const STEPS = ['type', 'period', 'baseline', 'target'] as const;

export type StepType = (typeof STEPS)[number];
type StepsProps = { text: string; steps: StepType[] }[];
export const ShownHeaderSteps: StepsProps = [
	{
		text: 'Target type',
		steps: ['type'],
	},
	{
		text: 'Time Period',
		steps: ['period'],
	},
	{
		text: 'Decide a baseline',
		steps: ['baseline'],
	},
	{
		text: 'Your emissions reduction target',
		steps: ['target'],
	},
];

// Target type options
export const targetTypeOptions = [
	{
		label: 'Absolute',
		value: 'absolute',
	},
	{
		label: 'Intensity',
		value: 'intensity',
	},
];

// Time period options
export const timeTypeOptions = [
	{
		label: 'Fiscal',
		value: 'fiscal',
	},
	{
		label: 'Calendar',
		value: 'calendar',
	},
];

// Initial values for formik
export const defaultInitialValues: {
	type: EmissionTargetType;
	yearType: EmissionTargetYearType;
	reductionPercentage: number;
	periodStartDay: number;
	periodStartMonth: number;
} = {
	type: 'intensity',
	yearType: 'fiscal',
	reductionPercentage: 5,
	periodStartDay: 1,
	periodStartMonth: 1,
};

export const getPeriodDates = (periodStartDay: number, periodStartMonth: number) => {
	const today = moment();
	const thisYear = today.year();

	// Construct this year's period start date
	const periodStartThisYear = moment({ year: thisYear, month: periodStartMonth - 1, day: periodStartDay });

	const { start, end } = today.isBefore(periodStartThisYear)
		? {
				start: moment({ year: thisYear - 1, month: periodStartMonth - 1, day: periodStartDay }),
				end: moment(periodStartThisYear).subtract(1, 'day'),
			}
		: {
				start: periodStartThisYear,
				end: moment({ year: thisYear + 1, month: periodStartMonth - 1, day: periodStartDay }).subtract(
					1,
					'day',
				),
			};

	return [start.format('DD.MM.YYYY'), end.format('DD.MM.YYYY')].join(' - ');
};

export const submenuLinks = [
	{ path: routes.climateProSetting, label: 'Setting' },
	{
		path: routes.climateProAllocation,
		label: 'Allocation',
	},
	{ path: routes.climateProExcessAlerts, label: 'Excess Alerts' },
];
