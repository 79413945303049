import React from 'react';
import styles from './OrganizationLogin.module.scss';
import MainLayout from '@/layouts/MainLayout/MainLayout.tsx';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';
import CloseIcon from '@/assets/Icons/Close.svg?react';
import { Logo } from '@/components/Logo/Logo.tsx';
import Card from '@/components/ui/Card/Card.tsx';
import ButtonLink from '@/components/ui/ButtonLink/ButtonLink.tsx';
import MicrosoftLogo from '@/assets/Icons/Microsoft-Logo-Color.svg?react';
import { useWhoamiStore } from '@/store/useWhoamiStore.ts';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import queryKeys from '@/constants/queryKeys.ts';
import { getOrganizationLogin } from '@/api/auth.ts';
import { useNavigate } from 'react-router';
import routes from '@/constants/routes.ts';
import Spinner from '@/components/ui/Spinner/Spinner.tsx';
import Image from '@/components/ui/Image/Image.tsx';

const OrganizationLogin: React.FC = () => {
	const { ssoUrls } = useWhoamiStore(state => state);
	const slug = location.pathname.split('/')[2];
	const navigate = useNavigate();

	const { data, isError } = useQuery({
		queryKey: [queryKeys.getOrganizationLogin],
		queryFn: () => getOrganizationLogin(slug),
		enabled: !!slug,
	});

	if (!slug || isError) {
		navigate(routes.myClimate);

		return (
			<MainLayout>
				<Spinner />
			</MainLayout>
		);
	}

	return (
		<MainLayout>
			<title>Login | Goodwings - Compromise free travel</title>

			<FlexBlock
				flexDirection="column"
				justifyContent="center"
				alignItems="center"
				rowGap="32px"
				className={styles.alkLoginPageWrapper}
			>
				{data && (
					<>
						<div className={styles.loginCardWrapper}>
							<FlexBlock justifyContent="center" alignItems="center" className={styles.logos}>
								<Logo showOnlyName />
								<CloseIcon />
								{data.logoUrl && (
									<div className={styles.organizationLogo}>
										<Image src={data.logoUrl} fallbackSrc={''} />
									</div>
								)}
							</FlexBlock>
							<Card className={styles.card}>
								<h2>Log into Goodwings</h2>
								{/* TODO - add login with email and google account*/}
								{data.ssoMicrosoftEnabled && (
									<ButtonLink
										fullWidth
										href={ssoUrls?.microsoft || ''}
										color="default"
										hover={false}
										size="large"
										className={styles.ssoButton}
									>
										<MicrosoftLogo /> Continue with Microsoft / Azure
									</ButtonLink>
								)}
							</Card>
						</div>
						<FlexBlock justifyContent="space-between" columnGap="6px" className={styles.moreInfo}>
							{data.travelPolicyUrl && (
								<Link to={data.travelPolicyUrl} target="_blank">
									Travel Policy
								</Link>
							)}
							{data.videoGuidelinesUrl && (
								<Link to={data.videoGuidelinesUrl} target="_blank">
									Video Guidelines
								</Link>
							)}
							{data.faqUrl && (
								<Link to={data.faqUrl} target="_blank">
									FAQ
								</Link>
							)}
						</FlexBlock>
					</>
				)}
			</FlexBlock>
		</MainLayout>
	);
};

export default OrganizationLogin;
