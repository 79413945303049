import React, { useEffect, useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import Card from '@/components/ui/Card/Card.tsx';
import styles from './ExcessAlerts.module.scss';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';
import UnsavedChangesToast from '@/pages/ClimateProDashboard/UnsavedChangesToast/UnsavedChangesToast.tsx';
import { useQuery } from '@tanstack/react-query';
import queryKeys from '@/constants/queryKeys.ts';
import { getNotificationSettings, updateNotificationSettings } from '@/api/climatePro.ts';
import { toast } from 'react-toastify';
import { useRouteBlocker } from '@/hooks/useRouteBlocker.ts';
import FormikCheckboxToggle from '@/components/Formik/FormikCheckboxToggle.tsx';
import ClimateProNotificationSettingsData = App.Data.ClimateProNotificationSettingsData;
import { submenuLinks } from '@/constants/emissionTarget.ts';
import SubmenuItems from '@/components/SubmenuItems/SubmenuItems.tsx';
import breakpoints from '@/constants/breakpoints.ts';
import { useWindowSize } from '@react-hook/window-size';

const ExcessAlerts: React.FC = () => {
	const [width] = useWindowSize();
	const { data, isLoading, isError, refetch } = useQuery({
		queryKey: [queryKeys.climateProNotificationSettings],
		queryFn: getNotificationSettings,
	});
	const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
	useRouteBlocker(hasUnsavedChanges);

	const initialValues: ClimateProNotificationSettingsData = data ?? {
		notifyCarbonTargetLevelsExceeded: false,
		notifyCarbonUsageSpike: false,
		notifyResponsibleEntityUser: false,
		notifyAdmin: false,
	};

	const formik = useFormik<ClimateProNotificationSettingsData>({
		initialValues,
		enableReinitialize: true,
		onSubmit: async values => {
			try {
				await updateNotificationSettings(values);
				toast.success('Successfully saved changes.');
			} catch (error) {
				toast.error('Something went wrong while retrieving your data. Please try again later.');
			}
			await refetch();
		},
	});

	useEffect(() => {
		setHasUnsavedChanges(formik.dirty);
	}, [formik.dirty]);

	useEffect(() => {
		if (!formik.values.notifyAdmin && !formik.values.notifyResponsibleEntityUser) {
			formik.setFieldValue('notifyAdmin', true);
		}
	}, [formik.values.notifyResponsibleEntityUser]);

	useEffect(() => {
		if (!formik.values.notifyAdmin && !formik.values.notifyResponsibleEntityUser) {
			formik.setFieldValue('notifyResponsibleEntityUser', true);
		}
	}, [formik.values.notifyAdmin]);

	return (
		<>
			{width < breakpoints.tabletWide && <SubmenuItems links={submenuLinks} />}
			<FormikProvider value={formik}>
				<Form onSubmit={formik.handleSubmit} className={styles.section}>
					<div>
						<h1>Excess alerts</h1>
						<Card>
							{isError && (
								<p className={styles.errorMessage}>
									Oops! Something went wrong while retrieving your data. Please try again later.
								</p>
							)}
							<h3>Allow email notifications</h3>
							<FlexBlock justifyContent="space-between" columnGap="20px" className={styles.toggleSection}>
								<p>When 50% and 100% of carbon budget has used</p>
								<FormikCheckboxToggle name="notifyCarbonTargetLevelsExceeded" />
							</FlexBlock>
							<div className={styles.toggleSection}>
								<FlexBlock justifyContent="space-between" columnGap="20px">
									<p>
										If “acceleration” occurs, e.g. ≥50% of carbon budget has been used ahead of time
										(*)
									</p>
									<FormikCheckboxToggle name="notifyCarbonUsageSpike" />
								</FlexBlock>
								<p className={styles.info}>
									(*) For example if 3/4 of the yearly carbon budget has been used just 6 months into
									the year
								</p>
							</div>

							<div className={styles.section}>
								<h3>Notification receivers</h3>

								<FlexBlock
									justifyContent="space-between"
									columnGap="20px"
									className={styles.toggleSection}
								>
									<p>Responsible person of legal entity/office/department</p>
									<FormikCheckboxToggle name="notifyResponsibleEntityUser" />
								</FlexBlock>
								<div>
									<FlexBlock
										justifyContent="space-between"
										columnGap="20px"
										className={styles.toggleSection}
									>
										<p>Admin(s)</p>
										<FormikCheckboxToggle name="notifyAdmin" />
									</FlexBlock>
								</div>
							</div>
						</Card>
					</div>
				</Form>
			</FormikProvider>
			{hasUnsavedChanges && !isLoading && (
				<UnsavedChangesToast onCancel={() => formik.resetForm()} onSubmit={formik.handleSubmit} />
			)}
		</>
	);
};

export default ExcessAlerts;
