import styles from './BottomMenu.module.scss';
import { MenuItem } from '@/components/SideMenu/SideMenu.tsx';
import { FC, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Overlay from '@/components/ui/Overlay/Overlay.tsx';
import classnames from 'classnames';
import { NavLink } from 'react-router-dom';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';
import ThreeDots from '@/assets/Icons/Three-Dots.svg?react';

interface BottomMenuProps {
	menuItems: Record<string, MenuItem>;
}

const BottomMenu: FC<BottomMenuProps> = ({ menuItems }) => {
	const [isExpanded, setIsExpanded] = useState(false);

	return (
		<>
			<FlexBlock
				justifyContent="space-between"
				alignItems="center"
				columnGap="4px"
				className={styles.bottomMenuWrapper}
			>
				{Object.entries(menuItems)
					.slice(0, 4)
					.map(([label, item]) => (
						<div key={label} className={styles.menuItem}>
							<NavLink
								to={item.path || Object.values(item?.submenu || {})[0]?.path || '#'}
								className={({ isActive }) =>
									classnames(styles.menuLink, {
										[styles.activeLink]: isActive,
									})
								}
							>
								<span className={styles.icon}>{item.icon}</span>
								<span>{label}</span>
							</NavLink>
						</div>
					))}
				<button className={styles.menuItem} onClick={() => setIsExpanded(true)}>
					<ThreeDots />
				</button>
			</FlexBlock>

			<AnimatePresence>
				{isExpanded && (
					<>
						<motion.div
							className={styles.bottomMenuOverlay}
							initial={{ opacity: 0 }}
							animate={{ opacity: 0.5 }}
							exit={{ opacity: 0 }}
							transition={{ duration: 0.3 }}
							onClick={() => setIsExpanded(false)}
						>
							<Overlay className={styles.overlay} onClick={() => setIsExpanded(false)} />
						</motion.div>
						<motion.div
							className={styles.expandMenuWrapper}
							initial={{ y: '100%' }}
							animate={{ y: 0 }}
							exit={{ y: '100%' }}
							transition={{ type: 'spring', stiffness: 150, damping: 20 }}
						>
							<motion.div
								drag="y"
								dragConstraints={{ top: 0, bottom: 0 }}
								onDragEnd={(_, info) => {
									if (info.point.y > 100) {
										setIsExpanded(false);
									}
								}}
							>
								<div className={styles.handleBar} />

								{Object.entries(menuItems)
									.slice(4)
									.map(([label, item]) => (
										<div key={label} className={styles.menuItem}>
											<NavLink
												to={item.path || '#'}
												className={({ isActive }) =>
													classnames(styles.menuLink, {
														[styles.activeLink]: isActive,
													})
												}
												onClick={() => setIsExpanded(false)}
											>
												<span className={styles.icon}>{item.icon}</span>
												<span>{label}</span>
											</NavLink>
										</div>
									))}
							</motion.div>
						</motion.div>
					</>
				)}
			</AnimatePresence>
		</>
	);
};

export default BottomMenu;
