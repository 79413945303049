import React, { useEffect, useMemo, useState } from 'react';
import styles from './SelectBaseline.module.scss';
import FormikInput from '@/components/Formik/FormikInput.tsx';
import { useFormikContext } from 'formik';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';
import InfoStatus from '@/components/ui/InfoStatus/InfoStatus.tsx';
import Tooltip from '@/components/ui/Tooltip/Tooltip.tsx';
import classNames from 'classnames';
import Checkbox from '@/components/ui/Checkbox/Checkbox.tsx';
import { round } from 'lodash';
import OrganizationEmissionTargetData = App.Data.OrganizationEmissionTargetData;
import breakpoints from '@/constants/breakpoints.ts';

const SelectBaseline: React.FC = () => {
	const { values, setFieldValue } = useFormikContext<OrganizationEmissionTargetData>();
	const [isLastPeriodCo2Unknown, setIsLastPeriodCo2Unknown] = useState(false);

	useEffect(() => {}, [values]);

	useEffect(() => {
		if (isLastPeriodCo2Unknown) {
			setFieldValue('lastPeriodCo2', '');
		} else {
			setFieldValue('lastPeriodTravelSpend', '');
		}
	}, [isLastPeriodCo2Unknown, setFieldValue]);

	const periodWord = values.yearType === 'calendar' ? 'year' : 'period';

	const carbonIntensity = useMemo(() => {
		if (values.lastPeriodRevenue && values.lastPeriodCo2) {
			return round(values.lastPeriodCo2 / values.lastPeriodRevenue, 2);
		} else if (values.lastPeriodRevenue && values.lastPeriodTravelSpend) {
			return round((((values.lastPeriodTravelSpend / 600) * 0.8) / values.lastPeriodRevenue) * 1000000, 2);
		} else {
			return null;
		}
	}, [values.lastPeriodRevenue, values.lastPeriodTravelSpend, values.lastPeriodCo2]);

	return (
		<div className={styles.selectBaselineWrapper}>
			<div className={styles.content}>
				<p className={styles.introText}>
					Establishing a baseline is a critical step in setting your emission target. It provides a reference
					point from which to measure your progress.
				</p>
				<FlexBlock
					flexDirection={{
						[breakpoints.zero]: 'column',
						[breakpoints.tablet]: 'row',
					}}
					rowGap="28px"
					justifyContent="space-between"
					alignItems="start"
					className={classNames(styles.baselineWrapper, isLastPeriodCo2Unknown && styles.noBorder)}
				>
					<FlexBlock columnGap="10px" alignItems="center">
						<h2>Enter your travel emissions from last {periodWord}</h2>
						<Tooltip
							placement="right"
							text={`Input the total travel emissions (in metric tons of CO₂) from your last full reporting ${periodWord}. This quantifies the environmental impact of your business travel and lays the foundation for setting reduction goals.`}
						/>
					</FlexBlock>
					<FlexBlock flexDirection="column" rowGap="5px" className={styles.lastPeriodWrapper}>
						<FormikInput
							type="number"
							name="lastPeriodCo2"
							helperText="tons of CO2"
							maxLength={8}
							disabled={isLastPeriodCo2Unknown}
							min={0.0001}
							className={styles.inputField}
						/>
						<Checkbox
							isSelected={isLastPeriodCo2Unknown}
							onChange={setIsLastPeriodCo2Unknown}
							className={styles.knowsLastYearTravelCheckbox}
						>
							Unsure of last {periodWord}’s travel emissions
						</Checkbox>
					</FlexBlock>
				</FlexBlock>
				{isLastPeriodCo2Unknown && (
					<FlexBlock
						flexDirection={{
							[breakpoints.zero]: 'column',
							[breakpoints.tablet]: 'row',
						}}
						rowGap="28px"
						justifyContent="space-between"
						alignItems="start"
						className={classNames(styles.baselineWrapper, styles.noTopPadding)}
					>
						<FlexBlock columnGap="10px" alignItems="center">
							<h2>Enter your travel spend from last {periodWord}</h2>
							<Tooltip
								placement="right"
								text={`Input the total amount your company spent on business travel during the last full reporting ${periodWord}. This helps estimate emissions if exact data is unavailable.`}
							/>
						</FlexBlock>
						<FormikInput
							type="number"
							name="lastPeriodTravelSpend"
							helperText="million EUR"
							maxLength={3}
							min={0.0001}
							className={styles.inputField}
						/>
					</FlexBlock>
				)}
				{values.type === 'intensity' && (
					<>
						<FlexBlock
							flexDirection={{
								[breakpoints.zero]: 'column',
								[breakpoints.tablet]: 'row',
							}}
							rowGap="28px"
							justifyContent="space-between"
							alignItems="start"
							className={styles.baselineWrapper}
						>
							<FlexBlock columnGap="10px" alignItems="center">
								<h2>Enter your revenue from last period</h2>
								<Tooltip
									placement="right"
									text="Provide your company's revenue from the last full year in millions of dollars. This will allow us to establish the ratio of emissions to economic output."
								/>
							</FlexBlock>
							<FormikInput
								type="number"
								name="lastPeriodRevenue"
								helperText="million EUR"
								maxLength={3}
								className={styles.inputField}
							/>
						</FlexBlock>
						{carbonIntensity !== null && (
							<InfoStatus
								text={`Your last ${periodWord}'s business travel carbon intensity is ${carbonIntensity} tons of CO₂ per million dollar in revenue`}
								className={styles.infoWrapper}
							/>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default SelectBaseline;
