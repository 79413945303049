import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FieldArray, Form, FormikProvider, useFormik } from 'formik';
import FormikAutocompletePlaceInput from '@/components/Formik/FormikAutocompletePlaceInput.tsx';
import FormikDatePicker from '@/components/Formik/FormikDatePicker.tsx';
import FormikInput from '@/components/Formik/FormikInput.tsx';
import FormikSelect from '@/components/Formik/FormikSelect.tsx';
import FormikTextArea from '@/components/Formik/FormikTextArea.tsx';
import Button from '@/components/ui/Button/Button.tsx';
import styles from './LogForm.module.scss';
import InfoStatus from '@/components/ui/InfoStatus/InfoStatus.tsx';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';
import Card from '@/components/ui/Card/Card.tsx';
import { first, last } from 'lodash';
import FormikCheckboxToggle from '@/components/Formik/FormikCheckboxToggle.tsx';
import FormikAutocompleteAirportsInput from '@/components/Formik/FormikAutocompleteAirportsInput.tsx';
import {
	accommodationTypeOptions,
	cabinClassOptions,
	engineTypeOptions,
	groundTransportationOptions,
} from '@/constants/myClimate.ts';
import { useQuery } from '@tanstack/react-query';
import queryKeys from '@/constants/queryKeys.ts';
import { createTravelLog, deleteTravelLog, getAllItineraries, updateTravelLog } from '@/api/myClimate.ts';
import moment, { Moment } from 'moment';
import { toast } from 'react-toastify';
import handleResponseErrors from '@/utils/handleResponseErrors.ts';
import Spinner from '@/components/ui/Spinner/Spinner.tsx';
import {
	accommodationValidationSchema,
	airValidationSchema,
	mileageValidationSchema,
} from '@/validations/myClimate.ts';
import CloseIcon from '@/assets/Icons/Close.svg?react';
import GlobalError from '@/components/FormikErrors/GlobalError.tsx';
import TravelLogType = App.Enums.TravelLogType;
import TravelLogData = App.Data.TravelLogData;
import CreateTravelLogRequest = App.Data.Request.CreateTravelLogRequest;

interface LogFormProps {
	tripMode: TravelLogType;
	editingMode: boolean;
	exitEditingMode?: () => void;
	viewMode: boolean;
	selectedRowData?: TravelLogData;
	refetchTravelLogHistoryData?: () => void;
	setSelectedTripMode?: (value: TravelLogType | undefined) => void;
}

const daysUntilEditModeExpire = (date: string) => {
	const givenTime = moment(date);
	const expireDate = givenTime.add(7, 'days');
	const today = moment();

	return expireDate.diff(today, 'days');
};

const LogForm: React.FC<LogFormProps> = ({
	tripMode,
	editingMode,
	exitEditingMode,
	viewMode,
	selectedRowData,
	refetchTravelLogHistoryData,
	setSelectedTripMode,
}) => {
	const isAirLog = tripMode === 'flight';
	const isAccommodationLog = tripMode === 'accommodation';
	const isMileageLog = tripMode === 'mileage';

	const [isFormLoading, setIsFormLoading] = useState(false);
	const { data: allItinerariesOptionsData } = useQuery({
		queryKey: [queryKeys.allItineraries],
		queryFn: getAllItineraries,
	});

	interface FormTypes extends Omit<CreateTravelLogRequest, 'departureDate' | 'arrivalDate'> {
		departureDate: Moment;
		arrivalDate: Moment;
	}

	const commonInitialValues = {
		departureDate: null,
		arrivalDate: null,
		itineraryId: undefined,
		reason: '',
	};

	const typeSpecificInitialValues = {
		flight: {
			isRoundTrip: false,
			flightLegs: [
				{
					departureAirport: {},
					arrivalAirport: {},
					flightCabinClass: undefined,
				},
			],
		},
		accommodation: {
			accommodationPlace: undefined,
			accommodationType: undefined,
		},
		mileage: {
			departurePlace: undefined,
			arrivalPlace: undefined,
			distance: undefined,
			groundTransportationType: undefined,
		},
	};

	const initialValues = selectedRowData
		? {
				...selectedRowData,
				distance:
					selectedRowData.distance && selectedRowData.distance > 0
						? selectedRowData.distance / 1000
						: selectedRowData.distance,
				departureDate: moment(selectedRowData.departureDate),
				arrivalDate: moment(selectedRowData.arrivalDate),
			}
		: {
				...commonInitialValues,
				...typeSpecificInitialValues[tripMode],
			};

	const validationSchema = {
		flight: airValidationSchema,
		accommodation: accommodationValidationSchema,
		mileage: mileageValidationSchema,
	}[tripMode];

	const transformFormValues = useCallback(
		(values: FormTypes, tripMode: App.Enums.TravelLogType): CreateTravelLogRequest => {
			const defaultIsRoundTrip = {
				flight: false,
				accommodation: null,
				mileage: false,
			}[tripMode];

			const formValues = {
				...values,
				type: tripMode,
				departureDate: values.departureDate.format('YYYY-MM-DD'),
				arrivalDate: values.arrivalDate.format('YYYY-MM-DD'),
				isRoundTrip: values.isRoundTrip ?? defaultIsRoundTrip ?? undefined,
			};

			// Convert distance from kilometers to meters before submitting the request
			if (tripMode === 'mileage' && values.distance) {
				formValues.distance = values.distance * 1000;
			}

			return formValues;
		},
		[],
	);

	const formik = useFormik({
		initialValues: initialValues as FormTypes,
		enableReinitialize: true,
		validationSchema,
		onSubmit: async values => {
			if (editingMode) {
				refetchTravelLogHistoryData && refetchTravelLogHistoryData();
				return;
			}

			const formValues = transformFormValues(values, tripMode);

			try {
				// Set form loading state to true
				setIsFormLoading(true);

				// Attempt to create the travel log with form values
				await createTravelLog(formValues);

				// Display a success message
				toast.success('You successfully created travel log.');

				// Refetch history travel log data if the function is available
				refetchTravelLogHistoryData && refetchTravelLogHistoryData();

				// Exit editing mode if the function is available
				exitEditingMode && exitEditingMode();

				// Unselect the trip mode
				setSelectedTripMode && setSelectedTripMode(undefined);
			} catch (error) {
				// Handle any errors that occur during the creation process
				handleResponseErrors(error, formik);
			} finally {
				// Ensure form loading state is set to false after completion
				setIsFormLoading(false);
			}
		},
	});

	const handleDeleteFormData = async (travelLogId: number) => {
		try {
			// Set form loading state to true
			setIsFormLoading(true);

			// Attempt to delete the travel log
			await deleteTravelLog(travelLogId);

			// Display a success message
			toast.success('You successfully deleted travel log.');

			// Refetch history travel log data if the function is available
			refetchTravelLogHistoryData && refetchTravelLogHistoryData();

			// Exit editing mode if the function is available
			exitEditingMode && exitEditingMode();
		} catch (error) {
			// Handle any errors that occur during the delete process
			handleResponseErrors(error, formik);
		} finally {
			// Ensure form loading state is set to false after completion
			setIsFormLoading(false);
		}
	};

	const handleEditFormData = async () => {
		const formValues = {
			...formik.values,
			departureDate: formik.values.departureDate.format('YYYY-MM-DD'),
			arrivalDate: formik.values.arrivalDate.format('YYYY-MM-DD'),
		};

		// Convert distance from kilometers to meters before submitting the request
		if (formik.values.type === 'mileage' && formValues.distance) {
			formValues.distance = formValues.distance * 1000;
		}

		try {
			// Set form loading state to true
			setIsFormLoading(true);

			// Attempt to update the travel log with form values
			await updateTravelLog(formValues);

			// Display a success message
			toast.success('You successfully updated this travel log.');

			// Refetch history travel log data if the function is available
			refetchTravelLogHistoryData && refetchTravelLogHistoryData();

			// Exit editing mode if the function is available
			exitEditingMode && exitEditingMode();
		} catch (error) {
			// Handle any errors that occur during the update process
			handleResponseErrors(error, formik);
		} finally {
			// Ensure form loading state is set to false after completion
			setIsFormLoading(false);
		}
	};

	useEffect(() => {
		if (
			tripMode === 'mileage' &&
			(formik.values.departurePlace?.googlePlaceId || formik.values.arrivalPlace?.googlePlaceId)
		) {
			formik.setFieldValue('distance', '', false);
		}
	}, [formik.values.departurePlace, formik.values.arrivalPlace]);

	useEffect(() => {
		if (tripMode === 'mileage' && formik.values.distance) {
			formik.setFieldValue('departurePlace', {}, false);
			formik.setFieldValue('arrivalPlace', {}, false);
		}
	}, [formik.values.distance]);

	const lastArrivalAirport = useMemo(
		() => last(formik.values.flightLegs)?.arrivalAirport,
		[formik.values.flightLegs],
	);

	const isSameDepartureAndArrival = useMemo(
		() =>
			first(formik.values.flightLegs)?.departureAirport?.id ===
			last(formik.values.flightLegs)?.arrivalAirport?.id,
		[formik.values.flightLegs],
	);

	return (
		<Card className={styles.logFormWrapper}>
			{(isFormLoading || formik.isSubmitting) && <Spinner absolute overlay />}
			<FormikProvider value={formik}>
				<Form className={styles.form}>
					<GlobalError />
					<FlexBlock rowGap="32px" flexDirection="column">
						<FieldArray
							name="flightLegs"
							render={arrayHelpers => (
								<>
									<FlexBlock flexDirection="column" rowGap="32px">
										{isAirLog && (
											<FlexBlock flexDirection="column" rowGap="32px">
												{formik.values?.flightLegs?.map((_, index: number) => {
													const isLast =
														formik.values.flightLegs &&
														index === formik.values.flightLegs.length - 1;

													return (
														<FlexBlock flexDirection="column" rowGap="25px" key={index}>
															<FlexBlock columnGap="32px">
																<FormikAutocompleteAirportsInput
																	wildcardVisible
																	name={`flightLegs.${index}.departureAirport`}
																	label="From"
																	placeholder="Search by city or airport"
																	disabled={viewMode}
																/>
																<FormikAutocompleteAirportsInput
																	wildcardVisible
																	name={`flightLegs.${index}.arrivalAirport`}
																	label="To"
																	placeholder="Search by city or airport"
																	disabled={viewMode}
																/>
																<FormikSelect
																	required
																	wildcardVisible
																	name={`flightLegs.${index}.flightCabinClass`}
																	options={cabinClassOptions}
																	label="Cabin class"
																	disabled={viewMode}
																	className={styles.field}
																/>
																{!viewMode &&
																	formik.values.flightLegs &&
																	formik.values.flightLegs.length > 1 && (
																		<button
																			onClick={() => arrayHelpers.remove(index)}
																			className={styles.deleteFlightRow}
																		>
																			<CloseIcon />
																		</button>
																	)}
															</FlexBlock>
															{!viewMode && isLast && (
																<button
																	onClick={() =>
																		arrayHelpers.push({
																			departureAirport: lastArrivalAirport || {},
																			arrivalAirport: {},
																		})
																	}
																	className={styles.addFlightRow}
																>
																	<span>+</span>
																	Add a destination
																</button>
															)}
														</FlexBlock>
													);
												})}
											</FlexBlock>
										)}

										{isMileageLog && (
											<FlexBlock flexDirection="column" rowGap="32px">
												<FlexBlock columnGap="32px">
													<FormikSelect
														required
														wildcardVisible
														name="groundTransportationType"
														options={groundTransportationOptions}
														placeholder="Select category"
														label="Transportation"
														disabled={viewMode}
														className={styles.field}
													/>
													<FormikSelect
														name="itineraryId"
														options={allItinerariesOptionsData?.itineraries || []}
														label="Trip"
														disabled={viewMode}
														className={styles.field}
													/>
												</FlexBlock>

												<FlexBlock columnGap="32px">
													<FormikAutocompletePlaceInput
														name="departurePlace"
														label="From"
														placeholder="Search city, place or address"
														disabled={viewMode}
														types={
															formik.values.groundTransportationType === 'rail'
																? ['rail_station']
																: undefined
														}
														className={styles.city}
													/>
													<FormikAutocompletePlaceInput
														name="arrivalPlace"
														label="To"
														placeholder="Search city, place or address"
														disabled={viewMode}
														types={
															formik.values.groundTransportationType === 'rail'
																? ['rail_station']
																: undefined
														}
														className={styles.city}
													/>
												</FlexBlock>
											</FlexBlock>
										)}

										{isAccommodationLog && (
											<FormikAutocompletePlaceInput
												wildcardVisible
												name="accommodationPlace"
												label="City / Accommodation"
												types={['city', 'lodging']}
												placeholder="Search city or accommodation name"
												disabled={viewMode}
												className={styles.city}
											/>
										)}
									</FlexBlock>
								</>
							)}
						/>
						{/* Render fields for other trip types */}
						<div>
							{isAirLog && (
								<FlexBlock flexDirection="column" rowGap="32px">
									<FlexBlock columnGap="32px">
										<FormikDatePicker
											required
											wildcardVisible
											name="departureDate"
											label="Departure date"
											disabled={viewMode}
											className={styles.datePicker}
										/>
										<FormikDatePicker
											required
											wildcardVisible
											name="arrivalDate"
											label="Arrival date"
											disabled={viewMode}
											className={styles.datePicker}
										/>
										{!isSameDepartureAndArrival && (
											<div className={styles.toggleBoxContainer}>
												<span className={styles.toggleBoxLabel}>Round trip</span>
												<FormikCheckboxToggle name="isRoundTrip" disabled={viewMode} />
											</div>
										)}
									</FlexBlock>
									<FlexBlock columnGap="32px">
										<FormikSelect
											name="itineraryId"
											options={allItinerariesOptionsData?.itineraries || []}
											label="Trip"
											disabled={viewMode}
											className={styles.field}
										/>
									</FlexBlock>
								</FlexBlock>
							)}
							{isAccommodationLog && (
								<FlexBlock flexDirection="column" rowGap="32px">
									<FlexBlock columnGap="32px">
										<FormikDatePicker
											required
											wildcardVisible
											name="departureDate"
											label="Check-in"
											disabled={viewMode}
											className={styles.datePicker}
										/>
										<FormikDatePicker
											required
											wildcardVisible
											name="arrivalDate"
											label="Check-out"
											disabled={viewMode}
											className={styles.datePicker}
										/>
									</FlexBlock>
									<FlexBlock columnGap="32px">
										<FormikSelect
											name="itineraryId"
											options={allItinerariesOptionsData?.itineraries || []}
											label="Trip"
											disabled={viewMode}
											className={styles.field}
										/>
										<FormikSelect
											required
											wildcardVisible
											name="accommodationType"
											options={accommodationTypeOptions}
											label="Category"
											disabled={viewMode}
											className={styles.field}
										/>
									</FlexBlock>
								</FlexBlock>
							)}
							{isMileageLog && (
								<FlexBlock flexDirection="column" rowGap="32px">
									<span className={styles.mileageDivider}>or</span>
									<FormikInput
										name="distance"
										label="Distance (km)"
										placeholder="Enter distance"
										disabled={viewMode}
										type="number"
										className={styles.field}
									/>

									<FlexBlock columnGap="32px">
										<FormikDatePicker
											required
											wildcardVisible
											name="departureDate"
											label="Departure date"
											disabled={viewMode}
											className={styles.datePicker}
										/>
										<FormikDatePicker
											required
											wildcardVisible
											name="arrivalDate"
											label="Return date"
											disabled={viewMode}
											className={styles.datePicker}
										/>
										<div className={styles.toggleBoxContainer}>
											<span className={styles.toggleBoxLabel}>Round trip</span>
											<FormikCheckboxToggle name="isRoundTrip" disabled={viewMode} />
										</div>
									</FlexBlock>
									{formik.values.groundTransportationType === 'car' ||
									formik.values.groundTransportationType === 'taxi' ? (
										<FormikSelect
											name="engineType"
											options={engineTypeOptions}
											label="Engine type"
											disabled={viewMode}
											className={styles.field}
										/>
									) : null}
								</FlexBlock>
							)}
							<FormikTextArea
								name="reason"
								label="Add a reason"
								placeholder="Explain to your company’s travel manager why you booked outside of Goodwings"
								disabled={viewMode}
								className={styles.textArea}
							/>
							{!viewMode && (
								<FlexBlock columnGap="20px" className={styles.formButtonWrapper}>
									{editingMode ? (
										<>
											<Button color="darkGreen" size="large" onClick={handleEditFormData}>
												Edit
											</Button>
											<Button
												size="large"
												hover={false}
												onClick={() => handleDeleteFormData(formik.values.id!)}
											>
												Delete
											</Button>
										</>
									) : (
										<Button type="submit" color="darkGreen" size="large">
											Submit
										</Button>
									)}
								</FlexBlock>
							)}
						</div>
					</FlexBlock>
				</Form>
				<InfoStatus
					status={editingMode || viewMode ? 'warning' : 'info'}
					text={
						viewMode
							? daysUntilEditModeExpire(formik.values.createdAt!) > 0
								? 'If you want to the edit travel log, go back and enter edit mode.'
								: 'Editing is no longer available for this entry. Emission logs can only be edited within 1 week of creation.'
							: editingMode
								? `Note: Only ${daysUntilEditModeExpire(formik.values.createdAt!)} days left to edit or delete this entry.`
								: 'Emission log entries can be edited or deleted within a week of creation.'
					}
					className={styles.infoStatus}
				/>
			</FormikProvider>
		</Card>
	);
};

export default LogForm;
