import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import Card from '@/components/ui/Card/Card';
import styles from './CarbonFootprint.module.scss';
import WorldMap from './WorldMap/WorldMap.tsx';
import Source from './Source/Source.tsx';
import { Form, FormikProvider, useFormik } from 'formik';
import FormikSelect from '@/components/Formik/FormikSelect.tsx';
import { periodOptions } from '@/constants/climatePro.ts';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';
import { useWhoamiStore } from '@/store/useWhoamiStore.ts';
import Co2Period = App.Enums.Co2Period;

const CarbonFootprint: React.FC = () => {
	const { organizationEmissionTarget } = useWhoamiStore();

	const [activeButton, setActiveButton] = useState<'Source' | 'Geography'>('Source');
	const containerRef = useRef<HTMLDivElement>(null);
	const sourceTextRef = useRef<HTMLSpanElement>(null);
	const geographyTextRef = useRef<HTMLSpanElement>(null);
	const [indicatorStyle, setIndicatorStyle] = useState({ width: 0, left: 0 });

	const formik = useFormik({
		initialValues: {
			period: 'last-12-months' as Co2Period,
		},
		enableReinitialize: true,
		onSubmit: () => {},
	});

	const updateIndicator = (textRef: HTMLSpanElement | null) => {
		if (!textRef || !containerRef.current) return;
		const containerRect = containerRef.current.getBoundingClientRect();
		const textRect = textRef.getBoundingClientRect();
		setIndicatorStyle({
			width: textRect.width,
			left: textRect.left - containerRect.left,
		});
	};

	useEffect(() => {
		activeButton === 'Source' ? updateIndicator(sourceTextRef.current) : updateIndicator(geographyTextRef.current);
	}, [activeButton]);

	const availablePeriodOptions = useMemo(() => {
		return periodOptions.filter(
			option => !(option.value === 'current-period' && organizationEmissionTarget === null),
		);
	}, [organizationEmissionTarget]);

	return (
		<>
			<Card className={styles.card}>
				<div>
					<FlexBlock justifyContent="space-between">
						<h1>My carbon footprint</h1>
						<FormikProvider value={formik}>
							<Form onSubmit={formik.handleSubmit}>
								<FormikSelect
									required
									name="period"
									options={availablePeriodOptions}
									placeholder="Choose period"
									className={styles.selectDropdown}
								/>
							</Form>
						</FormikProvider>
					</FlexBlock>
					<h2>See the emissions from your recent travel</h2>
					<div ref={containerRef} className={styles.buttonContainer}>
						<button
							className={classNames(styles.button, {
								[styles.active]: activeButton === 'Source',
							})}
							onClick={() => setActiveButton('Source')}
						>
							<span ref={sourceTextRef}>Source</span>
						</button>
						<button
							className={classNames(styles.button, {
								[styles.active]: activeButton === 'Geography',
							})}
							onClick={() => setActiveButton('Geography')}
						>
							<span ref={geographyTextRef}>Geography</span>
						</button>
						<div
							className={styles.indicator}
							style={{
								width: indicatorStyle.width,
								left: indicatorStyle.left,
							}}
						/>
					</div>
					<div className={styles.dividerStatic}></div>
					<div>
						{activeButton === 'Source' && <Source period={formik.values.period} />}
						{activeButton === 'Geography' && <WorldMap period={formik.values.period} />}
					</div>
				</div>
			</Card>
		</>
	);
};

export default CarbonFootprint;
