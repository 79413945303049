import React, { useState } from 'react';
import styles from './WorldMap.module.scss';
import Skeleton from 'react-loading-skeleton';
import { ComposableMap, Geographies, Geography, ZoomableGroup } from 'react-simple-maps';
import mapData from './map.json';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock';
import ReactCountryFlag from 'react-country-flag';
import { scaleLinear } from 'd3-scale';
import { getMyClimateCarbonFootprint } from '@/api/myClimate';
import { useQuery } from '@tanstack/react-query';
import queryKeys from '@/constants/queryKeys';
import classNames from 'classnames';
import { formatWeight } from '@/utils/stringUtils.ts';
import noDataIcon from '@/assets/Icons/No-Data.svg';
import plus from '@/assets/Icons/Plus.svg';
import minus from '@/assets/Icons/Minus.svg';
import Co2Period = App.Enums.Co2Period;

interface WorldMapProps {
	period: Co2Period;
}

const WorldMap: React.FC<WorldMapProps> = ({ period }) => {
	const [hoveredCountry, setHoveredCountry] = useState<string | null>(null);
	const [zoom, setZoom] = useState(2.2);
	const [center, setCenter] = useState<[number, number]>([-45, 50]);

	const { data, isLoading, isError } = useQuery({
		queryKey: [queryKeys.getMyClimateCarbonFootprint, period],
		queryFn: () => getMyClimateCarbonFootprint({ period }),
	});

	const isNoEmission = data?.totalEmission === 0;

	const mockData = {
		totalEmission: 0,
		countries: [
			{ name: 'Denmark', code: 'DK', emission: 0, emissionPercentage: 0 },
			{ name: 'United States', code: 'US', emission: 0, emissionPercentage: 0 },
			{ name: 'United Kingdom', code: 'GB', emission: 0, emissionPercentage: 0 },
			{ name: 'Germany', code: 'DE', emission: 0, emissionPercentage: 0 },
			{ name: 'France', code: 'FR', emission: 0, emissionPercentage: 0 },
		],
	};

	const displayData = isNoEmission ? mockData : data;

	const handleZoomIn = () => {
		setZoom(prev => Math.min(prev + 0.5, 8));
	};

	const handleZoomOut = () => {
		setZoom(prev => Math.max(prev - 0.5, 1));
	};

	const countriesData = React.useMemo(() => {
		if (!displayData) return [];
		return displayData.countries.map(country => ({
			name: country.name,
			code: country.code,
			emission: country.emission,
			emissionPercentage: country.emissionPercentage,
		}));
	}, [displayData]);

	const maxEmission = React.useMemo(() => {
		if (!countriesData.length) return 0;
		return Math.max(...countriesData.map(c => c.emission));
	}, [countriesData]);

	const colorScale = React.useMemo(() => {
		return scaleLinear<string>().domain([0, maxEmission]).range(['#e4e4e4', '#000000']);
	}, [maxEmission]);

	const findCountryByName = React.useCallback(
		(geoName: string) => {
			const normalizedGeoName = geoName.toLowerCase();
			return countriesData.find(c => {
				const countryName = c.name.toLowerCase();
				return countryName.includes(normalizedGeoName) || normalizedGeoName.includes(countryName);
			});
		},
		[countriesData],
	);

	const topCountries = React.useMemo(() => {
		return [...countriesData].sort((a, b) => b.emission - a.emission).slice(0, 5);
	}, [countriesData]);

	const isTopCountry = React.useCallback(
		(countryName: string) => topCountries.some(c => c.name === countryName),
		[topCountries],
	);

	if (!isNoEmission && isLoading) {
		return (
			<FlexBlock justifyContent="space-between" className={styles.worldMapWrapper}>
				<div className={styles.content}>
					<Skeleton height={256} />
				</div>
				<div className={styles.geographyInfo}>
					<Skeleton width={100} height={26} style={{ marginBottom: '9px' }} />
					<Skeleton width={160} height={25} />
					<div className={classNames(styles.legendContainer, styles.skeletonContainer)}>
						{Array.from({ length: 5 }).map((_, index) => (
							<div key={index} className={styles.legendItem}>
								<div className={styles.topRow} style={{ marginBottom: '9px' }}>
									<Skeleton width={16} height={16} />
									<div style={{ width: '100%', flex: 1 }}>
										<Skeleton width="100%" height={16} />
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</FlexBlock>
		);
	}

	if (isError) {
		return <div className={styles.failedRequestMessage}>Failed to load the data. Please try again later.</div>;
	}

	return (
		<div className={styles.worldMapWrapper}>
			<FlexBlock className={styles.worldMapWrapper}>
				<div className={styles.content}>
					<ComposableMap projection="geoMercator" className={styles.geography}>
						<ZoomableGroup
							center={center}
							zoom={zoom}
							onMoveEnd={({ coordinates, zoom: newZoom }) => {
								setCenter(coordinates);
								setZoom(newZoom);
							}}
						>
							<Geographies geography={mapData}>
								{({ geographies }) =>
									geographies.map(geo => {
										const country = findCountryByName(geo.properties.name);
										const fillColor = isNoEmission
											? '#e4e4e4'
											: country
												? colorScale(country.emission)
												: '#e4e4e4';
										return (
											<Geography
												key={geo.rsmKey}
												geography={geo}
												stroke="#000000"
												strokeWidth={0.1}
												onMouseEnter={() => {
													if (country && isTopCountry(country.name)) {
														setHoveredCountry(country.name);
													}
												}}
												onMouseLeave={() => setHoveredCountry(null)}
												style={{
													default: { fill: fillColor, outline: 'none' },
													hover: { fill: '#b5b5b5', outline: 'none' },
													pressed: { fill: fillColor, outline: 'none' },
												}}
											/>
										);
									})
								}
							</Geographies>
						</ZoomableGroup>
					</ComposableMap>
					<div className={styles.zoomControl}>
						<button onClick={handleZoomIn} className={styles.zoomButton} aria-label="Zoom in">
							<img src={plus} alt="Zoom in" />
						</button>
						<button onClick={handleZoomOut} className={styles.zoomButton} aria-label="Zoom out">
							<img src={minus} alt="Zoom out" />
						</button>
					</div>
				</div>
				<div className={styles.geographyInfo}>
					<h2>By country</h2>
					<h1>{displayData?.totalEmission && formatWeight(+displayData?.totalEmission.toFixed(2))}CO2</h1>
					<div className={styles.legendContainer}>
						{topCountries.map(country => (
							<div
								key={country.code || country.name}
								className={styles.legendItem}
								style={{ opacity: !hoveredCountry || hoveredCountry === country.name ? 1 : 0.5 }}
							>
								<div className={styles.topRow}>
									{country.code && (
										<ReactCountryFlag
											countryCode={country.code}
											svg
											style={{ fontSize: '12px', lineHeight: '14px' }}
										/>
									)}
									<span className={styles.label}>{country.name}</span>
									<span className={styles.emission}>{formatWeight(country.emission)}</span>
									<span className={styles.percentage}>
										({country.emissionPercentage.toFixed(2)}%)
									</span>
								</div>
							</div>
						))}
					</div>
				</div>
			</FlexBlock>
			{isNoEmission && (
				<div className={styles.worldMapOverlay}>
					<div className={styles.overlayContent}>
						<img src={noDataIcon} alt="No data" className={styles.noDataImage} />
						<h3 className={styles.overlayTitle}>No data available</h3>
						<p className={styles.overlaySubtitle}>
							Try selecting another date range, or there isn’t enough information to generate insights.
						</p>
					</div>
				</div>
			)}
		</div>
	);
};

export default WorldMap;
