import React from 'react';
import styles from './ReviewsModal.module.scss';
import Modal from '@/components/ui/Modal/Modal.tsx';
import StarsRating from '@/pages/MyClimate/UpcomingTrips/StarsRating.tsx';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';
import Image from '@/components/ui/Image/Image.tsx';
import AvatarPlaceholder from '@/assets/Images/Avatar-Placeholder.webp';
import breakpoints from '@/constants/breakpoints.ts';

interface ReviewsData extends App.Data.GooglePlaceReviewData {
	name: string;
}

const ReviewsModal: React.FC<{ data: ReviewsData[] | null; modalId: string }> = ({ data, modalId }) => {
	return (
		data && (
			<Modal name={modalId}>
				<div className={styles.allReviewsWrapper}>
					<h3>Latest reviews about {data[0].name}</h3>

					{data &&
						data.length > 0 &&
						data.map((review, index) => (
							<div key={index} className={styles.reviewWrapper}>
								<FlexBlock
									columnGap="18px"
									rowGap="10px"
									flexDirection={{
										[breakpoints.zero]: 'column',
										[breakpoints.phoneWide]: 'row',
									}}
									className={styles.reviewContent}
								>
									<div className={styles.imageWrapper}>
										{review.author.avatarUrl && (
											<Image src={review.author.avatarUrl} fallbackSrc={AvatarPlaceholder} />
										)}
									</div>
									<div className={styles.review}>
										<p className={styles.reviewerName}>{review.author.name}</p>
										<StarsRating rating={review.rating} />
										<p className={styles.date}>{review.createdAt}</p>
										<p className={styles.comment}>{review.text}</p>
									</div>
								</FlexBlock>
							</div>
						))}
				</div>
			</Modal>
		)
	);
};

export default ReviewsModal;
