import { create } from 'zustand';
import Co2SourceModelClass = App.Enums.Co2SourceModelClass;
import Co2Period = App.Enums.Co2Period;

interface DashboardAnalytics {
	timePeriod: Co2Period;
	setTimePeriod: (timePeriod: Co2Period) => void;
	contribution: Co2SourceModelClass;
	setContribution: (contributionType: Co2SourceModelClass) => void;
}

const initialData: Omit<DashboardAnalytics, 'setTimePeriod' | 'setContribution'> = {
	timePeriod: 'last-12-months',
	contribution: 'organization',
};

const useDashboardAnalytics = create<DashboardAnalytics>(set => ({
	...initialData,
	setTimePeriod: timePeriod => {
		set({ timePeriod });
	},
	setContribution: contribution => {
		set({ contribution });
	},
}));

export default useDashboardAnalytics;
