import React, { useEffect, useMemo, useState } from 'react';
import styles from './CO2Dashboard.module.scss';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';
import Card from '@/components/ui/Card/Card.tsx';
import BudgetEmissionChart from '@/pages/ClimateProDashboard/CO2Dashboard/BudgetEmissionChart/BudgetEmissionChart.tsx';
import { useQuery } from '@tanstack/react-query';
import queryKeys from '@/constants/queryKeys.ts';
import Select from '@/components/ui/Select/Select.tsx';
import { periodOptions } from '@/constants/climatePro.ts';
import { transformDataToGroupedOptionsArray } from '@/pages/ClimateProDashboard/Metrics/Metrics.tsx';
import { getCo2Segments, getEntitiesFilters } from '@/api/climatePro.ts';
import { useWhoamiStore } from '@/store/useWhoamiStore.ts';
import Co2Period = App.Enums.Co2Period;
import Co2SourceModelClass = App.Enums.Co2SourceModelClass;
import breakpoints from '@/constants/breakpoints.ts';

const Co2Dashboard: React.FC = () => {
	const { organizationEmissionTarget } = useWhoamiStore();

	const [timePeriod, setTimePeriod] = useState<Co2Period>('last-12-months');
	const [entity, setEntity] = useState<string | undefined>(undefined);

	const { data: entitiesFilter, isSuccess: entitiesFilterIsSuccess } = useQuery({
		queryKey: [queryKeys.entitiesFilter],
		queryFn: getEntitiesFilters,
	});
	const query = useQuery({
		queryKey: [queryKeys.co2Segments, timePeriod],
		queryFn: async () => {
			const [sourceModelClass, sourceModelId] = entity!.split('.');
			const data = {
				period: timePeriod,
				sourceModelClass: sourceModelClass as Co2SourceModelClass,
				sourceModelId: +sourceModelId,
			};
			return await getCo2Segments(data);
		},
		enabled: false,
	});

	useEffect(() => {
		entitiesFilter &&
			setEntity(`${entitiesFilter?.entities[0].value}.${entitiesFilter?.entities[0].items[0].value}`);
	}, [entitiesFilterIsSuccess]);

	useEffect(() => {
		entity && query.refetch();
	}, [entity]);

	useEffect(() => {
		timePeriod && query.refetch();
	}, [timePeriod]);

	const availablePeriodOptions = useMemo(() => {
		return periodOptions.filter(
			option => !(option.value === 'current-period' && organizationEmissionTarget === null),
		);
	}, [organizationEmissionTarget]);

	return (
		<FlexBlock flexDirection="column" rowGap="32px" className={styles.co2DashboardWrapper}>
			<h1>CO2 dashboard</h1>
			<FlexBlock
				flexDirection={{
					[breakpoints.zero]: 'column',
					[breakpoints.phoneWide]: 'row',
				}}
				justifyContent="space-between"
				rowGap="16px"
			>
				<h2>Per travel type</h2>
				<FlexBlock
					flexDirection={{
						[breakpoints.zero]: 'column',
						[breakpoints.phoneWide]: 'row',
					}}
					justifyContent="space-between"
					columnGap="32px"
					rowGap="12px"
				>
					<Select
						required
						searchable
						value={entity}
						onChange={value => setEntity(value as string)}
						name="sourceModelId"
						options={transformDataToGroupedOptionsArray(entitiesFilter?.entities || [])}
						className={styles.selectEntityDropdown}
					/>
					<Select
						required
						value={timePeriod}
						onChange={value => value && setTimePeriod(value as Co2Period)}
						placeholder="Choose period"
						options={availablePeriodOptions}
						className={styles.selectDropdown}
					/>
				</FlexBlock>
			</FlexBlock>
			<Card>
				<BudgetEmissionChart queryAnalytics={query} />
			</Card>
		</FlexBlock>
	);
};

export default Co2Dashboard;
