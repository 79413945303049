import React, { useMemo } from 'react';
import styles from './Metrics.module.scss';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';
import IncreaseArrow from '@/assets/Icons/Arrow-Increase.svg?react';
import DecreaseArrow from '@/assets/Icons/Arrow-Decrease.svg?react';
import { CountUp } from 'use-count-up';
import { isNumber } from 'lodash';
import Skeleton from 'react-loading-skeleton';
import { periodOptions } from '@/constants/climatePro.ts';
import { useQuery } from '@tanstack/react-query';
import queryKeys from '@/constants/queryKeys.ts';
import { getMyClimateMetrics } from '@/api/myClimate.ts';
import { Form, FormikProvider, useFormik } from 'formik';
import FormikSelect from '@/components/Formik/FormikSelect.tsx';
import { useWhoamiStore } from '@/store/useWhoamiStore.ts';
import Co2Period = App.Enums.Co2Period;

const Metrics: React.FC = () => {
	const { organizationEmissionTarget } = useWhoamiStore();

	const formik = useFormik({
		initialValues: {
			period: 'last-12-months',
		},
		enableReinitialize: true,
		onSubmit: () => {},
	});

	const { data, isLoading } = useQuery({
		queryKey: [queryKeys.getMyClimateMetrics, formik.values.period],
		queryFn: () => getMyClimateMetrics({ period: formik.values.period as Co2Period }),
		enabled: !!formik.values.period,
	});

	const availablePeriodOptions = useMemo(() => {
		return periodOptions.filter(
			option => !(option.value === 'current-period' && organizationEmissionTarget === null),
		);
	}, [organizationEmissionTarget]);

	return (
		<FlexBlock flexDirection="column" rowGap="24px" className={styles.myClimateMetricsWrapper}>
			<FlexBlock justifyContent="space-between" alignItems="center">
				<h1>CO2 metrics</h1>
				<FormikProvider value={formik}>
					<Form onSubmit={formik.handleSubmit}>
						<FlexBlock columnGap="20px">
							<FormikSelect
								required
								name="period"
								options={availablePeriodOptions}
								placeholder="Choose period"
								className={styles.selectDropdown}
							/>
						</FlexBlock>
					</Form>
				</FormikProvider>
			</FlexBlock>
			<h2>See the emissions from your recent travel</h2>
			<FlexBlock
				justifyContent="space-between"
				rowGap="32px"
				columnGap="24px"
				flexWrap="wrap"
				className={styles.metricsCards}
			>
				{!isLoading && data && data?.metrics?.length > 0 ? (
					data?.metrics.map((item, index) => (
						<div className={styles.metricsCard} key={index}>
							<h4>{item.label}</h4>
							<p className={styles.amount}>
								{isNumber(item.value) ? (
									<CountUp end={item.value} isCounting duration={0.75} />
								) : (
									item.value
								)}
							</p>
							{item.isIncreasing !== null && (
								<div className={styles.percentage}>
									<span className={item.isPositive ? styles.positive : styles.negative}>
										{item.isIncreasing ? <IncreaseArrow /> : <DecreaseArrow />}{' '}
										{item.percentageChange}%
									</span>{' '}
									period-over-period
								</div>
							)}
						</div>
					))
				) : (
					<>
						{Array.from({ length: 4 }).map((_, index) => (
							<div key={index} className={styles.metricsCard}>
								<Skeleton className={styles.skeletonTitle} />
								<Skeleton className={styles.skeletonValue} />
								<Skeleton className={styles.skeletonAddInfo} />
							</div>
						))}
					</>
				)}
			</FlexBlock>
		</FlexBlock>
	);
};

export default Metrics;
