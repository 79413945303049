import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './AutocompletePlaceInput.module.scss';
import Spinner from '@/components/ui/Spinner/Spinner.tsx';
import { useDebounce } from '@uidotdev/usehooks';
import { searchAutocomplete } from '@/api/search.ts';
import OutsideClickHandler from 'react-outside-click-handler';
import MarkerPinIcon from '@/assets/Icons/Marker-Pin.svg?react';
import { isEmpty } from 'lodash';
import GoogleAutocompleteData = App.Data.GoogleAutocompleteData;
import GooglePlaceAutocompleteType = App.Enums.GooglePlaceAutocompleteType;
import GooglePlaceData = App.Data.GooglePlaceData;
import GoogleAutocompleteRequest = App.Data.Request.GoogleAutocompleteRequest;

export interface AutocompletePlaceInputProps {
	className?: string;
	label?: string;
	placeholder?: string;
	value: GooglePlaceData;
	onChange: (newValue?: GooglePlaceData) => void;
	maxLength?: number;
	errorMessage?: string;
	touched?: boolean;
	min?: number;
	disabled?: boolean;
	onTouched?: () => void;
	name?: string;
	ref?: React.Ref<HTMLInputElement>;
	types?: GooglePlaceAutocompleteType[];
	contextGooglePlaceId?: string;
	wildcardVisible?: boolean;
}

const AutocompletePlaceInput: React.FC<AutocompletePlaceInputProps> = ({
	className,
	label,
	placeholder,
	onChange,
	maxLength,
	errorMessage,
	touched,
	min,
	disabled = false,
	onTouched,
	name,
	ref,
	value,
	types,
	contextGooglePlaceId,
	wildcardVisible = false,
}) => {
	// State to store the user input and debounced version of it (to prevent fetching on every keystroke)
	const [searchTerm, setSearchTerm] = useState('');
	const debouncedSearchTerm = useDebounce(searchTerm.trim(), 300); // Debounce the input value by 300ms

	const [loading, setLoading] = useState(false);
	const [isQueryFocused, setIsQueryFocused] = useState(false);
	const [isDropdownShown, setIsDropdownShown] = useState(false);
	const [places, setPlaces] = useState<GoogleAutocompleteData[]>([]);

	// Function to close dropdown
	const closeDropdown = useCallback(() => {
		setIsDropdownShown(false);

		onTouched && setTimeout(() => onTouched(), 50);
	}, [onTouched]);

	// Function to fetch suggestions based on the debounced input value
	const fetchOptions = useCallback((requestOptions: GoogleAutocompleteRequest) => {
		setLoading(true);

		// Fetch suggestions and update dropdown options
		searchAutocomplete(requestOptions)
			.then(response => {
				setPlaces(response.places);
				setIsDropdownShown(true);
			})
			.catch(() => {
				setPlaces([]);
				closeDropdown();
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	// Handle click events outside the wrapper to close the dropdown
	const handleClickOutside = useCallback(() => {
		isDropdownShown && closeDropdown();
	}, [isDropdownShown]);

	const handleChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			// Reset the value of the whole component, on every input change, if needed
			value && onChange(undefined);

			// Set the value of the input field
			setSearchTerm(event.target.value);
		},
		[onChange, value],
	);

	// Handle the selection of an option from the dropdown
	const handleSelectPlace = (autocomplete: GoogleAutocompleteData) => {
		// Set the value of the whole component to the selected item (value)
		onChange({
			googlePlaceId: autocomplete.googlePlaceId,
			name: autocomplete.name,
			addressLong: autocomplete.address,
		});

		// Close the dropdown
		closeDropdown();
	};

	useEffect(() => {
		// Do not fetch if the field is not focused
		if (!isQueryFocused) {
			return;
		}

		// Do not fetch if there is no search length
		if (!debouncedSearchTerm?.length) {
			return;
		}

		// Fetch options when the debounced input value changes
		fetchOptions({
			query: debouncedSearchTerm,
			types,
			contextGooglePlaceId,
		});
	}, [debouncedSearchTerm, types, contextGooglePlaceId]);

	useEffect(() => {
		// Clear the input value when the dropdown is closed and component value is not present
		if (!isDropdownShown && !value) {
			setSearchTerm('');
		}
	}, [isDropdownShown]);

	useEffect(() => {
		if (value && isEmpty(value)) {
			setSearchTerm('');
		}

		if (!value?.googlePlaceId) {
			return;
		}

		// When the value changes to truthy, set search term to the name of the google place
		const name = value?.name || '';
		const addressLong = value?.addressLong || '';

		const searchTerm = addressLong.startsWith(name) ? addressLong : [name, addressLong].filter(Boolean).join(', ');

		setSearchTerm(searchTerm);
	}, [value]);

	return (
		<div className={classNames(styles.wrapper, className)}>
			{!!label && (
				<span className={styles.label}>
					{label}
					{wildcardVisible && '*'}
				</span>
			)}
			<div className={styles.inputWrapper}>
				<div className={styles.inputField}>
					<input
						ref={ref}
						name={name}
						type="text"
						placeholder={placeholder}
						value={searchTerm}
						onChange={handleChange}
						maxLength={maxLength}
						onBlur={() => setIsQueryFocused(false)}
						onFocus={() => setIsQueryFocused(true)}
						min={min}
						disabled={disabled}
						autoComplete="off"
					/>

					{!loading && (
						<div className={styles.pinIcon}>
							<MarkerPinIcon />
						</div>
					)}
					{loading && (
						<span className={styles.spinnerWrapper}>
							<Spinner absolute />
						</span>
					)}
				</div>
				{!!errorMessage && touched && <span>{errorMessage}</span>}
				{isDropdownShown && places.length > 0 && (
					<OutsideClickHandler onOutsideClick={() => setTimeout(handleClickOutside, 10)}>
						<ul className={styles.dropdown}>
							{places.map((option: GoogleAutocompleteData) => {
								return (
									<li
										key={option.googlePlaceId}
										onClick={() => handleSelectPlace(option)}
										className={styles.option}
										dangerouslySetInnerHTML={{
											__html: [option.highlightedName, option.address].filter(Boolean).join(', '),
										}}
									/>
								);
							})}
						</ul>
					</OutsideClickHandler>
				)}
			</div>
		</div>
	);
};

export default AutocompletePlaceInput;
