import React, { ReactNode, useEffect, useRef } from 'react';
import MainLayout from '@/layouts/MainLayout/MainLayout.tsx';
import SideMenu from '@/components/SideMenu/SideMenu.tsx';
import Container from '@/components/ui/Container/Container.tsx';
import { climateProSideMenuItems } from '@/constants/sideMenuContent.tsx';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';
import styles from './ClimateProDashboard.module.scss';
import { Outlet } from 'react-router';
import UnsavedChangesModal from '@/components/UnsavedChangesModal/UnsavedChangesModal.tsx';
import breakpoints from '@/constants/breakpoints.ts';
import BottomMenu from '@/components/BottomMenu/BottomMenu.tsx';
import { useWindowSize } from '@react-hook/window-size';
import { useLocation } from 'react-router-dom';

interface ContentWrapperProps {
	header?: string;
	children: ReactNode;
}

export const ContentWrapper: React.FC<ContentWrapperProps> = ({ header, children }) => {
	return (
		<div className={styles.mainContentWrapper}>
			{header && <h1>{header}</h1>}
			{children}
		</div>
	);
};

const ClimateProDashboard: React.FC = () => {
	const [width] = useWindowSize();
	const location = useLocation();
	const layoutRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		layoutRef.current && layoutRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
	}, [location.pathname]);

	return (
		<MainLayout ref={layoutRef}>
			<Container>
				<FlexBlock columnGap="20px" className={styles.climateProWrapper}>
					{width > breakpoints.tabletWide ? (
						<SideMenu menuItems={climateProSideMenuItems} />
					) : (
						<BottomMenu menuItems={climateProSideMenuItems} />
					)}
					<ContentWrapper>
						<Outlet />
					</ContentWrapper>
				</FlexBlock>
			</Container>
			<UnsavedChangesModal />
		</MainLayout>
	);
};

export default ClimateProDashboard;
