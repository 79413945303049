import React, { useEffect, useState } from 'react';
import styles from './SelectPeriod.module.scss';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';
import { useFormikContext } from 'formik';
import FormikSwitchSelect from '@/components/Formik/FormikSwitchSelect.tsx';
import { timeTypeOptions } from '@/constants/emissionTarget.ts';
import classNames from 'classnames';
import CalendarPicker from '@/components/ui/CalendarPicker/CalendarPicker.tsx';
import OrganizationEmissionTargetData = App.Data.OrganizationEmissionTargetData;
import breakpoints from '@/constants/breakpoints.ts';

interface Date {
	month: number;
	day: number;
}

const SelectPeriod: React.FC = () => {
	const { values, setFieldValue } = useFormikContext<OrganizationEmissionTargetData>();

	const [date, setDate] = useState<Date>({
		day: values.periodStartDay || 1,
		month: values.periodStartMonth || 1,
	});

	useEffect(() => {
		if (values.yearType === 'fiscal') {
			setFieldValue('periodStartDay', date.day);
			setFieldValue('periodStartMonth', date.month);
		} else {
			setFieldValue('periodStartDay', 1);
			setFieldValue('periodStartMonth', 1);
		}
	}, [date, values.yearType, setFieldValue]);

	return (
		<div className={styles.selectPeriodContent}>
			<div className={styles.periodBox}>
				<h2>Fiscal</h2>
				<p>
					If your environmental impact initiatives and financial performance are closely linked, consider the
					fiscal year. This ensures your emissions reporting aligns with your company's financial cycles,
					making it easier to integrate environmental performance in annual reports and strategy reviews.
				</p>
			</div>
			<div className={styles.periodBox}>
				<h2>Calendar</h2>
				<p>
					Opt for the calendar year if your business operations are impacted by seasonal variations or if it
					simplifies coordination with external reporting requirements and benchmarks. Budgeting by calendar
					year allows for straightforward year-on-year comparisons and may align better with external
					environmental reporting frameworks.
				</p>
			</div>
			<FlexBlock
				flexDirection={{
					[breakpoints.zero]: 'column',
					[breakpoints.tablet]: 'row',
				}}
				justifyContent="space-between"
				alignItems="center"
				rowGap="28px"
				className={classNames(styles.periodBox, styles.selectYearType)}
			>
				<h2>Choose preferable type of reduction target</h2>
				<FormikSwitchSelect name="yearType" options={timeTypeOptions} />
			</FlexBlock>
			{values.yearType === 'fiscal' && (
				<div className={classNames(styles.periodBox, styles.selectDate)}>
					<h2>Choose your fiscal dates</h2>
					<p>Select the start of your fiscal year</p>
					<CalendarPicker onSelect={setDate} value={date} popoverPosition="top" />
				</div>
			)}
		</div>
	);
};

export default SelectPeriod;
