import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';
import Button from '@/components/ui/Button/Button.tsx';
import styles from './Allocation.module.scss';
import { FieldArray, Form, FormikHelpers, FormikProvider, useFormik } from 'formik';
import { useQuery } from '@tanstack/react-query';
import queryKeys from '@/constants/queryKeys.ts';
import { getAllocationResponsiblePersons, getAllocationTables, updateAllocationTables } from '@/api/climatePro.ts';
import FormikSelect from '@/components/Formik/FormikSelect.tsx';
import DeleteIcon from '@/assets/Icons/Trash-Bin.svg?react';
import Tooltip from '@/components/ui/Tooltip/Tooltip.tsx';
import FormikInput from '@/components/Formik/FormikInput.tsx';
import handleResponseErrors from '@/utils/handleResponseErrors.ts';
import {
	departmentsValidationSchema,
	legalEntitiesValidationSchema,
	officesValidationSchema,
} from '@/validations/climatePro.ts';
import { departmentsDemoColumns, legalEntitiesDemoColumns, officesDemoColumns } from '@/constants/climatePro.ts';
import Spinner from '@/components/ui/Spinner/Spinner.tsx';
import Skeleton from 'react-loading-skeleton';
import { useRouteBlocker } from '@/hooks/useRouteBlocker.ts';
import { useWhoamiStore } from '@/store/useWhoamiStore.ts';
import { toSelectOptions } from '@/utils/objectUtils.ts';
import Select, { Value } from '@/components/ui/Select/Select.tsx';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { getOrganizationEntities } from '@/api/organization.ts';
import Checkmark from '@/assets/Icons/Check-Mark.svg?react';
import PenIcon from '@/assets/Icons/Pen.svg?react';
import { submenuLinks } from '@/constants/emissionTarget.ts';
import SubmenuItems from '@/components/SubmenuItems/SubmenuItems.tsx';
import breakpoints from '@/constants/breakpoints.ts';
import { useWindowSize } from '@react-hook/window-size';
import UpdateEntityAllocationRequest = App.Data.Request.UpdateEntityAllocationRequest;
import AllocationModelClass = App.Enums.AllocationModelClass;

const Allocation: React.FC = () => {
	const [width] = useWindowSize();
	const { organizationEmissionTarget, user } = useWhoamiStore();

	const [selectedLegalEntityId, setSelectedLegalEntityId] = useState<Value | undefined>();

	const [isLegalEntitiesTableEditable, setIsLegalEntitiesTableEditable] = useState<boolean>(false);
	const [isOfficesTableEditable, setIsOfficesTableEditable] = useState<boolean>(false);
	const [isDepartmentsTableEditable, setIsDepartmentsTableEditable] = useState<boolean>(false);

	// Show unsaved changes modal when there is a new form values and user wants to navigate to another page
	const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
	useRouteBlocker(hasUnsavedChanges);

	const { data: organizationEntitiesData, isLoading: isOrganizationEntitiesDataLoading } = useQuery({
		queryKey: [queryKeys.organizationEntities],
		queryFn: () => getOrganizationEntities(Number(user?.organization?.id)),
	});

	const { data: allocationResponsiblePersonsData, isLoading: isAllocationResponsiblePersonsLoading } = useQuery({
		queryKey: [queryKeys.responsibleUsers],
		queryFn: getAllocationResponsiblePersons,
	});

	const {
		data: allocationTables,
		isLoading: isAllocationTablesLoading,
		isSuccess: allocationTablesSuccess,
		refetch: refetchAllocationTables,
	} = useQuery({
		queryKey: [queryKeys.allocationTables],
		queryFn: getAllocationTables,
	});

	const legalEntitiesFormik = useFormik<UpdateEntityAllocationRequest>({
		initialValues: {
			modelClass: 'legal_entity',
			allocations: allocationTables?.legalEntities || [],
		},
		enableReinitialize: true,
		validationSchema: legalEntitiesValidationSchema,
		onSubmit: (values, formikHelpers) => handleSaveChanges('legal_entity', values, formikHelpers),
	});

	const officesFormik = useFormik<UpdateEntityAllocationRequest>({
		initialValues: {
			modelClass: 'office',
			allocations: [],
		},
		enableReinitialize: true,
		validationSchema: officesValidationSchema,
		onSubmit: (values, formikHelpers) => handleSaveChanges('office', values, formikHelpers),
	});

	const departmentsFormik = useFormik<UpdateEntityAllocationRequest>({
		initialValues: {
			modelClass: 'department',
			allocations: allocationTables?.departments || [],
		},
		enableReinitialize: true,
		validationSchema: departmentsValidationSchema,
		onSubmit: (values, formikHelpers) => handleSaveChanges('department', values, formikHelpers),
	});

	useEffect(() => {
		// If no legal entity is selected, do nothing
		if (!selectedLegalEntityId) {
			return;
		}

		// Update the 'allocations' field in the form with filtered office allocations
		officesFormik.setFieldValue(
			'allocations',
			allocationTables?.offices?.filter(office => office.legalEntityId === selectedLegalEntityId) || [],
		);
	}, [selectedLegalEntityId, allocationTablesSuccess, isOfficesTableEditable]);

	useEffect(() => {
		setHasUnsavedChanges(isLegalEntitiesTableEditable || isOfficesTableEditable || isDepartmentsTableEditable);
	}, [isLegalEntitiesTableEditable, isOfficesTableEditable, isDepartmentsTableEditable]);

	const handleSaveChanges = async (
		modelClass: AllocationModelClass,
		data: UpdateEntityAllocationRequest,
		formikHelpers: FormikHelpers<UpdateEntityAllocationRequest>,
	) => {
		const allocationData: UpdateEntityAllocationRequest = {
			allocations: data.allocations,
			modelClass,
			legalEntityId: modelClass === 'office' && selectedLegalEntityId ? Number(selectedLegalEntityId) : undefined,
		};

		const setTableEditable: Record<AllocationModelClass, Dispatch<SetStateAction<boolean>>> = {
			legal_entity: setIsLegalEntitiesTableEditable,
			office: setIsOfficesTableEditable,
			department: setIsDepartmentsTableEditable,
		};

		try {
			await updateAllocationTables(allocationData);
			await refetchAllocationTables();
			setTableEditable[modelClass](false);
			toast.success(
				'Successfully saved changes.\n\nCO2 targets have been recalculated accordingly to the percentages entered.',
			);
		} catch (error) {
			handleResponseErrors(error, formikHelpers);
		}
	};

	// Memoized options for legal entities
	const legalEntityOptions = useMemo(
		() => toSelectOptions(organizationEntitiesData?.legalEntities, 'name', 'id'),
		[organizationEntitiesData?.legalEntities],
	);

	// Memoized options offices
	const officeOptions = useMemo(
		() =>
			toSelectOptions(
				organizationEntitiesData?.offices.filter(office => office.parentId === selectedLegalEntityId),
				'name',
				'id',
			),
		[organizationEntitiesData?.offices, selectedLegalEntityId],
	);

	// Memoized options for departments
	const departmentOptions = useMemo(
		() => toSelectOptions(organizationEntitiesData?.departments, 'name', 'id'),
		[organizationEntitiesData?.departments],
	);

	// Memoized options for responsible users
	const responsibleUserOptions = useMemo(
		() => toSelectOptions(allocationResponsiblePersonsData?.responsibleUsers, 'fullName', 'id'),
		[allocationResponsiblePersonsData?.responsibleUsers],
	);

	return (
		<>
			{width < breakpoints.tabletWide && <SubmenuItems links={submenuLinks} />}
			<FlexBlock flexDirection="column" rowGap="38px" className={styles.allocationWrapper}>
				{/* Legal entities table*/}
				<FormikProvider value={legalEntitiesFormik}>
					<Form onSubmit={legalEntitiesFormik.handleSubmit}>
						<FieldArray
							name="allocations"
							render={arrayHelpers => (
								<>
									<FlexBlock
										justifyContent="space-between"
										flexDirection={{
											[breakpoints.zero]: 'column',
											[breakpoints.tablet]: 'row',
										}}
										alignItems={{
											[breakpoints.zero]: 'flex-start',
											[breakpoints.tablet]: 'center',
										}}
										columnGap="10px"
										rowGap="22px"
										className={styles.headerWrapper}
									>
										<h1>Allocate your CO2 emission budget</h1>
										{organizationEmissionTarget && (
											<div className={styles.target}>
												Company target:
												<span>{organizationEmissionTarget}</span> tons of CO2
											</div>
										)}
									</FlexBlock>
									<FlexBlock
										flexDirection="column"
										rowGap="38px"
										className={styles.tableSectionWrapper}
									>
										<div className={styles.tableWrapper}>
											<FlexBlock
												flexDirection={{
													[breakpoints.zero]: 'column',
													[breakpoints.phoneWide]: 'row',
												}}
												justifyContent="space-between"
												alignItems={{
													[breakpoints.zero]: 'flex-start',
													[breakpoints.phoneWide]: 'center',
												}}
												rowGap="12px"
											>
												<h2>Legal entities</h2>
												<Button
													color="darkGreen"
													size="medium"
													align="right"
													className={styles.addButton}
													onClick={() => {
														arrayHelpers.push({
															modelId: undefined,
															percentage: 0,
															responsibleUserId: undefined,
														});
														setIsLegalEntitiesTableEditable(true);
													}}
													disabled={
														organizationEntitiesData?.legalEntities.length === 0 ||
														organizationEntitiesData?.legalEntities.length ===
															legalEntitiesFormik.values.allocations.length
													}
												>
													Add
												</Button>
											</FlexBlock>
											<div className={styles.tableContainer}>
												<table>
													<thead>
														<tr>
															{legalEntitiesDemoColumns.map(
																({ header, accessorKey }, idx) => {
																	if (
																		!isLegalEntitiesTableEditable &&
																		accessorKey === 'action'
																	) {
																		return null;
																	}

																	if (
																		isLegalEntitiesTableEditable &&
																		accessorKey === 'amount'
																	) {
																		return null;
																	}

																	return <th key={idx}>{header}</th>;
																},
															)}
														</tr>
													</thead>
													<tbody
														className={classNames(
															isLegalEntitiesTableEditable && styles.isEditMode,
														)}
													>
														{legalEntitiesFormik.values.allocations.map((item, index) => (
															<tr key={index}>
																<td>
																	{isOrganizationEntitiesDataLoading && (
																		<Skeleton width={120} height={17} />
																	)}
																	{isLegalEntitiesTableEditable ? (
																		<FormikSelect
																			required
																			tableSelect
																			name={`allocations.${index}.modelId`}
																			options={legalEntityOptions}
																		/>
																	) : (
																		<p>
																			{
																				organizationEntitiesData?.legalEntities.find(
																					entity =>
																						entity.id === item.modelId,
																				)?.name
																			}
																		</p>
																	)}
																</td>
																<td>
																	{isLegalEntitiesTableEditable ? (
																		<FormikInput
																			tableInput
																			name={`allocations.${index}.percentage`}
																		/>
																	) : (
																		<p>{item.percentage}%</p>
																	)}
																</td>
																{!isLegalEntitiesTableEditable && (
																	<td>{item.targetCo2} tons</td>
																)}
																<td>
																	{isAllocationResponsiblePersonsLoading && (
																		<Skeleton width={120} height={17} />
																	)}
																	{isLegalEntitiesTableEditable ? (
																		<FormikSelect
																			required
																			tableSelect
																			searchable
																			name={`allocations.${index}.responsibleUserId`}
																			options={responsibleUserOptions}
																		/>
																	) : (
																		<p>
																			{
																				allocationResponsiblePersonsData?.responsibleUsers.find(
																					person =>
																						person.id ===
																						item.responsibleUserId,
																				)?.fullName
																			}
																		</p>
																	)}
																</td>
																{isLegalEntitiesTableEditable && (
																	<td>
																		<Tooltip
																			icon={
																				<button
																					onClick={() =>
																						arrayHelpers.remove(index)
																					}
																					className={styles.actionBttn}
																				>
																					<DeleteIcon />
																				</button>
																			}
																			text="Delete row"
																		/>
																	</td>
																)}
															</tr>
														))}
													</tbody>
												</table>
											</div>
											{isLegalEntitiesTableEditable ? (
												<FlexBlock justifyContent="flex-end" alignItems="center">
													<Button
														size="small"
														onClick={() => {
															legalEntitiesFormik.resetForm();
															setIsLegalEntitiesTableEditable(false);
														}}
														className={styles.cancelBttn}
													>
														Cancel
													</Button>
													<Button
														type="submit"
														color="darkGreen"
														size="small"
														disabled={
															!legalEntitiesFormik.isValid ||
															legalEntitiesFormik.isSubmitting
														}
														className={styles.saveBttn}
													>
														<Checkmark /> Save
													</Button>
												</FlexBlock>
											) : (
												<FlexBlock
													justifyContent={
														legalEntitiesFormik.values.allocations.length
															? 'flex-end'
															: 'center'
													}
												>
													<div className={styles.spinnerEditWrapper}>
														{legalEntitiesFormik.values.allocations.length ? (
															<Button
																color="darkGreen"
																size="small"
																onClick={() => setIsLegalEntitiesTableEditable(true)}
																className={styles.editBttn}
															>
																<PenIcon /> Edit
															</Button>
														) : !isAllocationTablesLoading ? (
															<p className={styles.emptyTable}>No data available</p>
														) : (
															<Spinner absolute />
														)}
													</div>
												</FlexBlock>
											)}
										</div>
									</FlexBlock>
								</>
							)}
						/>
					</Form>
				</FormikProvider>

				{/*	Offices table*/}
				<FormikProvider value={officesFormik}>
					<Form onSubmit={officesFormik.handleSubmit}>
						<FieldArray
							name="allocations"
							render={arrayHelpers => (
								<FlexBlock flexDirection="column" rowGap="38px" className={styles.tableSectionWrapper}>
									<div className={styles.tableWrapper}>
										<FlexBlock
											flexDirection={{
												[breakpoints.zero]: 'column',
												[breakpoints.tablet]: 'row',
											}}
											justifyContent="space-between"
											alignItems={{
												[breakpoints.zero]: 'flex-start',
												[breakpoints.tablet]: 'center',
											}}
											rowGap="12px"
										>
											<h2>Offices</h2>
											<FlexBlock
												flexDirection={{
													[breakpoints.zero]: 'column',
													[breakpoints.tablet]: 'row',
												}}
												columnGap="20px"
												rowGap="12px"
												className={styles.selectOffices}
											>
												<Select
													required
													placeholder="- Select legal entity -"
													onChange={legalEntityId => setSelectedLegalEntityId(legalEntityId)}
													value={selectedLegalEntityId}
													options={legalEntityOptions}
													className={styles.legalEntitySelect}
												/>
												<Button
													color="darkGreen"
													size="medium"
													align="right"
													className={styles.addButton}
													onClick={() => {
														arrayHelpers.push({
															modelId: undefined,
															percentage: 0,
															responsibleUserId: undefined,
														});
														setIsOfficesTableEditable(true);
													}}
													disabled={
														organizationEntitiesData?.legalEntities?.length === 0 ||
														!selectedLegalEntityId
													}
												>
													Add
												</Button>
											</FlexBlock>
										</FlexBlock>

										{selectedLegalEntityId ? (
											<>
												<div className={styles.tableContainer}>
													<table>
														<thead>
															<tr>
																{officesDemoColumns.map(
																	({ header, accessorKey }, idx) => {
																		if (
																			!isOfficesTableEditable &&
																			accessorKey === 'action'
																		) {
																			return null;
																		}

																		if (
																			isOfficesTableEditable &&
																			accessorKey === 'amount'
																		) {
																			return null;
																		}

																		return <th key={idx}>{header}</th>;
																	},
																)}
															</tr>
														</thead>
														<tbody
															className={classNames(
																isOfficesTableEditable && styles.isEditMode,
															)}
														>
															{officesFormik.values.allocations.map((item, index) => (
																<tr key={index}>
																	<td>
																		{isOrganizationEntitiesDataLoading && (
																			<Skeleton width={120} height={17} />
																		)}
																		{isOfficesTableEditable ? (
																			<FormikSelect
																				required
																				tableSelect
																				name={`allocations.${index}.modelId`}
																				options={officeOptions}
																			/>
																		) : (
																			<p>
																				{
																					organizationEntitiesData?.offices.find(
																						entity =>
																							entity.id === item.modelId,
																					)?.name
																				}
																			</p>
																		)}
																	</td>
																	<td>
																		{isOfficesTableEditable ? (
																			<FormikInput
																				tableInput
																				name={`allocations.${index}.percentage`}
																			/>
																		) : (
																			<p>{item.percentage}%</p>
																		)}
																	</td>
																	{!isOfficesTableEditable && (
																		<td>{item.targetCo2} tons</td>
																	)}
																	<td>
																		{isAllocationResponsiblePersonsLoading && (
																			<Skeleton width={120} height={17} />
																		)}
																		{isOfficesTableEditable ? (
																			<FormikSelect
																				required
																				searchable
																				tableSelect
																				name={`allocations.${index}.responsibleUserId`}
																				options={responsibleUserOptions}
																			/>
																		) : (
																			<p>
																				{
																					allocationResponsiblePersonsData?.responsibleUsers.find(
																						person =>
																							person.id ===
																							item.responsibleUserId,
																					)?.fullName
																				}
																			</p>
																		)}
																	</td>
																	{isOfficesTableEditable && (
																		<td>
																			<Tooltip
																				icon={
																					<button
																						onClick={() =>
																							arrayHelpers.remove(index)
																						}
																						className={styles.actionBttn}
																					>
																						<DeleteIcon />
																					</button>
																				}
																				text="Delete row"
																			/>
																		</td>
																	)}
																</tr>
															))}
														</tbody>
													</table>
												</div>
												{isOfficesTableEditable ? (
													<FlexBlock justifyContent="flex-end" alignItems="center">
														<Button
															size="small"
															onClick={() => {
																officesFormik.resetForm();
																setIsOfficesTableEditable(false);
															}}
															className={styles.cancelBttn}
														>
															Cancel
														</Button>
														<Button
															type="submit"
															color="darkGreen"
															size="small"
															disabled={
																!officesFormik.isValid || officesFormik.isSubmitting
															}
															className={styles.saveBttn}
														>
															<Checkmark /> Save
														</Button>
													</FlexBlock>
												) : (
													<FlexBlock
														justifyContent={
															officesFormik.values.allocations.length
																? 'flex-end'
																: 'center'
														}
													>
														<div className={styles.spinnerEditWrapper}>
															{officesFormik.values.allocations.length ? (
																<Button
																	color="darkGreen"
																	size="small"
																	onClick={() => setIsOfficesTableEditable(true)}
																	className={styles.editBttn}
																>
																	<PenIcon /> Edit
																</Button>
															) : !isAllocationTablesLoading ? (
																<p className={styles.emptyTable}>No data available</p>
															) : (
																<Spinner absolute />
															)}
														</div>
													</FlexBlock>
												)}
											</>
										) : (
											<FlexBlock justifyContent="center">
												<div className={styles.spinnerEditWrapper}>
													<p className={styles.emptyTable}>Please select an office</p>
												</div>
											</FlexBlock>
										)}
									</div>
								</FlexBlock>
							)}
						/>
					</Form>
				</FormikProvider>

				{/* Departments table*/}
				<FormikProvider value={departmentsFormik}>
					<Form onSubmit={departmentsFormik.handleSubmit}>
						<FieldArray
							name="allocations"
							render={arrayHelpers => (
								<FlexBlock flexDirection="column" rowGap="38px" className={styles.tableSectionWrapper}>
									<div className={styles.tableWrapper}>
										<FlexBlock
											flexDirection={{
												[breakpoints.zero]: 'column',
												[breakpoints.phoneWide]: 'row',
											}}
											columnGap="20px"
											rowGap="12px"
											justifyContent="space-between"
											alignItems={{
												[breakpoints.zero]: 'flex-start',
												[breakpoints.phoneWide]: 'center',
											}}
										>
											<h2>Departments</h2>
											<Button
												color="darkGreen"
												size="medium"
												align="right"
												className={styles.addButton}
												onClick={() => {
													arrayHelpers.push({
														modelId: undefined,
														percentage: 0,
														responsibleUserId: undefined,
													});
													setIsDepartmentsTableEditable(true);
												}}
												disabled={
													organizationEntitiesData?.departments.length === 0 ||
													organizationEntitiesData?.departments.length ===
														departmentsFormik.values.allocations.length
												}
											>
												Add
											</Button>
										</FlexBlock>
										<div className={styles.tableContainer}>
											<table>
												<thead>
													<tr>
														{departmentsDemoColumns.map(({ header, accessorKey }, idx) => {
															if (
																!isDepartmentsTableEditable &&
																accessorKey === 'action'
															) {
																return null;
															}

															if (
																isDepartmentsTableEditable &&
																accessorKey === 'amount'
															) {
																return null;
															}

															return <th key={idx}>{header}</th>;
														})}
													</tr>
												</thead>

												<tbody
													className={classNames(
														isDepartmentsTableEditable && styles.isEditMode,
													)}
												>
													{departmentsFormik.values.allocations.map((item, index) => (
														<tr key={index}>
															<td>
																{isOrganizationEntitiesDataLoading && (
																	<Skeleton width={120} height={17} />
																)}
																{isDepartmentsTableEditable ? (
																	<FormikSelect
																		required
																		tableSelect
																		name={`allocations.${index}.modelId`}
																		options={departmentOptions}
																	/>
																) : (
																	<p>
																		{
																			organizationEntitiesData?.departments.find(
																				entity => entity.id === item.modelId,
																			)?.name
																		}
																	</p>
																)}
															</td>
															<td>
																{isDepartmentsTableEditable ? (
																	<FormikInput
																		tableInput
																		name={`allocations.${index}.percentage`}
																	/>
																) : (
																	<p>{item.percentage}%</p>
																)}
															</td>
															{!isDepartmentsTableEditable && (
																<td>{item.targetCo2} tons</td>
															)}
															<td>
																{isAllocationResponsiblePersonsLoading && (
																	<Skeleton width={120} height={17} />
																)}
																{isDepartmentsTableEditable ? (
																	<FormikSelect
																		required
																		searchable
																		tableSelect
																		name={`allocations.${index}.responsibleUserId`}
																		options={responsibleUserOptions}
																	/>
																) : (
																	<p>
																		{
																			allocationResponsiblePersonsData?.responsibleUsers.find(
																				person =>
																					person.id ===
																					item.responsibleUserId,
																			)?.fullName
																		}
																	</p>
																)}
															</td>
															{isDepartmentsTableEditable && (
																<td>
																	<Tooltip
																		icon={
																			<button
																				onClick={() =>
																					arrayHelpers.remove(index)
																				}
																				className={styles.actionBttn}
																			>
																				<DeleteIcon />
																			</button>
																		}
																		text="Delete row"
																	/>
																</td>
															)}
														</tr>
													))}
												</tbody>
											</table>
										</div>
										{isDepartmentsTableEditable ? (
											<FlexBlock justifyContent="flex-end" alignItems="center">
												<Button
													size="small"
													onClick={() => {
														departmentsFormik.resetForm();
														setIsDepartmentsTableEditable(false);
													}}
													className={styles.cancelBttn}
												>
													Cancel
												</Button>
												<Button
													type="submit"
													color="darkGreen"
													size="small"
													disabled={
														!departmentsFormik.isValid || departmentsFormik.isSubmitting
													}
													className={styles.saveBttn}
												>
													<Checkmark /> Save
												</Button>
											</FlexBlock>
										) : (
											<FlexBlock
												justifyContent={
													departmentsFormik.values.allocations.length ? 'flex-end' : 'center'
												}
											>
												<div className={styles.spinnerEditWrapper}>
													{departmentsFormik.values.allocations.length ? (
														<Button
															color="darkGreen"
															size="small"
															onClick={() => setIsDepartmentsTableEditable(true)}
															className={styles.editBttn}
														>
															<PenIcon /> Edit
														</Button>
													) : !isAllocationTablesLoading ? (
														<p className={styles.emptyTable}>No data available</p>
													) : (
														<Spinner absolute />
													)}
												</div>
											</FlexBlock>
										)}
									</div>
								</FlexBlock>
							)}
						/>
					</Form>
				</FormikProvider>
			</FlexBlock>
		</>
	);
};

export default Allocation;
