import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { OnboardNewUserRequestExtended, registerUser, RegisterUserRequest } from '@/api/auth';
import { useParams } from 'react-router';
import styles from './EmployeeOnboard.module.scss';
import MainLayout from '@/layouts/MainLayout/MainLayout';
import Container from '@/components/ui/Container/Container';
import { Form, FormikHelpers, FormikProvider, useFormik } from 'formik';
import FormikInput from '@/components/Formik/FormikInput';
import FormikSelect from '@/components/Formik/FormikSelect';
import Button from '@/components/ui/Button/Button';
import { onboardEmployeeSchema } from '@/validations/auth.ts';
import Spinner from '@/components/ui/Spinner/Spinner.tsx';
import GlobalError from '@/components/FormikErrors/GlobalError.tsx';
import handleResponseErrors from '@/utils/handleResponseErrors.ts';
import queryKeys from '@/constants/queryKeys.ts';
import { getOrganizationEntities } from '@/api/organization.ts';
import FormikPhoneInput from '@/components/Formik/FormikPhoneInput.tsx';
import InfoStatus from '@/components/ui/InfoStatus/InfoStatus.tsx';

const EmployeeOnboard: React.FC = () => {
	const [showInfoSuccess, setShowInfoSuccess] = useState({ show: false, email: '' });
	const { token } = useParams<{ token: string }>();
	const { data, isError, isLoading } = useQuery({
		queryKey: [queryKeys.getEmployeeOnboardOrganizationsEntities, token],
		queryFn: () => getOrganizationEntities(token || ''),
		enabled: !!token,
	});

	const [initialValues, setInitialValues] = useState<RegisterUserRequest>({
		firstName: '',
		middleName: '',
		lastName: '',
		email: '',
		jobTitle: '',
		employeeNumber: '',
		phone: '',
		legalEntityId: undefined,
	});

	const formik = useFormik<RegisterUserRequest>({
		initialValues: initialValues,
		enableReinitialize: true,
		validationSchema: onboardEmployeeSchema(data?.legalEntities || []),
		onSubmit: (values, formikHelpers) => handleSubmit(values, formikHelpers),
	});

	useEffect(() => {
		if (data) {
			const legalEntityId = data.legalEntities?.length === 1 ? data.legalEntities[0].id : undefined;
			setInitialValues(prev => ({
				...prev,
				legalEntityId,
			}));
		}
	}, [data]);

	useEffect(() => {
		if (data && !data.offices.filter(item => item.parentId === formik.values.legalEntityId).length) {
			formik.setFieldValue('officeId', undefined);
		}
	}, [formik.values.legalEntityId]);

	const legalEntitiesOptions =
		data?.legalEntities?.map(entity => ({
			label: entity.name,
			value: entity.id,
		})) || [];

	const handleSubmit = async (values: RegisterUserRequest, formikHelpers: FormikHelpers<RegisterUserRequest>) => {
		try {
			const changedValues: OnboardNewUserRequestExtended = {
				...values,
				onboardToken: token || '',
				legalEntityId: values.legalEntityId || 0,
			};
			await registerUser(changedValues);
			setShowInfoSuccess({ show: true, email: changedValues.email });
			formikHelpers.resetForm();
		} catch (error) {
			handleResponseErrors(error, formikHelpers);
		}
	};

	if (isLoading) {
		return (
			<MainLayout>
				<Spinner />
			</MainLayout>
		);
	}

	if (isError) {
		return (
			<MainLayout>
				<Container>
					<div className={styles.employeeOnboardWrapper}>
						<h1>Not Found</h1>
						<p>
							We're sorry, but the page you are looking for could not be found. Please check the
							information provided and try again. If you continue to experience issues, kindly contact our
							support team for assistance. We apologize for any inconvenience caused.
						</p>
					</div>
				</Container>
			</MainLayout>
		);
	}

	return (
		<MainLayout>
			<Container>
				<div className={styles.employeeOnboardWrapper}>
					<h3>Create your free profile</h3>
					<p>
						We've partnered with Goodwings, the leading travel management system for responsible business
						travel. It's easy to use - simply book your travel with Goodwings and they'll calculate your
						full trip emissions - everything from your flights, hotels, trains, taxis and meals, and reduce
						them for free. How do they pay for it? They don't profit from your bookings like other travel
						management systems. Instead, they channel the commissions they earn from your bookings directly
						into green technology investments like biofuel and reforestation projects. Cool, right?
					</p>
					<h3>Let's get started </h3>
					<p>
						Simply fill out your details below to create your free profile, and we'll send you an email so
						you can start booking right away!
					</p>
					<FormikProvider value={formik}>
						<Form>
							<GlobalError />
							<FormikInput name="firstName" label="First name*" className={styles.formikInput} />
							<FormikInput name="middleName" label="Middle name" className={styles.formikInput} />
							<FormikInput name="lastName" label="Last name*" className={styles.formikInput} />
							<FormikInput name="email" type="email" label="Email*" className={styles.formikInput} />
							<FormikInput name="jobTitle" type="text" label="Job title" className={styles.formikInput} />
							<FormikInput
								name="employeeNumber"
								type="text"
								label="Employee number"
								className={styles.formikInput}
							/>
							<FormikPhoneInput
								name="phone"
								defaultValue="DK"
								label="Phone*"
								className={styles.formikInput}
							/>
							{data?.legalEntities && data.legalEntities.length > 1 && (
								<FormikSelect
									required
									searchable
									name="legalEntityId"
									options={legalEntitiesOptions}
									label="Legal entity"
									placeholder="Choose legal entity"
									position="top"
									className={styles.formikInput}
								/>
							)}
							{data?.offices &&
								data.offices.length > 1 &&
								data.offices.filter(item => item.parentId === formik.values.legalEntityId).length >
									1 && (
									<FormikSelect
										required
										searchable
										name="officeId"
										options={data.offices
											.filter(item => item.parentId === formik.values.legalEntityId)
											.map(item => ({ value: item.id, label: item.name }))}
										label="Office"
										placeholder="Choose office"
										position="top"
										className={styles.formikInput}
									/>
								)}
							{data?.departments && data.departments.length > 1 && (
								<FormikSelect
									required
									searchable
									name="departmentId"
									options={data.departments.map(item => ({ value: item.id, label: item.name }))}
									label="Department"
									placeholder="Choose department"
									position="top"
									className={styles.formikInput}
								/>
							)}
							{data?.costCenters && data.costCenters.length > 1 && (
								<FormikSelect
									required
									searchable
									name="costCenterId"
									options={data.costCenters.map(item => ({ value: item.id, label: item.name }))}
									label="Cost center"
									placeholder="Choose cost center"
									position="top"
									className={styles.formikInput}
								/>
							)}
							{showInfoSuccess.show && (
								<InfoStatus
									status="info"
									text={`The account has been successfully created! A verification link has been sent to ${showInfoSuccess.email}. Please check the email and click the link to activate the account.`}
								/>
							)}
							<Button
								type="submit"
								color="green"
								size="large"
								disabled={formik.isSubmitting}
								loading={formik.isSubmitting}
								className={styles.submitButton}
							>
								Join
							</Button>
						</Form>
					</FormikProvider>
				</div>
			</Container>
		</MainLayout>
	);
};

export default EmployeeOnboard;
