import styles from './Overlay.module.scss';
import classNames from 'classnames';

interface OverlayProps {
	text?: string;
	subtext?: string;
	className?: string;
	onClick?: () => void;
}

const Overlay: React.FC<OverlayProps> = ({ text = '', subtext, className, onClick }) => (
	<div className={classNames(styles.overlay, className)} onClick={onClick}>
		{text}
		{subtext && <p>{subtext}</p>}
	</div>
);
export default Overlay;
