import axios from 'axios';
import ListRestaurantsResponse = App.Data.Response.ListRestaurantsResponse;
import ListRestaurantsRequest = App.Data.Request.ListRestaurantsRequest;
import ListPlacesToRelaxResponse = App.Data.Response.ListPlacesToRelaxResponse;
import ListPlacesToRelaxRequest = App.Data.Request.ListPlacesToRelaxRequest;
import GetAirportTransportationResponse = App.Data.Response.GetAirportTransportationResponse;
import GetAirportTransportationRequest = App.Data.Request.GetAirportTransportationRequest;
import AddPlaceToCalendarRequest = App.Data.Request.AddPlaceToCalendarRequest;
import ListPlacesResponse = App.Data.Response.ListPlacesResponse;

export const getUpcomingTrips = async (): Promise<ListPlacesResponse> => {
	const response = await axios.get('/my-climate/trip-planner/places');
	return response.data;
};

export const getSuggestedRestaurants = async (
	requestData: ListRestaurantsRequest,
): Promise<ListRestaurantsResponse> => {
	const response = await axios.post('/my-climate/trip-planner/restaurants', requestData);
	return response.data;
};

export const getPlacesToRelax = async (requestData: ListPlacesToRelaxRequest): Promise<ListPlacesToRelaxResponse> => {
	const response = await axios.post('/my-climate/trip-planner/places-to-relax', requestData);
	return response.data;
};

export const getTransportationToHotel = async (
	requestData: GetAirportTransportationRequest,
): Promise<GetAirportTransportationResponse> => {
	const response = await axios.post('/my-climate/trip-planner/airport-transportation', requestData);
	return response.data;
};

export const addEventToCalendar = (requestData: AddPlaceToCalendarRequest) => {
	const params = new URLSearchParams({
		googlePlaceId: requestData.googlePlaceId,
		startAt: requestData.startAt,
		duration: requestData.duration.toString(),
	});

	window.location.href = `${import.meta.env.VITE_API_URL}/my-climate/trip-planner/add-to-calendar?${params.toString()}`;
};
