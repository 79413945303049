import React, { useEffect, useState } from 'react';
import styles from './ReportDownload.module.scss';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';
import Card from '@/components/ui/Card/Card.tsx';
import moment, { Moment } from 'moment/moment';
import useDownloadFile from '@/pages/ClimateProDashboard/ReportDownload/useDownloadFile.ts';
import Select, { Value } from '@/components/ui/Select/Select.tsx';
import DatePicker from '@/components/ui/DatePicker/DatePicker.tsx';
import ButtonDropdown from '@/components/ui/ButtonDropdown/ButtonDropdown.tsx';
import { downloadReportOptions, RangeValue, reportRangeValues } from '@/constants/climatePro.ts';
import breakpoints from '@/constants/breakpoints.ts';
import queryString from 'query-string';
import CheckboxToggle from '@/components/ui/CheckboxToggle/CheckboxToggle.tsx';
import { useQuery } from '@tanstack/react-query';
import queryKeys from '@/constants/queryKeys.ts';
import { getEntitiesFilters } from '@/api/climatePro.ts';
import { transformDataToGroupedOptionsArray } from '@/pages/ClimateProDashboard/Metrics/Metrics.tsx';
import Co2SourceModelClass = App.Enums.Co2SourceModelClass;

const ReportDownload: React.FC = () => {
	const [range, setRange] = useState<RangeValue>(reportRangeValues[0]?.value);
	const [startDate, setStartDate] = useState(moment().startOf('year'));
	const [endDate, setEndDate] = useState(moment());
	const [includeUpcomingTrips, setIncludeUpcomingTrips] = useState(false);
	const [entity, setEntity] = useState<string | undefined>(undefined);

	const { data: entitiesFilter, isSuccess } = useQuery({
		queryKey: [queryKeys.entitiesFilter],
		queryFn: getEntitiesFilters,
	});

	useEffect(() => {
		setEntity(`${entitiesFilter?.entities[0].value}.${entitiesFilter?.entities[0].items[0].value}`);
	}, [isSuccess]);

	const handleRangeChange = (value: RangeValue) => {
		setRange(value);

		const today = moment();

		switch (value) {
			case '12months':
				setStartDate(today.clone().subtract(12, 'months').startOf('day'));
				setEndDate(today.clone().endOf('day'));
				break;
			case '6months':
				setStartDate(today.clone().subtract(6, 'months').startOf('day'));
				setEndDate(today.clone().endOf('day'));
				break;
			case '3months':
				setStartDate(today.clone().subtract(3, 'months').startOf('day'));
				setEndDate(today.clone().endOf('day'));
				break;
			case '30days':
				setStartDate(today.clone().subtract(30, 'days').startOf('day'));
				setEndDate(today.clone().endOf('day'));
				break;
			case 'custom':
				break;
			case 'all':
			default:
				break;
		}
	};

	const handleStartDateChange = (date?: Moment) => {
		if (!date) {
			return;
		}

		if (date.isSame(endDate, 'day')) {
			setEndDate(date.clone().add(1, 'days'));
		} else if (date.isAfter(endDate)) {
			setEndDate(date.clone().add(1, 'days'));
		}

		setStartDate(date);
	};

	const handleEndDateChange = (date?: Moment) => {
		if (!date) {
			return;
		}

		if (date.isSame(startDate, 'day')) {
			setStartDate(date.clone().subtract(1, 'days'));
		} else if (date.isBefore(startDate)) {
			setStartDate(date.clone().subtract(1, 'days'));
		}

		setEndDate(date);
	};

	const isDatesEnabled = range === 'custom';
	const { downloader } = useDownloadFile();

	const onDownload = (fileType: 'csv' | 'xlsx') => {
		const [sourceModelClass, sourceModelId] = entity!.split('.');
		const params = {
			fileType: fileType,
			from: range !== 'all' ? startDate.format('YYYY-MM-DD') : undefined,
			to: range !== 'all' ? endDate.format('YYYY-MM-DD') : undefined,
			includeUpcoming: includeUpcomingTrips,
			sourceModelClass: sourceModelClass as Co2SourceModelClass,
			sourceModelId: +sourceModelId,
		};
		const apiUrl = import.meta.env.VITE_API_URL;

		const link = `${apiUrl}/climate-pro/co2-report/download?${queryString.stringify(params)}`;
		return downloader(link);
	};
	return (
		<FlexBlock rowGap="32px" flexDirection="column" className={styles.reportDownloadWrapper}>
			<h1>Emission reports</h1>
			<p className={styles.additionalInfo}>
				Get an overview of your company’s travel emissions with a detailed report.
			</p>
			<Card className={styles.card}>
				<h2>Your company emission report</h2>
				<p className={styles.subtitle}>
					Choose to download all emissions data or filter by a specific time period.
				</p>
				<FlexBlock
					flexDirection={{
						[breakpoints.zero]: 'column',
						[breakpoints.tabletWide]: 'row',
					}}
					rowGap="20px"
					alignItems={{ [breakpoints.tabletWide]: 'flex-end' }}
					className={styles.inputs}
				>
					<Select
						label="Period"
						required
						options={reportRangeValues}
						value={range}
						onChange={(value: Value) => handleRangeChange(value as RangeValue)}
						className={styles.select}
					/>

					<FlexBlock columnGap="8px" alignItems="flex-end">
						{range !== 'all' && (
							<DatePicker
								value={startDate}
								onChange={handleStartDateChange}
								label="Start date"
								required={true}
								disabled={!isDatesEnabled}
							/>
						)}

						{range !== 'all' && (
							<DatePicker
								value={endDate}
								onChange={handleEndDateChange}
								label="End date"
								required={true}
								disabled={!isDatesEnabled}
							/>
						)}

						<Select
							label="Filter"
							required
							searchable
							value={entity}
							onChange={value => setEntity(value as string)}
							name="sourceModelId"
							options={transformDataToGroupedOptionsArray(entitiesFilter?.entities || [])}
							className={styles.select}
						/>
					</FlexBlock>
				</FlexBlock>
				<FlexBlock columnGap="10px" className={styles.checkbox}>
					<p>Include upcoming trips</p>
					<CheckboxToggle
						checked={includeUpcomingTrips}
						onChange={() => setIncludeUpcomingTrips(!includeUpcomingTrips)}
					/>
				</FlexBlock>
				<ButtonDropdown
					buttonText="Export"
					options={downloadReportOptions}
					onClick={value => {
						if (value === 'csv' || value === 'xlsx') {
							onDownload(value);
						}
					}}
					disabled={
						!entity ||
						!entity.split('.')[0] ||
						entity.split('.')[0].includes('undefined') ||
						!+entity.split('.')[1]
					}
				/>
			</Card>
		</FlexBlock>
	);
};

export default ReportDownload;
