import React from 'react';
import { NavLink } from 'react-router-dom';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';
import styles from './SubmenuItems.module.scss';
import classNames from 'classnames';

interface SubmenuItemsProps {
	links: { path: string; label: string }[];
}

const SubmenuItems: React.FC<SubmenuItemsProps> = ({ links }) => {
	return (
		<FlexBlock
			justifyContent="space-between"
			alignItems="stretch"
			rowGap="8px"
			columnGap="8px"
			className={styles.submenuItemsMobileWrapper}
		>
			{links.map((link, key) => (
				<NavLink
					key={key}
					to={link.path}
					className={({ isActive }) => classNames(styles.submenuItemMobile, { [styles.active]: isActive })}
				>
					{link.label}
				</NavLink>
			))}
		</FlexBlock>
	);
};

export default SubmenuItems;
