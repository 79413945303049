import React from 'react';
import { useField } from 'formik';
import AutocompleteAirportsInput, {
	AutocompleteAirportsInputProps,
} from '@/components/ui/AutocompleteAirportsInput/AutocompleteAirportsInput.tsx';
import { get } from 'lodash';

interface FormikAutocompleteAirportsInputProps
	extends Omit<AutocompleteAirportsInputProps, 'value' | 'onChange' | 'errorMessage' | 'onTouched'> {
	name: string;
}

const FormikAutocompleteAirportsInput: React.FC<FormikAutocompleteAirportsInputProps> = ({ name, ...props }) => {
	const [{ value }, { error, touched }, { setValue, setTouched }] = useField(name);

	return (
		<AutocompleteAirportsInput
			{...props}
			name={name}
			value={value}
			onTouched={() => setTouched(true)}
			onChange={setValue}
			errorMessage={get(error, 'id', error)}
			touched={touched}
		/>
	);
};

export default FormikAutocompleteAirportsInput;
