import React, { ChangeEvent, Fragment, useCallback, useEffect, useState } from 'react';
import styles from './AutocompleteAirportsInput.module.scss';
import { useDebounce } from '@uidotdev/usehooks';
import classNames from 'classnames';
import { listAirports } from '@/api/search.ts';
import Spinner from '@/components/ui/Spinner/Spinner.tsx';
import OutsideClickHandler from 'react-outside-click-handler';
import { isEmpty } from 'lodash';
import SelectOptionGroupData = App.Data.SelectOptionGroupData;
import SelectOptionData = App.Data.SelectOptionData;
import SearchAirportsRequest = App.Data.Request.SearchAirportsRequest;

export interface AutocompleteAirportsInputProps {
	className?: string;
	label?: string;
	placeholder?: string;
	value: App.Data.AirportData;
	onChange: (newValue?: App.Data.AirportData) => void;
	maxLength?: number;
	errorMessage?: string;
	touched?: boolean;
	min?: number;
	disabled?: boolean;
	onTouched?: () => void;
	name?: string;
	ref?: React.Ref<HTMLInputElement>;
	wildcardVisible?: boolean;
}

const AutocompleteAirportsInput: React.FC<AutocompleteAirportsInputProps> = ({
	className,
	label,
	placeholder,
	onChange,
	maxLength,
	errorMessage,
	touched,
	min,
	disabled = false,
	onTouched,
	name,
	ref,
	value,
	wildcardVisible = false,
}) => {
	// State to store the user input and debounced version of it (to prevent fetching on every keystroke)
	const [searchTerm, setSearchTerm] = useState('');
	const debouncedSearchTerm = useDebounce(searchTerm.trim(), 500); // Debounce the input value by 500ms

	const [loading, setLoading] = useState(false);
	const [isQueryFocused, setIsQueryFocused] = useState(false);
	const [isDropdownShown, setIsDropdownShown] = useState(false);
	const [airports, setAirports] = useState<SelectOptionGroupData[]>([]);

	// Function to close dropdown
	const closeDropdown = useCallback(() => {
		setIsDropdownShown(false);

		onTouched && setTimeout(() => onTouched(), 50);
	}, [onTouched]);

	// Function to fetch suggestions based on the debounced input value
	const fetchOptions = useCallback((requestOptions: SearchAirportsRequest) => {
		setLoading(true);

		// Fetch suggestions and update dropdown options
		listAirports(requestOptions)
			.then(response => {
				setAirports(response.airports);
				setIsDropdownShown(true);
			})
			.catch(() => {
				setAirports([]);
				closeDropdown();
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	// Handle click events outside the wrapper to close the dropdown
	const handleClickOutside = useCallback(() => {
		isDropdownShown && closeDropdown();
	}, [isDropdownShown]);

	const handleChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			// Reset the value of the whole component, on every input change, if needed
			value && onChange(undefined);

			// Set the value of the input field
			setSearchTerm(event.target.value);
		},
		[onChange, value],
	);

	// Handle the selection of an option from the dropdown
	const handleSelectAirport = (airport: SelectOptionData) => {
		// Set the value of the whole component to the selected item (value)
		onChange({
			id: airport.value,
			name: airport.label,
		});

		// Close the dropdown
		closeDropdown();
	};

	useEffect(() => {
		// Do not fetch if the field is not focused
		if (!isQueryFocused) {
			return;
		}

		// Do not fetch if there is no search length
		if (!debouncedSearchTerm?.length) {
			return;
		}

		// Fetch options when the debounced input value changes
		fetchOptions({
			query: debouncedSearchTerm,
		});
	}, [debouncedSearchTerm]);

	useEffect(() => {
		// Clear the input value when the dropdown is closed and component value is not present
		if (!isDropdownShown && !value) {
			setSearchTerm('');
		}
	}, [isDropdownShown]);

	useEffect(() => {
		if (value && isEmpty(value)) {
			setSearchTerm('');
		}

		if (!value?.id) {
			return;
		}

		// When the value changes to truthy, set search term to the name of the google place
		const searchTerm = `${value.name} (${value.iataCode})`;

		setSearchTerm(searchTerm);
	}, [value]);

	return (
		<div className={classNames(styles.autocompleteAirportsWrapper, className)}>
			{!!label && (
				<span className={styles.label}>
					{label}
					{wildcardVisible && '*'}
				</span>
			)}
			<div className={styles.inputWrapper}>
				<input
					ref={ref}
					name={name}
					type="text"
					placeholder={placeholder}
					value={searchTerm}
					onChange={handleChange}
					maxLength={maxLength}
					onBlur={() => setIsQueryFocused(false)}
					onFocus={() => setIsQueryFocused(true)}
					min={min}
					disabled={disabled}
					autoComplete="off"
				/>
				{!!errorMessage && touched && <span>{errorMessage}</span>}
				{loading && (
					<span className={styles.spinnerWrapper}>
						<Spinner absolute />
					</span>
				)}
				{isDropdownShown && airports.length > 0 && (
					<OutsideClickHandler onOutsideClick={() => setTimeout(handleClickOutside, 10)}>
						<div className={styles.listWrapper}>
							{airports.map((airportsCity, index) => (
								<Fragment key={index}>
									<div className={styles.groupLabel}>{airportsCity.label}</div>
									{airportsCity.items.map(airport => (
										<div
											key={airport.value}
											className={classNames(styles.rowWrapper, {
												[styles.selected]: value && value.id === airport.value,
											})}
										>
											<div className={styles.option} onClick={() => handleSelectAirport(airport)}>
												{airport.label}
											</div>
										</div>
									))}
								</Fragment>
							))}
						</div>
					</OutsideClickHandler>
				)}
			</div>
		</div>
	);
};

export default AutocompleteAirportsInput;
