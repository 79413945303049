import { FC } from 'react';
import { useField } from 'formik';
import DatePicker, { DatePickerProps } from '@/components/ui/DatePicker/DatePicker.tsx';

interface FormikDatePickerProps extends Omit<DatePickerProps, 'value' | 'onChange' | 'errorMessage' | 'touched'> {
	name: string;
}

const FormikDatePicker: FC<FormikDatePickerProps> = ({ name, ...props }) => {
	const [{ value }, { error, touched }, { setValue }] = useField(name);

	return (
		<DatePicker
			{...props}
			value={value}
			onChange={newValue => setValue(newValue)}
			errorMessage={error}
			touched={touched}
			name={name}
		/>
	);
};

export default FormikDatePicker;
