import FlexBlock from '@/components/ui/FlexBlock/FlexBlock';
import styles from './BookingRewards.module.scss';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { getOrganizationCarbonRewards } from '@/api/myClimate';
import queryKeys from '@/constants/queryKeys';
import Skeleton from 'react-loading-skeleton';
import GoodwingsLogo from '@/assets/Icons/Goodwings-Logo-Wings.svg';
import Tooltip from '@/components/ui/Tooltip/Tooltip';
import InfoIcon from '@/assets/Icons/Info-Line.svg?react';
import { useWhoamiStore } from '@/store/useWhoamiStore.ts';

const BookingRewards: React.FC = () => {
	const organizationName = useWhoamiStore(state => state.user?.organization?.name);

	const { data, isLoading, isError } = useQuery({
		queryKey: [queryKeys.getOrganizationCarbonRewards],
		queryFn: getOrganizationCarbonRewards,
	});

	if (isLoading) {
		return (
			<FlexBlock className={styles.card} flexDirection="column" rowGap="24px">
				<Skeleton height={30} width={200} />
				<Skeleton count={3} />
			</FlexBlock>
		);
	}

	if (isError || !data) {
		return (
			<FlexBlock className={styles.card} flexDirection="column" rowGap="24px">
				<h1>Booking rewards</h1>
				<p>Unable to load booking rewards data.</p>
			</FlexBlock>
		);
	}

	const { totalItineraries, cycleThreshold, creditsTotal } = data;

	const currentCycleEnd = (Math.floor(totalItineraries / cycleThreshold) + 1) * cycleThreshold;
	const currentCycleStart = currentCycleEnd - cycleThreshold;
	const progressInCurrentCycle = totalItineraries - currentCycleStart;
	const percentageInCycle = Math.max(0, (progressInCurrentCycle / cycleThreshold) * 100);

	return (
		<FlexBlock className={styles.card} flexDirection="column" rowGap="24px">
			<FlexBlock flexDirection="column">
				<div className={styles.logoContainer}>
					<div>
						<h1>Booking rewards</h1>
						<h2 className={styles.companyName}>{organizationName ?? 'Your company'}</h2>
					</div>
					<div className={styles.logo}>
						<img src={GoodwingsLogo} alt="Goodwings Logo" />
					</div>
				</div>
			</FlexBlock>

			<h2>Earn 20 carbon credits for every 20 bookings made company-wide.</h2>

			<FlexBlock className={`${styles.rewardCard} ${styles.bookings}`} flexDirection="column" rowGap="14px">
				<FlexBlock alignItems="center" justifyContent="space-between" className={styles.cardHeader}>
					<h1>Total company bookings</h1>
					<div>
						<Tooltip
							icon={<InfoIcon />}
							text="Your company's total confirmed bookings so far. For every 20th company-wide booking, you earn 20 carbon credits towards nature based carbon removal."
							placement="left"
						/>
					</div>
				</FlexBlock>
				<h1 className={styles.creditHeading}>{totalItineraries}</h1>
				{totalItineraries > 0 ? (
					<>
						<FlexBlock className={styles.progressContainer} alignItems="center">
							<div className={styles.progressbar}>
								<div
									className={styles.progress}
									style={{
										width: `${percentageInCycle}%`,
									}}
								/>
							</div>
							<span className={styles.counter}>
								{totalItineraries} / {currentCycleEnd}
							</span>
						</FlexBlock>
						<h2 className={styles.bookingstogo}>
							Book {currentCycleEnd - totalItineraries} more trips to redeem credits
						</h2>
					</>
				) : (
					<>
						<div style={{ height: '38px' }}></div>
						<h2 className={styles.bookingstogo}>Unlock credits by booking trips with us!</h2>
					</>
				)}
			</FlexBlock>

			<FlexBlock className={`${styles.rewardCard} ${styles.credits}`} flexDirection="column">
				<FlexBlock alignItems="center" justifyContent="space-between" className={styles.cardHeader}>
					<h1>Total credits earned</h1>
					<div>
						<Tooltip
							icon={<InfoIcon />}
							text="Earn carbon credits gradually when booking in-platform, and contribute to VCS-verified carbon removal. 1 credit equals 1 ton of CO₂ absorbed from the atmosphere."
							placement="left"
						/>
					</div>
				</FlexBlock>
				<div className={styles.creditsDisplay}>
					<span className={styles.creditsNumber}>{creditsTotal}</span>
					<span className={styles.creditsText}>Carbon credits</span>
				</div>
			</FlexBlock>
		</FlexBlock>
	);
};

export default BookingRewards;
