import React from 'react';
import styles from './LogTrip.module.scss';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock';
import planeIllustration from '../../../assets/Images/Plane.png';
import Image from '@/components/ui/Image/Image';
import ButtonLink from '@/components/ui/ButtonLink/ButtonLink.tsx';

const LogTrip: React.FC = () => {
	return (
		<>
			<FlexBlock className={styles.logTripWrapper}>
				<div className={styles.imageWrapper}>
					<Image
						src={planeIllustration}
						fallbackSrc={planeIllustration}
						alt="Log trip illustration"
						className={styles.pic}
					/>
				</div>
				<FlexBlock flexDirection="column" justifyContent="center" rowGap="24px" className={styles.text}>
					<h1>Log your trip emissions</h1>
					<h2>Log outside bookings to keep emission reports accurate </h2>
				</FlexBlock>
				<ButtonLink href="/my-climate/trip-emission-log" color="darkGreen" className={styles.button}>
					Add external trip
				</ButtonLink>
			</FlexBlock>
		</>
	);
};

export default LogTrip;
