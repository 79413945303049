export const groundTransportationOptions = [
	{
		label: 'Rail',
		value: 'rail',
	},
	{
		label: 'Bus',
		value: 'bus',
	},
	{
		label: 'Car',
		value: 'car',
	},
	{
		label: 'Taxi',
		value: 'taxi',
	},
];

export const cabinClassOptions = [
	{
		label: 'Economy',
		value: 'economy',
	},
	{
		label: 'Premium',
		value: 'premium',
	},
	{
		label: 'Business',
		value: 'business',
	},
	{
		label: 'First',
		value: 'first',
	},
];

export const accommodationTypeOptions = [
	{ value: 'hotel', label: 'Hotel' },
	{ value: 'hostel', label: 'Hostel' },
	{ value: 'airbnb', label: 'Airbnb' },
	{ value: 'guesthouse', label: 'Guesthouse' },
	{ value: 'other', label: 'Other' },
];

export const engineTypeOptions = [
	{ value: 'diesel', label: 'Diesel' },
	{ value: 'petrol', label: 'Petrol' },
	{ value: 'hybrid', label: 'Hybrid' },
	{ value: 'electric', label: 'Electric' },
];

export const airTravelLogHistoryDemoColumns = [
	{
		accessorKey: 'travelLogType',
		header: 'Type',
		type: 'text',
	},
	{
		accessorKey: 'from',
		header: 'Route info',
		type: 'text',
	},
	{
		accessorKey: 'departureReturnDate',
		header: 'Travel date',
		type: 'string',
	},
	{
		accessorKey: 'type',
		header: 'Type/Class',
		type: 'text',
	},
	{
		accessorKey: 'isRoundtrip',
		header: 'Round Trip',
		type: 'text',
	},
	{
		accessorKey: 'co2',
		header: 'CO2',
		type: 'number',
	},
	{
		accessorKey: 'entryDate',
		header: 'Entry Date',
		type: 'string',
	},
	{
		accessorKey: 'action',
		header: 'Actions',
	},
];
